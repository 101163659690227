// @ts-strict-ignore
import { useState } from 'react'

import { useRouter } from 'next/router'

import { useApolloClient } from '@apollo/client'

import { Body2, Heading6 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Collapse from '~/shared/atoms/Collapse'
import Dropdown from '~/shared/atoms/Dropdown'
import { Flag } from '~/shared/atoms/Flag/Flag'
import Icon from '~/shared/atoms/Icon'

import { Container, Select, SelectLabel } from './LanguageSwitcherShards'
import useLanguageSwitcher, { LocaleItem, localeToCountry } from './LanguageSwitcherUtils'

export type LanguageSwitcherVariant = 'small' | 'big'

interface LanguageSwitcherProps {
  variant?: LanguageSwitcherVariant
  onClose(): void
}

const LanguageSwitcher = ({ variant = 'small', onClose }: LanguageSwitcherProps) => {
  const { tp } = gt
  const router = useRouter()
  const [show, setShow] = useState(false)
  const { options, onLanguageChange } = useLanguageSwitcher()
  const language = router.locale

  const apolloClient = useApolloClient()

  const handleLanguageChange = (lang: string) => {
    setShow(false)
    onLanguageChange(lang)

    // temp remove when solution to cache problem will be found
    if (router.pathname === '/restaurants/festival/[slug]') {
      // apolloClient.cache.reset()
      // clear cache only for festivalEditionRestaurants
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'festivalEditionRestaurants' })
    }
  }

  const content =
    variant === 'small' ? (
      <Dropdown
        trigger='programmatic'
        show={show}
        selectedKey={language}
        onChange={handleLanguageChange}
        onClickOutside={() => setShow(false)}
        items={options}>
        <Select active={show} onClick={() => setShow(v => !v)}>
          <SelectLabel>
            <Flag country={localeToCountry[language]} />
            <Body2 fontWeight='medium'>{tp('Languages', language.toUpperCase())}</Body2>
            <Icon icon='arrow' />
          </SelectLabel>
        </Select>
      </Dropdown>
    ) : (
      <Container>
        <Collapse title={<LocaleItem locale={language} />} icon='earth' borderStyle='none'>
          {options
            .filter(item => item.key !== router.locale)
            .map(item => {
              const key = item.key.toString()
              return (
                <Heading6
                  fontWeight='regular'
                  key={item.key}
                  onClick={() => {
                    handleLanguageChange(key)
                    onClose()
                  }}>
                  <LocaleItem key={key} locale={key} />
                </Heading6>
              )
            })}
        </Collapse>
      </Container>
    )
  return <>{content}</>
}

export default LanguageSwitcher
