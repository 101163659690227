import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { h3, h4, Heading6 } from '~/theme/utils/typography'

import Button from '~/shared/atoms/Button'
import Icon from '~/shared/atoms/Icon'

export const JoinTheClubWrapper = styled.div<{ stretchContainer?: boolean }>`
  margin: 3rem 0;
  grid-area: join;
  max-width: ${({ stretchContainer }) => (stretchContainer ? 'unset' : '400px')};

  h6 {
    margin-top: 0.75rem;
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  ${mediaQueryUp('l')} {
    max-width: unset;
    width: ${({ stretchContainer }) => (stretchContainer ? '100%' : '400px')};
    justify-self: flex-end;
    margin-top: 0;
  }
`

export const JoinInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 0.5rem;

  ${mediaQueryUp('m')} {
    flex-direction: row;
  }
`

export const JoinButton = styled(Button)`
  ${mediaQueryUp('m')} {
    width: 6.625rem;
  }
`

export const JoinHeading = styled.div`
  ${({ theme }) => h4({ theme, fontWeight: 'medium' })}

  ${mediaQueryUp('m')} {
    margin-bottom: 1.5rem;
    ${({ theme }) => h3({ theme, fontWeight: 'medium' })}
  }
`

export const JoinMobileHeading = styled(Heading6)`
  ${mediaQueryUp('m')} {
    display: none;
  }
`

export const Consent = styled.div<{ stretchContainer?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
  font-size: ${({ theme }) => theme.text.fontSize.xxs};
  line-height: ${({ theme }) => theme.text.lineHeight.loose};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wide};
  max-width: ${({ stretchContainer }) => (stretchContainer ? 'unset' : '400px')};

  & a {
    color: ${({ theme }) => theme.colors.orange[500]};
  }
`

export const Address = styled.div`
  display: flex;
  flex-direction: column;
`

export const AddressInfo = styled.span`
  font-size: ${({ theme }) => theme.text.fontSize.s};
`

export const Benefits = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const BenefitsTitle = styled.li`
  font-size: ${({ theme }) => theme.text.fontSize.m};
`

export const Benefit = styled.li`
  font-size: ${({ theme }) => theme.text.fontSize.s};
`

export const BenefitIcon = styled(Icon)`
  width: 0.75rem;
  margin-right: 0.25rem;
  filter: ${({ theme }) => theme.filters.gray[1000]};
`

export const ToastContainer = styled.div`
  margin-top: -0.5rem;
`
