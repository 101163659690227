import React from 'react'

import { Global } from '@emotion/react'

import useMobileNavigation from '~/utils/hooks/useMobileNavigation'

import { MetadataType } from '~/layout/Head/HeadTypes'
import Footer from '~/shared/molecules/Footer'
import Header from '~/shared/molecules/Header'
import NavBarMobile from '~/shared/molecules/NavBarMobile'
import FestivalNavBarMobile from '~/shared/molecules/NavBarMobile/FestivalNavBarMobileView'
import globalStyle from '~/theme/globalStyle'

import Head from './Head'

interface Props {
  children: React.ReactNode
  metadata?: MetadataType
  festivalCode?: string
  festivalSlug?: string
  hideMobileMenu?: boolean
  renderFestivalTabs?: boolean
}

const Layout: React.FC<Props> = ({ children, metadata, festivalCode, festivalSlug, hideMobileMenu, renderFestivalTabs }) => {
  const isMobileNavigation = useMobileNavigation()
  return (
    <>
      <Head metadata={metadata} />
      <Global styles={globalStyle} />
      <Header festivalCode={festivalCode} festivalSlug={festivalSlug} />
      <div>{children}</div>
      <Footer festivalCode={festivalCode} />
      {!hideMobileMenu && isMobileNavigation && (renderFestivalTabs ? <FestivalNavBarMobile /> : <NavBarMobile />)}
    </>
  )
}

export default Layout
