import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import {
  Banner as BannerAtom,
  ButtonContainer as ButtonContainerAtom,
  Container as ContainerAtom,
  MainText as MainTextAtom,
  TextContainer as TextContainerAtom,
} from '~/components/Festival/Banners/BannerShards'
import { imageUrl } from '~/components/Festival/Banners/BannerTypes'

export const Banner = styled(BannerAtom)<{ color?: string; image?: imageUrl }>`
  position: relative;
  height: 11.75rem;
  border-radius: 0;
  .icon-right {
    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `}
    width: 6px;
    height: 9px;
  }

  ${({ image }) =>
    image &&
    css`
      background: url('${image.mobile}') no-repeat left center / auto;
    `}
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
  align-items: center;

  ${mediaQueryUp('m')} {
    width: 100%;
    border-radius: 0.5625rem;
    margin-left: 0;
    height: 9.5rem;
    ${({ image }) =>
      image &&
      css`
        background-image: url('${image.desktop}');
      `};
  }
`

export const Container = styled(ContainerAtom)`
  margin-right: 0.5rem;
  width: 100%;

  ${mediaQueryUp('m')} {
    width: 100%;
    max-width: 100%;
    margin-right: 1rem;
    flex-direction: row;
  }
`

export const TextContainer = styled(TextContainerAtom)`
  gap: 0.25rem;
  ${mediaQueryUp('m')} {
    gap: 0.125rem;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  ${mediaQueryUp('m')} {
    gap: 0.5rem;
  }
`

export const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonContainer = styled(ButtonContainerAtom)`
  ${mediaQueryUp('m')} {
    a {
      width: 100%;
    }
  }
`

export const ButtonIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  ${mediaQueryUp('m')} {
    width: 1.125rem;
    height: 1.125rem;
  }
`

export const ButtonContentContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const Logo = styled.img`
  height: 20px;
`

export const MainText = styled(MainTextAtom)`
  font-weight: 600;
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};

  ${mediaQueryUp('m')} {
    letter-spacing: ${({ theme }) => theme.text.letterSpacing.tight};
  }
`

export const DesktopDate = styled.span`
  font-weight: 600;
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.tight};

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  display: none;
  ${mediaQueryUp('m')} {
    font-size: ${({ theme }) => theme.text.fontSize.s};
    display: block;
  }

  ${mediaQueryUp('l')} {
    font-size: ${({ theme }) => theme.text.fontSize.m};
  }
`

export const MobileDate = styled.span`
  font-size: ${({ theme }) => theme.text.fontSize.s};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wide};
  font-weight: 600;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${mediaQueryUp('m')} {
    display: none;
  }
`

export const LeftImageContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 114px;
  justify-content: center;
  align-items: center;

  ${mediaQueryUp('m')} {
    max-width: unset;
    min-width: 124px;
  }
`

export const LeftImageBottomElement = styled.img`
  max-height: 100%;
  width: 114px;

  ${mediaQueryUp('m')} {
    width: unset;
  }
`

export const LeftImageTopElement = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
`
