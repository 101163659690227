import { GetFestivalCopy } from '../..'
import { getVoucherDetailsFaq, getVoucherDetailsFaqSummary, restaurantWeekExpiresDate, VoucherDetailsCopy } from '..'

export const getRwCopy: GetFestivalCopy<VoucherDetailsCopy> = gt => ({
  title: gt.tp('VoucherDetailsPage', '{{ festival }} - Reservation for a gift 🎁', { festival: gt.tp('festival', 'RestaurantWeek') }),
  question: gt.tp('VoucherDetailsPage', 'How does the gift reservation work?'),
  description1: gt.tp(
    'VoucherDetailsPage',
    `Book the restaurant experience of your dreams for yourself or a loved one. Just select the number of Guests and you will receive a beautiful printable confirmation.`
  ),
  description2: gt.tp(
    'VoucherDetailsPage',
    `The code on the confirmation will be used to pay for the reservation of a table at the restaurant of your dreams on the date of your choice.`
  ),
  summary: getVoucherDetailsFaqSummary(gt),
  faq: getVoucherDetailsFaq(gt, `${gt.tp('festival', 'RestaurantWeek')} ${gt.tp('Seasons', 'Spring')}`, restaurantWeekExpiresDate).concat([
    {
      title: gt.tp('VoucherDetailsPage', 'When will the festival be held and which restaurants are participating?'),
      description: gt.tp(
        'VoucherDetailsPage',
        'RestaurantWeek® is held twice a year. The approximate date of the next Festival is March 4 - April 17, 2025. The date of the Festival is subject to change. If you would like us to notify you of the exact date, please sign up for the newsletter. At this time we cannot provide a list of restaurants. With Advance Booking you will know the list of restaurants 24 hours before other Guests.'
      ),
    },
    {
      title: gt.tp('VoucherDetailsPage', 'What if I cancel a reservation paid by code?'),
      description: gt.tp(
        'VoucherDetailsPage',
        'In case of cancellation, the code remains active and can be used again to pay for another reservation, regardless of the cancellation date.'
      ),
    },
  ]),
})
