import { PriceTag } from '~/shared/atoms/PriceTag'

import { Item, StyledStarIcon, TagBarIconContainer } from '../TagBarShards'
import { ComponentProps, TagBarRestaurant } from '../TagBarTypes'

export const PriceTagElement = ({
  sections = [],
  priceTag,
  variant,
  size,
}: Pick<ComponentProps, 'sections' | 'priceTag' | 'variant' | 'size'>) =>
  (sections.includes('price') && priceTag && typeof priceTag !== 'string' && (
    <>
      <Item>
        <PriceTag position={priceTag.position} size={size} variant={variant} />
      </Item>
      {variant === 'plainText' ? `•${'\u00A0'}` : null}
    </>
  )) ||
  null

export const RatingElement = ({
  sections = [],
  rating,
  ratingCount = 0,
}: Pick<ComponentProps, 'sections'> & { rating: TagBarRestaurant['rating']; ratingCount: TagBarRestaurant['ratingCount'] }) =>
  (sections.includes('rating') && ratingCount > 0 && (
    <Item>
      <TagBarIconContainer>
        <StyledStarIcon icon='star' />
      </TagBarIconContainer>
      <span>{`${rating} (${ratingCount})`}</span>
    </Item>
  )) ||
  null
