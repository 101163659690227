// @ts-strict-ignore
import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import { processEnv } from '~/utils/env'

import { paths, RESERVABLE_TYPES } from '~/constants'

export const getProductProfileUrl = (product, type) => {
  if (type === RESERVABLE_TYPES.EXTRA) {
    const { slug } = product
    return paths.singleChefsMenu({ slug })
  }
  if (type === RESERVABLE_TYPES.FER) {
    const { slug } = product.restaurant
    return paths.singleRestaurant({ slug })
  }
}

export const useProductModalNavigation = ({ reservableExtra, FER, type, handleClose }) => {
  const router = useRouter()
  const [previousPath, setPreviousPath] = useState('')

  useEffect(() => {
    setPreviousPath(router.asPath)
    router.replace(
      {
        // Omit pathname so Next.js uses the current route (asPath).
        query: {
          ...router.query,
          ...(FER && { festivalFilter: FER.festivalEdition.id }),
          restaurant: getProductSlug(reservableExtra || FER, type),
        },
      },
      // `undefined` here ensures the displayed URL remains
      // the same path ("/restaurants/festival/restaurant-week"), not "[slug]"
      undefined,
      {
        shallow: true,
      }
    )
  }, [type])

  const closeModal = () => {
    const url = new URL(previousPath, processEnv.CLUB_URL)
    url.searchParams.delete('festivalFilter')
    url.searchParams.delete('restaurant')
    const asPath = url.pathname + url.search
    router.push(asPath, asPath, { shallow: true })
    handleClose()
  }

  return {
    closeModal,
  }
}

export const getProductSlug = (product, type) => {
  if (type === RESERVABLE_TYPES.EXTRA) {
    const { slug } = product
    return slug
  }
  if (type === RESERVABLE_TYPES.FER) {
    const { slug } = product.restaurant
    return slug
  }
}
