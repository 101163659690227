import styled from '@emotion/styled'

export const Wrapper = styled.div`
  position: relative;
  max-width: 100%;

  .DayPicker__withBorder {
    font-family: 'Poppins', sans-serif;
    background: ${({ theme }) => theme.colors.gray[0]};
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};
    box-shadow: ${({ theme }) => theme.commons.shadow.l};
    overflow: hidden;
  }

  .CalendarMonth_caption {
    font-size: ${({ theme }) => theme.text.fontSize.s};
    font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
    line-height: 2;
    letter-spacing: ${({ theme }) => theme.text.letterSpacing.wide};
    color: ${({ theme }) => theme.colors.gray[1000]};
    padding-top: 1rem;
    padding-bottom: 2.75rem;
  }

  .DayPicker_weekHeader_li {
    font-size: ${({ theme }) => theme.text.fontSize.s};
    font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
    line-height: ${({ theme }) => theme.text.lineHeight.restful};
    letter-spacing: ${({ theme }) => theme.text.letterSpacing.wide};
    color: ${({ theme }) => theme.colors.gray[700]};
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .CalendarDay__default {
    border: 0;
    font-size: ${({ theme }) => theme.text.fontSize.s};
    font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
    line-height: ${({ theme }) => theme.text.lineHeight.restful};
    letter-spacing: ${({ theme }) => theme.text.letterSpacing.wide};
    color: ${({ theme }) => theme.colors.gray[1000]};
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      top: 0.25rem;
      left: 0;
      width: 100%;
      height: calc(100% - 0.5rem);
      background: transparent;
      border-radius: 0.5rem;
      z-index: -1;
      position: absolute;
      transition: background 0.1s ease-in-out;
    }

    &:not(.CalendarDay__blocked_out_of_range):not(.CalendarDay__selected):hover {
      background: transparent;

      &::before {
        background: ${({ theme }) => theme.colors.orange[200]};
      }
    }
  }

  .CalendarDay__blocked_out_of_range {
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  .CalendarDay__selected {
    background: none;
    color: ${({ theme }) => theme.colors.gray[0]};

    &::before {
      background: ${({ theme }) => theme.colors.orange[400]};
    }
  }

  .DayPickerNavigation_button {
    border: 0;
  }

  .DayPicker_weekHeader {
    top: 48px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 1rem;

  img {
    height: 1.25rem;
    width: auto;
    filter: ${({ theme }) => theme.filters.gray[1000]};
  }
`

export const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100% - 4.5rem);
  background: ${({ theme }) => theme.colors.gray[0]};
  position: absolute;
  top: 4.5rem;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
`
