// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react'

import { FerModalQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import FormattedPrice from '~/shared/atoms/FormattedPrice/FormattedPriceView'

import { SelectWrapper, TabletDetailsComponentWrapper } from '../FERModalShards'

import { FERModalMenus } from './FERModalMenus'
import { FERModalSlotSelect } from './FERModalSlotSelect'

interface TabletDetailsProps {
  price: number
  fer: FerModalQuery['festivalEditionRestaurant']
}

type Step = { label: string; Component: JSX.Element }

export function FERModalTabletDetails({ price, fer }: TabletDetailsProps) {
  const [step, setStep] = useState(0)

  const secondStepContainerRef = useRef<HTMLDivElement>(null)

  const handleStepChange = async () => {
    setStep(step => (step === 0 ? 1 : 0))
  }

  useEffect(() => {
    if (step === 1 && secondStepContainerRef.current) {
      secondStepContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [step])

  const { restaurant, menus, festivalEdition } = fer

  const steps: Array<Step> = [
    {
      label: gt.tp('FestivalModal', 'Book a table'),
      Component: <FERModalMenus festivalEdition={festivalEdition} menus={menus} restaurant={restaurant} />,
    },
    {
      label: gt.tp('FestivalModal', 'Check out the menu'),
      Component: (
        <SelectWrapper ref={secondStepContainerRef}>
          <FERModalSlotSelect price={price} fer={fer} menus={menus} />
        </SelectWrapper>
      ),
    },
  ]

  const { Component, label } = steps[step]

  return (
    <>
      <FormattedPrice price={price} withGuest={true} />
      <Button label={label} width='full' color='orange' onClick={handleStepChange} />
      <TabletDetailsComponentWrapper>{Component}</TabletDetailsComponentWrapper>
    </>
  )
}
