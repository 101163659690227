import React, { ReactNode, useId } from 'react'

import { SetOptional } from 'type-fest'

import * as popover from '@zag-js/popover'
import { mergeProps, normalizeProps, Portal, useMachine } from '@zag-js/react'

type PopoverViewProps = {
  renderTrigger: (api: popover.Api) => ReactNode
  renderContent: (api: popover.Api) => ReactNode
  popoverContext?: SetOptional<popover.Context, 'id'>
}

export const PopoverView = ({ renderTrigger, renderContent, popoverContext }: PopoverViewProps) => {
  const [state, send] = useMachine(popover.machine({ id: useId() }), { context: popoverContext })

  const api = popover.connect(state, send, normalizeProps)

  const Wrapper = api.portalled ? Portal : React.Fragment

  return (
    <>
      <button {...api.getTriggerProps()}>{renderTrigger(api)}</button>
      <Wrapper>
        <div {...mergeProps(api.getPositionerProps(), { style: { zIndex: 10002, maxWidth: 'max-content' } })}>
          {api.open && <div {...api.getContentProps()}>{renderContent(api)}</div>}
        </div>
      </Wrapper>
    </>
  )
}
