import config from '~/config'

import cz from './cz'
import de from './de'
import pl from './pl'

const configs = {
  pl,
  cz,
  de,
}

export const getClubConfig = () => {
  return configs[config().marketCode]
}

export const redirectIfFeatureDisabledInConfig = {
  notFound: true as const,
}

export const isLocaleEnabled = (locale: string) => Object.keys(getClubConfig().enabledLanguages).includes(locale)

export const isDemo = config().isDemo

export * from './types'
