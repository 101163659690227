import dayjs from 'dayjs'

import { Metadata } from '~/generated/graphql'

export const dateToDaysAndMonths = (date: string) => dayjs.parseZone(date).format('DD.MM')

export const formatDate = (date: string) => dayjs.parseZone(date).format('DD.MM.YYYY')

export const hasGiftSaleMetadata = (festivalData: { metadatas: Metadata[] }): boolean => {
  return festivalData.metadatas.some(metadata => metadata.key === 'giftSale' && metadata.value === 'true')
}
