import { useLayoutEffect, useRef, useState } from 'react'

import { debounce } from 'lodash'

import { gt } from '~/locale'

import { DescriptionText, ExpandTextButton } from './ChefsMenuDescriptionShards'
import { toggleExpand } from './ChefsMenuDescriptionUtils'

interface DescriptionViewProps {
  description: string
  lineClamp?: number
}

const DescriptionView = ({ description, lineClamp }: DescriptionViewProps) => {
  const [expandText, setExpandText] = useState(false)
  const [showReadMoreBtn, setShowReadMoreBtn] = useState(false)
  const descriptionText = useRef<HTMLHeadingElement>(null)

  useLayoutEffect(() => {
    const descriptionElement = descriptionText?.current
    if (descriptionElement) {
      toggleExpand(descriptionElement, setShowReadMoreBtn)
      const debouncedHandleResize = debounce(() => {
        toggleExpand(descriptionElement, setShowReadMoreBtn)
      }, 500)
      window.addEventListener('resize', debouncedHandleResize)
      return () => window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return (
    <>
      <DescriptionText as={'p'} ref={descriptionText} fontWeight='regular' expandText={expandText} lineClampNumber={lineClamp}>
        {description}
      </DescriptionText>
      {showReadMoreBtn && (
        <ExpandTextButton onClick={() => setExpandText(!expandText)} type='button'>
          {expandText ? gt.tp('ProductDetails', 'Read less') : gt.tp('ProductDetails', 'Expand description')}
        </ExpandTextButton>
      )}
    </>
  )
}

export default DescriptionView
