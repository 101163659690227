// @ts-strict-ignore
import { useRouter } from 'next/router'

import { gt } from '~/locale'
import Link from '~/shared/atoms/Link'
import { NavBarMobileProps } from '~/shared/molecules/NavBarMobile/NavBarMobileTypes'
import { DEFAULT_NAV_BAR_ROUTES } from '~/shared/molecules/NavBarMobile/NavBarMobileUtils'

import { NavBar, NavButton, NavButtonIcon, NavButtonLabel, Wrapper } from './NavBarMobileShards'

export const IconButton = ({ icon, label, href }) => {
  const router = useRouter()
  const active = href === router.asPath || (router.asPath !== '/' && href !== '/' && router.asPath.startsWith(href))

  return (
    <Link href={href}>
      <NavButton>
        <NavButtonIcon active={active} icon={icon} />
        <NavButtonLabel active={active}>{label}</NavButtonLabel>
      </NavButton>
    </Link>
  )
}

const NavBarMobile: React.FC<NavBarMobileProps> = ({ routes }) => {
  return (
    <NavBar>
      <Wrapper>
        {(routes ?? DEFAULT_NAV_BAR_ROUTES).map(routeObj => (
          <IconButton key={routeObj.href} icon={routeObj.icon} href={routeObj.href} label={gt.tp('NavBarMobile', routeObj.label)} />
        ))}
      </Wrapper>
    </NavBar>
  )
}

export default NavBarMobile
