import * as React from 'react'
import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsTablet } from '~/theme/utils/grid'

import { gt } from '~/locale'
import Icon from '~/shared/atoms/Icon'
import { Like } from '~/shared/atoms/Like'
import TagBar from '~/shared/molecules/TagBar'

import { ActionButton, ActionButtonTitle, Actions, Col, Container, Row, Tagline, Title } from './ProductPageTitleShards'
import { RestaurantPageTitleProps } from './ProductPageTitleTypes'
import { usePageTitleData } from './ProductPageTitleUtils'

export const ShareButton = () => {
  return (
    <ActionButton>
      <Icon icon='share' />
      <ActionButtonTitle as='div' fontWeight='medium'>
        {gt.tp('RestaurantPageTitle', 'Share')}
      </ActionButtonTitle>
    </ActionButton>
  )
}

const RestaurantPageTitle: React.FC<RestaurantPageTitleProps> = props => {
  const { name } = usePageTitleData(props)
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })

  const { id, favorited } = props?.restaurant || {}

  const actions = (
    <Actions>
      {/* <ShareButton /> */}
      {!props?.reservableExtra && !props.hideFavourite && <Like hasTitle={true} restaurantId={id} favorited={favorited} />}
    </Actions>
  )

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Col>
              {props?.reservableExtra && <Tagline>{gt.tp('ProductPage', '#ChefsMenu')}</Tagline>}
              <Title>{name}</Title>
            </Col>
            {isTablet && actions}
          </Row>
          <TagBar
            restaurant={props?.restaurant}
            reservableExtra={props?.reservableExtra}
            size={'large'}
            sections={['rating', 'price', 'address']}
          />
        </Col>
        {!isTablet && actions}
      </Row>
    </Container>
  )
}

export default RestaurantPageTitle
