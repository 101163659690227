import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { useReleaseSlot } from './useReleaseSlot'

export function useOrderExitDetection() {
  const router = useRouter()

  const releaseSlot = useReleaseSlot()

  useEffect(() => {
    // detecting whether a user click browser back
    router.beforePopState(() => {
      if (!window.location.pathname.includes('order')) releaseSlot()
      return true // Return false when you want to block navigation
    })
  }, [router, releaseSlot])

  // hidden until you return to the decision with support for closing the tab / window
  // // detecting whether a user close browser
  // useEffect(() => {
  //   const handleBeforeUnload = e => {
  //     sessionStorage.setItem('isClosing', 'true')
  //     setTimeout(() => {
  //       sessionStorage.removeItem('isClosing')
  //     }, 100) // Slight delay to ensure the flag is cleared on refresh
  //   }

  //   const handleUnload = () => {
  //     if (sessionStorage.getItem('isClosing') === 'true') {
  //       handleReleaseSlot()
  //     }
  //   }

  //   window.addEventListener('beforeunload', handleBeforeUnload)
  //   window.addEventListener('unload', handleUnload)

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload)
  //     window.removeEventListener('unload', handleUnload)
  //   }
  // }, [])
}
