import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

export default function useNavigationHistory() {
  const router = useRouter()
  // Start the history with the current path
  const [history, setHistory] = useState([router.asPath])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setHistory(prevHistory => {
        // If the user is navigating back (the new url matches the page before the last),
        // remove the last entry instead of adding a duplicate.
        if (prevHistory.length > 1 && prevHistory[prevHistory.length - 2] === url) {
          return prevHistory.slice(0, prevHistory.length - 1)
        }
        return [...prevHistory, url]
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // If history length is greater than 1, there is somewhere to go back to
  const canGoBack = history.length > 1

  return { canGoBack }
}
