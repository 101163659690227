import { ReactNode, useCallback, useMemo } from 'react'

import { ApolloError } from '@apollo/client'
import { css, Global } from '@emotion/react'

import { mediaQueryDown } from '~/theme/utils/grid'
import { useOrderExitDetection } from '~/utils/hooks/useOrderExitDetection'

import { errors } from '~/constants/errors'
import { ContentWrapper, PageContent, PageContentStyles, PageWrapper } from '~/layout/CheckoutLayoutNew/CheckoutLayoutNewShards'
import { MetadataType } from '~/layout/Head/HeadTypes'
import { gt } from '~/locale'
import { useNewToast } from '~/shared/atoms/NewToast/NewToast'
import { handleNetworkError } from '~/shared/atoms/Toast/ToastUtils'
import globalStyle from '~/theme/globalStyle'

import Head from '../Head'

type CheckoutLayoutProps = {
  children: ReactNode
  header: ReactNode
  sidebar?: ReactNode
  extraHeadContent?: ReactNode
  metadata?: MetadataType
  contentStyle?: PageContentStyles
}

const BottomSheetGlobalStyles = () => (
  <Global
    styles={css`
      ${mediaQueryDown('l')} {
        #__next {
          height: 100%;
        }
      }
    `}
  />
)

export const useCheckoutError = () => {
  const newToast = useNewToast()

  const showError = useCallback(
    (error?: ApolloError | string | ReactNode) => {
      let id: string | undefined = ''
      newToast.remove()

      if (error instanceof ApolloError) {
        handleNetworkError({
          err: error as ApolloError,
          create: newToast?.create,
        })
      } else {
        if (typeof error !== 'string') {
          id = newToast.create({
            title: error,
            type: 'error',
          })
        } else {
          const message = errors[error as keyof typeof errors] || errors.unknown_error

          id = newToast.create({
            title: typeof message === 'string' ? gt.tp('Error', message) : message,
            type: 'error',
          })
        }
      }

      return id
    },
    [newToast]
  )

  return useMemo(() => ({ showError, removeError: newToast?.remove }), [showError, newToast])
}

export const CHECKOUT_FORM_ID = 'checkout_form'

export default function CheckoutLayout({ children, header, sidebar, extraHeadContent, metadata, contentStyle }: CheckoutLayoutProps) {
  useOrderExitDetection()

  return (
    <>
      <Head metadata={metadata}>{extraHeadContent}</Head>
      <Global styles={globalStyle} />
      <PageWrapper>
        {header}
        <ContentWrapper>
          <PageContent id='pageContent' contentStyle={contentStyle}>
            {children}
          </PageContent>
          {sidebar}
        </ContentWrapper>
      </PageWrapper>
      <BottomSheetGlobalStyles />
    </>
  )
}
