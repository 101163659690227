import { components, InputProps, OptionProps, ValueContainerProps } from 'react-select'

import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js'

import { Heading5, Heading6 } from '~/theme/utils/typography'

import ReactSelect from '~/shared/atoms/SelectionDropdown/SelectionDropdownContainer'

import { Button } from '../Dropdown/DropdownShards'

import { HiddenInput, ValueContainerImageWrapper, ValueContainerWrapper } from './PhoneInputSheets'
import { selectStyles } from './utils'

type OptionType = {
  label: string
  value: CountryCode
}

type CountrySelectProps = {
  value: CountryCode
  onChange: (val?: string) => void
  id: string
}

const Option = ({ children, innerRef, ...props }: OptionProps<OptionType, false>) => (
  <components.Option innerRef={innerRef} {...props}>
    <Button selected={props.isSelected}>
      <Heading6 fontWeight='medium'>{children}</Heading6>
    </Button>
  </components.Option>
)

const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType, false>) => {
  const value = props.getValue()[0].value

  return (
    <ValueContainerWrapper>
      <ValueContainerImageWrapper>
        {/* Url taken from docs: https://www.npmjs.com/package/react-phone-number-input */}
        <img src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`} />
        <Heading5>+{getCountryCallingCode(value)}</Heading5>
      </ValueContainerImageWrapper>
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
    </ValueContainerWrapper>
  )
}

const Input = (props: InputProps<OptionType, false>) => (
  <HiddenInput>
    <components.Input {...props} />
  </HiddenInput>
)

const SingleValue = () => <></>

export const CountrySelect = ({ value, onChange, id }: CountrySelectProps) => {
  const options = getCountries().map(countryCode => ({
    label: `${countryCode} +${getCountryCallingCode(countryCode)}`,
    value: countryCode,
  }))

  return (
    <ReactSelect
      id={id}
      options={options}
      styles={selectStyles}
      components={{
        Option,
        ValueContainer,
        Input,
        SingleValue,
      }}
      onChange={value => onChange(value?.value)}
      value={options.find(option => option.value === value)}
      isSearchable
    />
  )
}
