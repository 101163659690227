// @ts-strict-ignore
import { useTheme } from '@emotion/react'

import Loader from '../Loader'

import { BasicButtonIcon, ButtonContent, IconWrapper, LoaderCnt, StyledButton } from './ButtonShards'
import { Props } from './ButtonTypes'
import { buttonLoaderColor, getLinkAttributes } from './ButtonUtils'

const Button: React.FC<Props> = props => {
  const theme = useTheme()
  const { disabled, label, loading, onClick, type, variant, color, className } = props
  const edgeLoaderColor = variant === 'outlined' && color === 'secondary'
  const loaderColor = edgeLoaderColor ? theme.colors.orange[400] : buttonLoaderColor(theme, props)[variant]

  const parseContent = content => {
    if (!Array.isArray(content)) return content
    return content.map(item =>
      item.charAt(0) === '#' ? (
        <IconWrapper key={item}>
          <BasicButtonIcon icon={item.substring(1)} />
        </IconWrapper>
      ) : (
        <span key={item}>{item}</span>
      )
    )
  }

  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={className}
      props={props}
      as={props.as}
      href={props.href}
      target={props.target}
      data-ga={props['data-ga']}
      form={props.form}
      {...getLinkAttributes(props)}>
      {loading && (
        <LoaderCnt>
          <Loader color={loaderColor} />
        </LoaderCnt>
      )}
      <ButtonContent>{parseContent(label)}</ButtonContent>
    </StyledButton>
  )
}

Button.defaultProps = {
  color: 'primary',
  disabled: false,
  height: 'normal',
  loading: false,
  type: 'button',
  width: 'fit',
  variant: 'solid',
  fontWeight: 'medium',
  justify: 'center',
}

export default Button
