import { useOngoingFestivalsListQuery } from '~/generated/graphql'
import { getNavBarRoutes } from '~/shared/molecules/NavBarMobile/NavBarMobileUtils'
import NavBarMobile from '~/shared/molecules/NavBarMobile/NavBarMobileView'

const FestivalNavBarMobile: React.FC = () => {
  const { data: ongoingFestivalsData } = useOngoingFestivalsListQuery()
  return <NavBarMobile routes={getNavBarRoutes(ongoingFestivalsData?.ongoingFestivalEditions)} />
}

export default FestivalNavBarMobile
