import styled from '@emotion/styled'

import { StyledToast } from '../Toast'

export const NewStyledToast = styled(StyledToast)`
  min-width: 320px;
  width: max-content;
  width: fit-available;
  max-width: calc(100vw - 32px);
  overflow-wrap: anywhere;
  translate: var(--x) var(--y);
  scale: var(--scale);
  z-index: var(--z-index);
  height: var(--height);
  opacity: var(--opacity);
  will-change: translate, opacity, scale;
  transition:
    translate 400ms,
    scale 400ms,
    opacity 400ms;
  transition-timing-function: cubic-bezier(0.21, 1.02, 0.73, 1);

  &[data-state='closed'] {
    transition:
      translate 400ms,
      scale 400ms,
      opacity 200ms;
    transition-timing-function: cubic-bezier(0.06, 0.71, 0.55, 1);
  }
`

export const CloseTrigger = styled.button`
  display: flex;
  margin-left: auto;
`

export const NewToastsContainer = styled.div``
