import { SecondaryText } from '~/components/Festival/Banners/BannerShards'
import { defaultStyle } from '~/components/Festival/Banners/BannerUtils'
import {
  Banner,
  ButtonContainer,
  ButtonContentContainer,
  ButtonIconContainer,
  Container,
  ContentContainer,
  DesktopDate,
  Logo,
  MainText,
  MobileContainer,
  MobileDate,
  TextContainer,
} from '~/components/Festival/FestivalListingBanner/FestivalListingBannerShards'
import { FestivalListingBannerProps } from '~/components/Festival/FestivalListingBanner/FestivalListingBannerTypes'
import Button from '~/shared/atoms/Button'
import Icon from '~/shared/atoms/Icon'
import Link from '~/shared/atoms/Link'

const FestivalListingBannerView = ({
  title,
  subtitle,
  date,
  leftElement,
  button,
  logo,
  styles = defaultStyle,
}: FestivalListingBannerProps) => {
  const buttonLabel = button.withArrow ? (
    <ButtonContentContainer>
      {button.label}
      <ButtonIconContainer>
        <Icon icon='chevron-right' className='icon-right' />
      </ButtonIconContainer>
    </ButtonContentContainer>
  ) : (
    button.label
  )
  return (
    <Banner color={styles.bgColor}>
      {leftElement}
      <Container>
        <ContentContainer>
          <MobileContainer>
            <MobileDate color={styles.dateColor}>{date}</MobileDate>
            <Logo src={logo} alt='Festival logo' />
          </MobileContainer>
          <TextContainer>
            <MainText color={styles.titleFontColor}>{title}</MainText>
            {/* eslint-disable-next-line max-len */}
            <SecondaryText color={styles.fontColor}>{subtitle}</SecondaryText>
          </TextContainer>
          <DesktopDate color={styles.dateColor}>{date}</DesktopDate>
        </ContentContainer>
        <ButtonContainer>
          <Link href={button.href}>
            <Button label={buttonLabel} color={styles.buttonColor} width={'fixed'} as='a' target='_blank' />
          </Link>
        </ButtonContainer>
      </Container>
    </Banner>
  )
}

export default FestivalListingBannerView
