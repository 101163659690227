import { FestivalCodesEnum } from '~/generated/graphql'
import { gt as GT } from '~/locale'

import { ClubConfigCopy } from '../../types'

import { earlyReservation, voucherTranslation } from './pageTranslate'

const rwReservationStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your reservation...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Booking confirmed!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Reservation cancelled'),
})

const giftStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your purchase...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Congrats! Your gift purchase is complete!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Gift purchase cancelled'),
})

const beforeYouVisitTitle = (gt: typeof GT) => gt.tp('SuccessPage', 'Read before you visit')

const beforeYouVisitDefaultDescription = (gt: typeof GT) =>
  gt.tp(
    'SuccessPage',
    // eslint-disable-next-line max-len
    'Be eco! Don\'t print the confirmation - at the restaurant, the confirmation on your phone will be enough. Please be on time :). Punctuality gives everyone an equal opportunity to enjoy their dream restaurant experience. After the visit, log in to your account, select "Your reservations" and "Review". The restaurant counts on your honest opinion!'
  )

const marketingConsent = (gt: typeof GT) => ({
  footer: {
    label: gt.tp(
      'Footer',
      'I want to receive and use DISCOUNT CODES and get information about festivals, so I agree to receive commercial communications from Restaurant Club'
    ),
    expandedText: gt.tp(
      'Footer',
      ' to the contact details I have provided and I agree to the use of these details for the purposes of direct marketing via electronic communication (email, online social networks, the phone number I provided - SMS, phone contact). For more information on the processing of your personal data, please see <a href="{{ privacyPolicyLink }}" target="_blank">here</a>',
      {
        privacyPolicyLink: "/privacy-policy'",
      }
    ),
  },
  form: {
    label: gt.tp(
      'RegisterForm',
      'I want to receive and use DISCOUNT CODES and get information about festivals, so I agree to receive commercial communications from Restaurant Club'
    ),
    expandedText: gt.tp(
      'RegisterForm',
      ' to the contact details I have provided and I agree to the use of these details for the purposes of direct marketing via electronic communication (email, online social networks, the phone number I provided - SMS, phone contact). For more information on the processing of your personal data, please see <a href="{{ privacyPolicyLink }}" target="_blank">here</a>',
      {
        privacyPolicyLink: "/privacy-policy'",
      }
    ),
  },
})

const getMetadata = (gt: typeof GT) => ({
  default: {
    title: gt.tp('RestaurantWeekCZ', 'RestaurantWeek Czech Republic | The biggest restaurant festival in the world'),
    description: gt.tp(
      'Head',
      "Book top restaurants at a special price. Discover the show-stopping menus of Chefs and the world's most popular Restaurant Festival - RestaurantWeek®."
    ),
    imageUrl: 'https://restaurant-week.cz/assets/rwc-share.png',
    imageAlt: 'rwc-share',
    siteName: gt.tp('Head', 'restaurant-week.cz'),
    url: 'https://restaurant-week.cz',
  },
  festivals: {
    [FestivalCodesEnum.Rw]: {
      title: gt.tp('RestaurantWeekCZ', 'RestaurantWeek | The biggest culinary festival'),
      description: gt.tp(
        'RestaurantWeekCZ',
        'Exceptional 3 courses + a gift for the festival price in selected restaurants! Discover top chef menus and the most popular restaurant festival in the world - RestaurantWeek'
      ),
      imageUrl: 'https://restaurant-week.cz/assets/rwc-share.png',
      imageAlt: 'rwc-share',
    },
  },
})

const footerInfo = {
  addressLines: ['RestaurantClub s.r.o.', 'Jurečkova 643/20', 'Ostrava 702', 'info@restaurant-week.cz'],
}

export const copy: ClubConfigCopy = {
  voucher: voucherTranslation,
  rwReservationStatusMessages,
  giftStatusMessages,
  beforeYouVisitTitle,
  beforeYouVisitDefaultDescription,
  marketingConsent,
  metadata: getMetadata,
  earlyReservation,
  footerInfo,
}
