import { getStaticFilePath } from '~/utils/helpers'

import { Festival, Reservable, ReservableEnum } from '~/generated/graphql'

export const getFestivalImage = (festival: { code: Festival['code']; logo?: { preview?: string } }) => {
  const imageUrl = !!festival?.logo?.preview && festival.logo.preview
  return getStaticFilePath(`assets/voucher-cover/${festival.code}.png`) || imageUrl
}

type MetadatasType = Array<{ key: string; value: string }> | null | undefined
export const getMetadataValue = (metadatas: MetadatasType, key: string): string | undefined => {
  if (!Array.isArray(metadatas)) {
    return undefined
  }
  const metadata = metadatas.find(meta => meta.key === key)
  return metadata?.value || undefined
}

export const checkIfGiftEvent = (reservable: { __typename: Reservable['__typename']; metadatas?: MetadatasType }) =>
  !!getMetadataValue(reservable.metadatas, 'giftEvent') && reservable.__typename === ReservableEnum.Event

export const getMetadataBooleanValue = (metadatas: MetadatasType, key: string): boolean => {
  return Boolean(getMetadataValue(metadatas, key))
}
