import { RestaurantDetails } from '~/components/Product/ProductDetailsPanel/ProductDetailsPanelTypes'
import { FerModalQuery, SingleReservableExtraQuery } from '~/generated/graphql'
import { gt } from '~/locale'

export const getReservableExtraProperties = (reservableExtra: SingleReservableExtraQuery['reservableExtra']) => {
  const { restaurant, image, id, name, slug, price } = reservableExtra
  return {
    eyebrowText: restaurant?.name,
    titleText: name,
    photo: image?.preview,
    name,
    slug,
    price,
    id,
    buttonLabel: gt.tp('Reservation', 'Book a table with menu'),
  }
}

export const getFERProperties = (FER: FerModalQuery['festivalEditionRestaurant'] & { restaurant: RestaurantDetails }) => {
  const {
    price: customFerPrice,
    festivalEdition: {
      festival: { name, slogan, image },
      price,
    },
    id,
    restaurant,
  } = FER
  return {
    eyebrowText: name,
    titleText: slogan,
    photo: image?.preview,
    name: restaurant.name,
    slug: restaurant.slug,
    price: customFerPrice ?? price,
    id,
    buttonLabel: gt.tp('Reservation', 'Book a table'),
  }
}
