import { GetFestivalCopy } from '../..'
import { fineDiningWeekExpiresDate, getVoucherDetailsFaq, getVoucherDetailsFaqSummary, VoucherDetailsCopy } from '..'

export const getFdwCopy: GetFestivalCopy<VoucherDetailsCopy> = gt => ({
  title: gt.tp('VoucherDetailsPage', '{{ festival }} - Reservation for a gift 🎁', { festival: gt.tp('festival', 'FineDiningWeek') }),
  question: gt.tp('VoucherDetailsPage', 'How does the gift reservation work?'),
  description1: gt.tp(
    'VoucherDetailsPage',
    `Book the restaurant experience of your dreams for yourself or a loved one. Just select the number of Guests and you will receive a beautiful printable confirmation.`
  ),
  description2: gt.tp(
    'VoucherDetailsPage',
    `The code on the confirmation will be used to pay for the reservation of a table at the restaurant of your dreams on the date of your choice.
You will get to know the list of restaurants participating in the Festival 24 hours before other Guests!`
  ),
  summary: getVoucherDetailsFaqSummary(gt),

  faq: getVoucherDetailsFaq(gt, `${gt.tp('festival', 'FineDiningWeek')}`, fineDiningWeekExpiresDate).concat([
    {
      title: gt.tp('VoucherDetailsPage', 'When will the festival be held and which restaurants are participating?'),
      description: gt.tp(
        'VoucherDetailsPage',
        'FineDiningWeek® is held once a year in the summer, the approximate date of the Festival is July 2 - August 10, 2025. The date of the Festival is subject to change. If you would like us to notify you of the exact date, please sign up for the newsletter. At this time we cannot provide a list of restaurants. With Advance Booking you will know the list of restaurants 24 hours before other Guests.'
      ),
    },
    {
      title: gt.tp('VoucherDetailsPage', 'What if I cancel a reservation paid by code?'),
      description: gt.tp(
        'VoucherDetailsPage',
        'In case of cancellation, the code remains active and can be used again to pay for another reservation, regardless of the cancellation date.'
      ),
    },
  ]),
})
