// @ts-strict-ignore
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import dayjs from 'dayjs'
import _ from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { mediaQueryIsTablet } from '~/theme/utils/grid'
import { Body2, Heading5 } from '~/theme/utils/typography'
import { CheckoutFlow } from '~/utils/checkoutFlow'
import { dayjsToString, formatPrice, getPossibleDailySlots, getSlotFromTime } from '~/utils/graphqlDataFormatters'
import { UniversalContainer } from '~/utils/grid'
import { runEcommerceDetails } from '~/utils/gtag'
import useReservationCreate from '~/utils/hooks/useReservationCreate'
import { imgProxyLoader } from '~/utils/imgproxy'

import { ChefsMenuSelectHeader } from '~/components/Checkout/CheckoutPage/ChefsMenuDetailsModal'
import ProductDetailsPanel from '~/components/Product/ProductDetailsPanel'
import SlotSelectionPanel from '~/components/Reservation/SlotSelectionPanel'
import { getAvailableDates, getDefaultReservableExtraMenuCounts } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelUtils'
import NoSlotsBoard from '~/components/Restaurant/NoSlotsBoard'
import {
  ContentContainer,
  ModalContainer,
  ModalInnerContainer,
  ProductGrid,
  SliderContainer,
} from '~/components/Restaurant/RestaurantPage/RestaurantPageShards'
import { paths, RESERVABLE_TYPES } from '~/constants'
import { ReservableEnum, SingleReservableExtraQuery, SlotsUsageQuery, useCreateDailyReservationMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import { NextPageWithLayout } from '~/pages/_app'
import Button from '~/shared/atoms/Button'
import { useModal } from '~/shared/molecules/Modal'
import ProductPageTitle from '~/shared/molecules/ProductPageTitle'

import { ChefsMenuHeaderContainer, ChefsMenuSelectContainer, HeaderWrapper, ProductContainer } from './ChefsMenuPageShards'
import { slotSelectModalProps } from './ChefsMenuPageUtils'

export interface ChefsMenuPageProps {
  reservableExtra: SingleReservableExtraQuery['reservableExtra']
  initialSlotsData?: SlotsUsageQuery
  closeModal?: () => void
}

const ChefsMenuPage: NextPageWithLayout<ChefsMenuPageProps> = ({ reservableExtra, initialSlotsData, closeModal }) => {
  const router = useRouter()
  const { name, restaurant, price, image, enabled } = reservableExtra
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const daily = restaurant?.reservables?.find(({ __typename }) => __typename === ReservableEnum.Daily)

  useEffect(() => {
    runEcommerceDetails({
      reservableData: { restaurant, price, name },
      reservableType: RESERVABLE_TYPES.EXTRA,
    })
  }, [reservableExtra])

  const [createDailyReservation] = useReservationCreate({ useCreateReservationMutation: useCreateDailyReservationMutation })
  const [hasAvailableSlots, setSlotsAvailability] = useState(true)

  useEffect(() => {
    const availableDates = getAvailableDates(initialSlotsData?.slots)
    const hasSlots = initialSlotsData?.slots ? enabled && !_.isEmpty(availableDates) : enabled
    setSlotsAvailability(hasSlots)
  }, [initialSlotsData?.slots])
  const possibleDailySlots = getPossibleDailySlots(daily)
  const earliestReservableFrom = dayjs.parseZone(reservableExtra.reservableFrom)

  const slotSelect = daily && (
    <ModalInnerContainer>
      <SlotSelectionPanel
        header={
          <HeaderWrapper>
            <ChefsMenuSelectHeader price={price} />
          </HeaderWrapper>
        }
        type={RESERVABLE_TYPES.EXTRA}
        reservableInput={{ id: daily.id, type: ReservableEnum.Daily }}
        withReservableExtra={true}
        fieldsToHide={['name', 'submit', 'title']}
        possibleSlots={possibleDailySlots}
        price={price}
        dateRange={{
          startsOn: dayjsToString(earliestReservableFrom),
          endsOn: dayjsToString(earliestReservableFrom.clone().add(1, 'month').endOf('month')),
        }}
        defaultSlot={getSlotFromTime(earliestReservableFrom)}
        defaultDate={earliestReservableFrom}
        forceSearchParams={true}
        onSubmit={async params => {
          return createDailyReservation({
            variables: {
              ...params,
              date: dayjsToString(params.date),
              restaurantId: restaurant.id,
              reservableExtraAttrs: getDefaultReservableExtraMenuCounts(params.peopleCount, reservableExtra),
            },
            onCompleted: data => {
              router.push(paths.checkoutChefsMenu(data.createDailyReservation.code, CheckoutFlow.CHM))
              modal.close()
            },
          })
        }}
      />
      {/* <Infobox /> */}
    </ModalInnerContainer>
  )
  const modal = useModal()
  const mainImage = image?.preview || restaurant?.imageFiles?.[0]?.preview || ''

  const openSlotSelectModal = () => {
    closeModal && closeModal()
    modal.open(slotSelectModalProps, <ModalContainer>{slotSelect}</ModalContainer>)
  }

  const slotSelectContent = isTablet ? (
    <>
      <Heading5 fontWeight='regular'>
        <strong>{formatPrice(price)}</strong> {gt.tp('Checkout', '/ Guest')}
      </Heading5>
      <Button onClick={() => openSlotSelectModal()} color='orange' width='full' label={gt.tp('ChefsMenuPage', 'Book a table')} />
      <Body2>{gt.tp('ChefsMenuPage', 'Last spots!')}</Body2>
    </>
  ) : (
    slotSelect
  )
  const isChefsMenuModal = !!closeModal
  const content = (
    <ProductContainer hasImage={!!mainImage}>
      <ProductGrid>
        <ChefsMenuHeaderContainer isModal={isChefsMenuModal}>
          <ProductPageTitle restaurant={reservableExtra.restaurant} reservableExtra={reservableExtra} />
        </ChefsMenuHeaderContainer>
        <SliderContainer>
          <Image
            loader={imgProxyLoader}
            layout='responsive'
            src={mainImage}
            objectPosition={'left center'}
            objectFit='cover'
            height={311}
            width={560}
            priority={true}
          />
        </SliderContainer>
        <ChefsMenuSelectContainer>
          {daily && (initialSlotsData?.slots || hasAvailableSlots) ? (
            slotSelectContent
          ) : (
            <NoSlotsBoard type='chefsmenu' /* closeSelf={() => closeModal && closeModal()} */ />
          )}
        </ChefsMenuSelectContainer>
        <ContentContainer>
          <ProductDetailsPanel
            sections={['chefsMenuDescription', 'menu', 'restaurant']}
            restaurant={restaurant}
            reservableExtra={reservableExtra}
            defaultShow={['chefsMenuDescription']}
          />
        </ContentContainer>
      </ProductGrid>
    </ProductContainer>
  )

  if (closeModal) {
    return content
  }

  return <UniversalContainer>{content}</UniversalContainer>
}

export default ChefsMenuPage
