// @ts-strict-ignore

import { useCallback } from 'react'

import dayjs from 'dayjs'
import { NextRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

import { OpenChangeDetails } from '@zag-js/dialog'

import { dayjsToString } from '~/utils/graphqlDataFormatters'
import useNavigationHistory from '~/utils/hooks/useNavigationHistory'

import { defaultStyle } from '~/components/Festival/Banners/BannerUtils'
import FestivalBanner from '~/components/Festival/FestivalBanner'
import { fdwStyle } from '~/components/Festival/FestivalMenuBanner/FestivalMenuBannerUtils'
import FestivalMenuBanner from '~/components/Festival/FestivalMenuBanner/FestivalMenuBannerView'
import { OTHER_DATES_RESTAURANTS_LIMIT } from '~/components/Listing/ListingPageContent/ListingPageContentUtils'
import { getClubConfig } from '~/config/clubConfig'
import {
  FerListQueryVariables,
  FestivalCodesEnum,
  FestivalEdition,
  PageDetailQuery,
  ReservableEnum,
  useFerListQuery,
} from '~/generated/graphql'
import { MetadataType } from '~/layout/Head/HeadTypes'
import { gt } from '~/locale'
import ImageBanner from '~/shared/atoms/ImageBanner'

import { AllQueryVariables, FilterSection, FilterSettings } from '../../ListingPageContent/ListingPageContentTypes'
import { ChefsMenuFilterSettings } from '../ChefsMenuListingPage/ChefsMenuListingPageTypes'
import { getTagAndRestaurantIds, PAGE_SIZE } from '../RestaurantListingPage/RestaurantListingPageUtils'

export const getRangeStart = (startsOn?: dayjs.Dayjs, reservableFrom?: dayjs.Dayjs): dayjs.Dayjs => {
  if (!startsOn && !reservableFrom) return dayjs.parseZone().add(1, 'day')
  if (!startsOn || !reservableFrom) return startsOn || reservableFrom
  return startsOn.clone().isAfter(reservableFrom, 'hour') ? startsOn : reservableFrom
}

export const getFERListQueryVariables = ({
  selectedRegion,
  filters,
  search,
  festivalEditionId,
  endCursor,
  tagsAndNames,
  reservableFrom,
  endsOn,
  first,
  ids,
  startsOn,
}: AllQueryVariables): FerListQueryVariables => {
  const { tagIds } = getTagAndRestaurantIds(tagsAndNames)
  const allTagIds = [...filters.tags.map(({ id }) => id), ...tagIds]
  const { date, slot, peopleCount } = search || {}

  const rangeStart = date || getRangeStart(startsOn, dayjs.parseZone(reservableFrom))

  const rangeEnd = date || endsOn

  return {
    region_id: selectedRegion.id,
    festival_edition_id: festivalEditionId,
    reservation_filters: {
      startsOn: rangeStart && dayjsToString(rangeStart),
      endsOn: rangeEnd && dayjsToString(rangeEnd),
      hours: slot ? [slot] : [],
      peopleCount: peopleCount,
      reservableType: ReservableEnum.FestivalEditionRestaurant,
    },
    ...(allTagIds.length && { tag_ids: allTagIds }),
    ...(ids !== undefined ? { restaurant_ids: ids } : {}),
    first: first || PAGE_SIZE,
    after: endCursor || null,
  }
}

export const getFilterSections = ({
  festivalCode,
  isExternalSale,
}: {
  festivalCode: FestivalCodesEnum
  isExternalSale: boolean
}): FilterSection[] => {
  if (isExternalSale) {
    return []
  }

  switch (festivalCode) {
    case FestivalCodesEnum.Gw:
      return ['popular']
    default:
      return ['popular', 'cuisines', 'dishes']
  }
}

export const getFestivalMetadata = ({ festivalCode }: { festivalCode: FestivalCodesEnum }): MetadataType =>
  getClubConfig().copy.metadata(gt).festivals[festivalCode] || {}

// TODO: uncomment after release 16.12
export const useFersAvailableInOtherDates = ({
  filters,
  festivalEditionId,
}: {
  filters: AllQueryVariables
  festivalEditionId: FestivalEdition['id']
}) => {
  // TODO add valid query for  AvailableInOtherDates
  // eslint-disable-next-line no-unused-vars
  const { data } = useFerListQuery({
    variables: getFERListQueryVariables({ ...filters, festivalEditionId, first: OTHER_DATES_RESTAURANTS_LIMIT }),
  })

  return data?.festivalEditionRestaurants?.nodes || []
}

export const getBanners = (code: FestivalCodesEnum, isExternalSale: boolean, pageDetailData: PageDetailQuery) => {
  const banners = { topBanner: null, middleBanner: null }
  if (code && !isExternalSale) {
    const topBanner = pageDetailData?.pageDetail?.topBanner?.visible ? pageDetailData.pageDetail.topBanner : undefined
    const middleBanner = pageDetailData?.pageDetail?.middleBanner?.visible ? pageDetailData.pageDetail.middleBanner : undefined

    if (topBanner?.files) banners.topBanner = <FestivalBanner files={topBanner?.files} href={topBanner?.href} />
    if (middleBanner?.files) {
      banners.middleBanner = <FestivalBanner middle files={middleBanner?.files} href={middleBanner?.href} />
    }
  }
  return banners
}

export const getListingBanner = ({
  isGW,
  isExternalSale,
  isFdw,
  festivalEditionId,
  currentFilters: { region },
}: {
  isGW: boolean
  isExternalSale: boolean
  isFdw: boolean
  festivalEditionId: string
  currentFilters: FilterSettings | ChefsMenuFilterSettings
}) => {
  if (isGW) {
    return (
      <ImageBanner
        link='/restaurants/festival/ginweek'
        mobile={{ width: 750, height: 328, localPath: '/assets/listing/ginweekBannerMobile.jpg' }}
        desktop={{ width: 1068, height: 189, localPath: '/assets/listing/ginweekBannerDesktop.jpg' }}
      />
    )
  }
  if (isExternalSale) {
    return (
      <ImageBanner
        link='/restaurants/festival/pizzaweek'
        mobile={{ width: 750, height: 328, localPath: '/assets/listing/pizzaweekBannerMobile.jpg' }}
        desktop={{ width: 1068, height: 189, localPath: '/assets/listing/pizzaweekBannerDesktop.jpg' }}
      />
    )
  }
  return (
    <FestivalMenuBanner
      festivalEditionId={parseInt(festivalEditionId, 10)}
      location={`${region.id}-${region.name}`}
      styles={isFdw ? fdwStyle : defaultStyle}
    />
  )
}

enum FERDialogSource {
  Listing = 'listing',
}

const isDialogOpenedFromListing = (router: NextRouter) => router.query.source === FERDialogSource.Listing

export const useDialogManagement = (router: NextRouter) => {
  const ferId = router.query.ferId

  const navigate = useCallback(
    (method: 'replace' | 'push', query: ParsedUrlQuery) => {
      router[method]({ pathname: router.pathname, query }, undefined, { shallow: true })
    },
    [router]
  )

  const { canGoBack } = useNavigationHistory()

  const onOpenChange = useCallback(
    (details: OpenChangeDetails) => {
      if (!details.open) {
        if (isDialogOpenedFromListing(router) && canGoBack) {
          return router.back()
        }

        const { ferId: _deletedParam, source: _deletedParam2, ...newQuery } = router.query
        return navigate('replace', newQuery)
      }
    },
    [canGoBack, navigate, router]
  )

  const dialogContext = {
    open: !!ferId,
    onOpenChange,
    'open.controlled': true,
    id: 'ferDialog',
  }

  const handleOpen = useCallback(
    (ferId: string) => {
      navigate('push', { ...router.query, ferId, source: FERDialogSource.Listing })
    },
    [router.query, navigate]
  )

  return {
    ferId,
    handleOpen,
    dialogContext,
  }
}
