import dayjs, { OpUnitType } from 'dayjs'

import { Timestamp } from '~/types/common'

export const getTimeDifference = (start: Timestamp, end: Timestamp, units: OpUnitType = 'seconds') => {
  return dayjs.parseZone(end).diff(start, units)
}

export const getTimeLeft = (from: Timestamp, to: Timestamp, units: OpUnitType = 'seconds') => {
  return Math.max(getTimeDifference(from, to, units), 0)
}

export const isAfterTime = (to: Timestamp) => {
  return dayjs().isAfter(to)
}
