// @ts-strict-ignore
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import Modal, { ModalProps } from '.'

export type ModalContextType = {
  open: (props: ModalProps, content: ReactNode) => void
  close: () => void
}

export const ModalContext = createContext<ModalContextType | null>(null)

export const ModalProvider = props => {
  const [currentModal, setCurrentModal] = useState<ReactNode>()
  const [currentModalProps, setCurrentModalProps] = useState<ModalProps>()
  const [mounted, setMounted] = useState(false)

  const open = (modalProps: ModalProps, modalContent: ReactNode) => {
    setCurrentModal(modalContent)
    setCurrentModalProps(modalProps)
    if (modalProps.position === 'fixed') {
      document.body.style.overflow = 'hidden'
    }
  }

  const close = () => {
    setCurrentModal(null)
    document.body.style.overflow = null
  }

  const value = useMemo(() => ({ open, close }), [])

  const handleUserKeyPress = ({ key }: KeyboardEvent) => {
    if (!currentModal) {
      return
    }

    if (key === 'Escape' && currentModalProps?.closeOnEscapeKeyClick) {
      close()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    const handlePopState = () => {
      if (currentModalProps?.closeOnUrlChange) {
        close()
      }
    }
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [currentModal, currentModalProps])

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return (
    <ModalContext.Provider value={value}>
      {mounted && currentModal ? (
        <Modal handleClose={close} {...currentModalProps}>
          {currentModal}
        </Modal>
      ) : null}
      {props.children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const modalContext = useContext(ModalContext)
  if (!modalContext) {
    throw Error('Missing ModalContextProvider!')
  }
  return modalContext
}

const modalPropsTemplate = {
  hasVisibleOverlay: true,
  hasClickableOverlay: true,
  closeOnEscapeKeyClick: true,
  closeOnUrlChange: true,
  handleClick: () => null,
  position: 'fixed' as const,
  iconSize: 'big' as const,
}

export const bigModalPropsTemplate = {
  ...modalPropsTemplate,
  closeIconPosition: 'outside' as const,
  fillScreenOnMobile: true,
  size: 'big' as const,
}

export const smallModalPropsTemplate = {
  ...modalPropsTemplate,
  closeIconPosition: 'inside' as const,
  fillScreenOnMobile: false,
  size: 'small' as const,
}
