import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { useReleaseSlotMutation } from '~/generated/graphql'

export function useReleaseSlot() {
  const router = useRouter()

  const [releaseSlot] = useReleaseSlotMutation()

  const handleReleaseSlot = useCallback(() => {
    releaseSlot({ variables: { reservationCode: router.query.code as string } })
  }, [releaseSlot, router.query])

  return handleReleaseSlot
}
