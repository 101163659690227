// @ts-strict-ignore
import { useMemo, useState } from 'react'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'

import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Heading5, Heading6 } from '~/theme/utils/typography'
import { dayjsToString, getTimeFromSlot } from '~/utils/graphqlDataFormatters'
import { runEcommerceAdd } from '~/utils/gtag'

import CardActions from '~/components/Product/ProductCardActions'
import { getDayUsageVariables } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelUtils'
import { DEFAULT_SLOT_COMPUTED, defaultSearchSettings, paths, RESERVABLE_TYPES } from '~/constants'
import { ReservableInput, useCreateDailyReservationMutation, useSlotsUsageQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import Icon from '~/shared/atoms/Icon'
import Pill from '~/shared/atoms/Pill'
import { useModal } from '~/shared/molecules/Modal'
import { SearchSettings } from '~/shared/molecules/Search'
import TagBar from '~/shared/molecules/TagBar'

import SearchSlotsModalView from '../../SearchSlotsModal'
import { LeanReservable } from '../../SearchSlotsModal/SearchSlotsModalTypes'
import { modalProps } from '../../SearchSlotsModal/SearchSlotsModalUtils'
import { RestaurantCardProps } from '..'
import { MainContentContainer, RestaurantDescription, RestaurantName, TagContainer } from '../CardShards'
import { ImageSection } from '../components'

import { LoadingPills } from './DailyCardUtils'
import {
  Arrow,
  NoSlotsBadge,
  NoSlotsBadgeText,
  NoSlotsText,
  NoSlotsWrapper,
  PillContainer,
  ReservationsCount,
  Wrapper,
} from './DailyCardViewShards'

const { DAILY } = RESERVABLE_TYPES

export type RestaurantCardType = 'default' | 'daily' | 'FER'

interface DailyCardProps extends Omit<RestaurantCardProps, 'restaurant'> {
  slotParams?: SearchSettings
  reservableInput?: ReservableInput
  restaurant?: Omit<RestaurantCardProps['restaurant'], 'reservables'> & {
    reservables: Array<
      { __typename: 'Daily'; id: string } | { __typename: 'Event'; id: string } | { __typename: 'FestivalEditionRestaurant'; id: string }
    >
  }
}

export const DailyCardView = ({
  restaurant,
  slotParams: params = defaultSearchSettings({ defaultSlot: DEFAULT_SLOT_COMPUTED }),
  subtitle,
  type = 'default',
  reservableInput,
  withCarousel,
  onClick,
  ...props
}: DailyCardProps) => {
  const { tp, tnp } = gt
  const router = useRouter()
  const { imageFiles, externalSaleUrl } = props
  const { id: restaurantId, name, slug, description, favorited } = restaurant
  const reservationsCount = 0
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const modal = useModal()
  const { date, peopleCount } = params
  const dateString = date ? dayjsToString(date) : ''
  const { data: slotsUsageData, loading: slotsLoading } = useSlotsUsageQuery({
    skip: type !== 'daily' || !dateString,
    variables: getDayUsageVariables(dateString, reservableInput),
  })

  const slotsToShow = Array.from({ length: 5 }).map((_, i) => params?.slot + (i * 30 - 60))
  const availableSlots: number[] = slotsUsageData?.slots
    .filter(({ slot, sizes }) => slotsToShow.includes(slot) && peopleCount <= sizes.length)
    .map(({ slot }) => slot)
  const [submittingSlot, setSubmittingSlot] = useState<number | null>(null)
  const [isHovered, setIsHovered] = useState(false)
  const [create] = useCreateDailyReservationMutation()
  const dailyReservable = restaurant.reservables?.find(reservable => reservable.__typename === DAILY)
  const createDailyReservation = (e, slot) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmittingSlot(slot)
    create({
      variables: {
        date: dateString,
        slot,
        peopleCount,
        restaurantId,
      },
      onCompleted: data => router.push(paths.checkoutChefsMenu(data.createDailyReservation.code)),
      onError: () => setSubmittingSlot(null),
    })

    runEcommerceAdd({
      reservableType: DAILY,
      reservableData: { restaurant, quantity: peopleCount, price: 0 },
    })
  }

  const isDaily = type === 'daily'

  const cardLink = useMemo(() => {
    return (
      externalSaleUrl || {
        pathname: paths.singleRestaurant({ slug }),
        query: router.query,
      }
    )
  }, [externalSaleUrl, router.query, slug])

  return (
    <>
      <Wrapper withBorder horizontal onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={onClick}>
        <CardActions href={cardLink}>
          <MainContentContainer cardType={type}>
            <ImageSection
              type={type}
              restaurantId={restaurantId}
              favorited={favorited}
              withCarousel={withCarousel}
              isHovered={isHovered}
              restaurantName={restaurant.name}
              sliderImages={imageFiles}
            />
            {reservationsCount > 0 && (
              <ReservationsCount>
                {tnp('Restaurant', '{{ count }} reservation today', '{{ count }} reservations today', reservationsCount, {
                  count: reservationsCount,
                })}
              </ReservationsCount>
            )}
            <RestaurantName as={isMobile ? Heading6 : Heading5} fontWeight='semiBold'>
              {name}
            </RestaurantName>
            <RestaurantDescription fontWeight='regular'>{subtitle ?? description}</RestaurantDescription>
            <TagContainer>
              <TagBar
                variant={isDaily ? 'default' : 'plainText'}
                sections={['price']}
                maxBaseTagCount={5}
                size={'small'}
                restaurant={restaurant}
                reservableExtra={undefined}
              />
            </TagContainer>
            {params.date && (
              <>
                {slotsLoading ? (
                  <LoadingPills />
                ) : !isEmpty(availableSlots) ? (
                  <PillContainer>
                    {slotsToShow?.map(slot => (
                      <Pill
                        key={slot}
                        loading={slot === submittingSlot}
                        disabled={!availableSlots.includes(slot)}
                        onClick={e => createDailyReservation(e, slot)}
                        text={getTimeFromSlot(slot)}
                      />
                    ))}
                  </PillContainer>
                ) : (
                  <NoSlotsWrapper>
                    <NoSlotsText>
                      {tp('Restaurant', 'No available slots for {{ time }}', { time: getTimeFromSlot(params.slot) })}
                    </NoSlotsText>
                    <NoSlotsBadge
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        modal.open(
                          modalProps,
                          <SearchSlotsModalView
                            restaurant={restaurant}
                            reservableInput={reservableInput}
                            reservable={dailyReservable as LeanReservable}
                          />
                        )
                      }}>
                      <NoSlotsBadgeText>{tp('Restaurant', 'Search for available slots')}</NoSlotsBadgeText>
                      <Arrow>
                        <Icon icon='arrow' />
                      </Arrow>
                    </NoSlotsBadge>
                  </NoSlotsWrapper>
                )}
              </>
            )}
          </MainContentContainer>
        </CardActions>
      </Wrapper>
    </>
  )
}
