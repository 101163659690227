import { SkeletonDividerElement, SkeletonSlotsElement, SkeletonSlotsHeaderElement, Wrapper } from './SlotSelectionPanelShards'

const SlotSelectionSkeleton = () => (
  <Wrapper type=''>
    <SkeletonSlotsHeaderElement />
    <SkeletonDividerElement />
    <SkeletonSlotsElement />
    <SkeletonSlotsElement />
    <SkeletonSlotsElement />
  </Wrapper>
)

export default SlotSelectionSkeleton
