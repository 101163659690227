import styled from '@emotion/styled'

import { ImageContainer, Menus, Profile, Wrapper } from '../../FERModalShards'

export const SkeletonWrapper = styled(Wrapper)`
  @media (max-width: 600px) {
    height: 100%;
  }
`

export const SkeletonProfile = styled(Profile)`
  @media (min-width: 600px) {
    min-width: 400px;
  }
`

export const SkeletonImageContainer = styled(ImageContainer)``

export const SkeletonMenus = styled(Menus)<{ height?: string }>`
  height: ${({ height = 'auto' }) => height};
  background-color: ${({ theme }) => theme.colors.gray[200]};
  border-radius: 0.625rem;
`

export const SkeletonImage = styled.div`
  border-radius: 0.625rem;
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.gray[200]};
`

export const SkeletonContent = styled.div<{ margin?: string; height?: string }>`
  border-radius: 0.625rem;
  width: 100%;
  height: ${({ height = 'auto' }) => height};
  background-color: ${({ theme }) => theme.colors.gray[200]};
  margin: ${({ margin = 0 }) => margin};
`
