// @ts-strict-ignore
import { get } from 'lodash'

import { RESERVABLE_TYPES } from '~/constants'

const { EXTRA, DAILY, EVENT } = RESERVABLE_TYPES

export enum GTMEventAction {
  Navigate = 'navigate',
  Click = 'click',
  ProductDetail = 'product_detail',
  ViewPromotion = 'view_promotion',
  Add = 'add_to_cart',
  Checkout = 'checkout',
  Purchase = 'purchase',
}

export enum GTMEvent {
  Ecommerce = 'ecommerce',
  UI = 'ui',
  Filter = 'filter',
}

export const getName = (type, data) => {
  if (type === EXTRA) return get(data, 'name')
  if (type === EVENT) return get(data, 'title')
  return get(data, 'restaurant.name')
}

export const getVariant = (type, data) => {
  if (type === DAILY) return 'D2D'
  if (type === EXTRA) return 'ReservableExtra'
  return get(data, 'festivalEdition.code') || get(data, 'code')
}

export const getCategory = type => {
  return type === DAILY || type === EXTRA ? 'D2D' : type
}

export const getFields = (reservableType, data) => ({
  name: getName(reservableType, data),
  brand: get(data, 'restaurant.name') || get(data, 'title'),
  category: getCategory(reservableType),
  variant: getVariant(reservableType, data),
  price: get(data, 'price') ?? 0,
})

export const getVoucher = data => ({
  name: 'wczesniejsza rezerwacja',
  price: get(data, 'price'),
  brand: 'Restaurant Club',
  variant: get(data, 'code'),
  category: 'Voucher',
})
