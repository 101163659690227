import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import { ButtonWidth } from '~/shared/atoms/Button/ButtonTypes'

import { ButtonIcon, Icon, IconWrapper, SmallCounter, Value, Wrap, Wrapper } from './CounterShards'

interface Props {
  min: number
  max: number
  value: number
  onIncrement: () => void
  onDecrement: () => void
  width?: ButtonWidth
  hasButtonLabel?: boolean
  disabled?: boolean
  size?: 'small' | 'regular'
  isLoading?: boolean
  variant?: 'outlined'
}

const CounterView: React.FC<Props> = ({
  size = 'regular',
  min,
  max,
  value,
  onIncrement,
  onDecrement,
  width = 'fixed',
  hasButtonLabel = true,
  disabled = false,
  variant,
}) => {
  const hidden = size === 'small' && value === 0
  const counter = (
    <Wrapper hidden={hidden} size={size}>
      {hidden ? null : (
        <>
          <IconWrapper hidden={hidden} disabled={value <= min || disabled} onClick={onDecrement}>
            <Icon icon='circle-subtract' />
          </IconWrapper>
          <Value hidden={hidden} fontWeight='medium'>
            {value}
          </Value>
        </>
      )}
      <IconWrapper hidden={false} disabled={value >= max || disabled} onClick={onIncrement}>
        <Icon icon='circle-add' />
      </IconWrapper>
    </Wrapper>
  )
  const addLabel = (
    <>
      <ButtonIcon icon='circle-add' variant={variant} />
      {gt.tp('Button', 'Add')}
    </>
  )

  if (size === 'small') {
    return <SmallCounter value={value}>{counter}</SmallCounter>
  }

  return (
    <Wrap width={width} variant={variant}>
      {hasButtonLabel && !value ? (
        <Button
          height='small'
          width={width}
          label={addLabel}
          variant={variant}
          color='orange'
          onClick={!value ? onIncrement : () => null}
          disabled={(!value && disabled) || (!value && value >= max)}
        />
      ) : (
        counter
      )}
    </Wrap>
  )
}

export default CounterView
