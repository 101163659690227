// @ts-strict-ignore
import { useMediaQuery } from 'react-responsive'

import Image from 'next/image'

import { mediaQueryIsMobile, UniversalContainer } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'
import { getLogos } from '~/utils/helpers'
import useMobileNavigation from '~/utils/hooks/useMobileNavigation'

import { getClubConfig } from '~/config/clubConfig'
import { paths } from '~/constants'
import { FestivalCodesEnum } from '~/generated/graphql'
import { gt } from '~/locale'
import Collapse from '~/shared/atoms/Collapse'
import Link from '~/shared/atoms/Link'
import { VisibleIfFeatureFlag } from '~/shared/atoms/VisibleIfFeatureFlag'
import JoinTheClub from '~/shared/molecules/Footer/JoinTheClub'

import Awards from './Awards'
import {
  AwardsContainer,
  BottomLinks,
  Copyright,
  FooterContainer,
  FooterPartner,
  IconButton,
  LinkItem,
  LinkMap,
  Logo,
  Partners,
  SocialMedia,
  Wrapper,
} from './FooterShards'
import { getFooterLinkData } from './FooterUtils'

type FestivalListingFooterProps = {
  festivalCode?: string
}

const Footer = ({ festivalCode }: FestivalListingFooterProps) => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const isMobileNavigation = useMobileNavigation()
  const clubConfig = getClubConfig()

  const linkData = getFooterLinkData(festivalCode)
  const { tp } = gt

  const logoSize = (festivalCode: string) => {
    switch (festivalCode) {
      case FestivalCodesEnum.Bw:
        return 'medium'
      default:
        return 'small'
    }
  }

  const LinkSection = isMobile ? Collapse : 'section'
  return (
    <FooterContainer>
      {clubConfig.footerAwardsEnabled && (
        <AwardsContainer>
          <UniversalContainer>
            <Awards />
          </UniversalContainer>
        </AwardsContainer>
      )}
      <UniversalContainer>
        <Wrapper>
          <Logo src={getLogos(festivalCode as FestivalCodesEnum).centered} size={logoSize(festivalCode)} alt='RestaurantClub Logo' />
          <LinkMap>
            {linkData.map(section => {
              const sectionTitle = typeof section.title === 'string' ? section.title : section.title(clubConfig.hideRCInfo)
              const collapseProps = isMobile ? { title: tp('Footer', sectionTitle) } : {}

              return (
                <VisibleIfFeatureFlag key={sectionTitle} hasValue={false} name={section.disabledForFeatureFlag}>
                  <LinkSection key={sectionTitle} size='regular' {...collapseProps}>
                    {!isMobile && <Heading6 fontWeight='semiBold'>{tp('Footer', sectionTitle)}</Heading6>}
                    {section.links.map(link => {
                      return link.href ? (
                        <VisibleIfFeatureFlag
                          name={link.enabledForFeatureFlag?.key}
                          hasValue={link.enabledForFeatureFlag?.value}
                          key={link.label}>
                          <Link href={link.href}>
                            <LinkItem onClick={link?.onClick ?? (() => null)}>{tp('Footer', link.label)}</LinkItem>
                          </Link>
                        </VisibleIfFeatureFlag>
                      ) : (
                        <VisibleIfFeatureFlag
                          name={link.enabledForFeatureFlag?.key}
                          hasValue={link.enabledForFeatureFlag?.value}
                          key={link.label}>
                          <LinkItem key={link.label} onClick={link.onClick}>
                            {tp('Footer', link.label)}
                          </LinkItem>
                        </VisibleIfFeatureFlag>
                      )
                    })}
                  </LinkSection>
                </VisibleIfFeatureFlag>
              )
            })}
          </LinkMap>
          <JoinTheClub />
          <Partners>
            <FooterPartner>
              <p>{tp('Footer', 'Payments partner')}</p>
              <a href='https://poland.payu.com/' target='_blank' rel='noreferrer'>
                <Image src='/assets/logo-payu-partner.svg' width={96} height={34} alt='Logo PayU' />
              </a>
            </FooterPartner>
            <FooterPartner>
              <p>{tp('Footer', 'Technology partner')}</p>
              <Image src='/assets/partners/sales-manago-logo.svg' width={131} height={20} alt='Logo Sales Manago' />
            </FooterPartner>
          </Partners>

          <SocialMedia>
            {clubConfig.paths.socialMedia.map(socialMedium => (
              <a key={socialMedium.social} href={socialMedium.href}>
                <IconButton icon={socialMedium.social} />
              </a>
            ))}
          </SocialMedia>
          {isMobile ? (
            <BottomLinks addPadding={isMobileNavigation}>
              <Link href={paths.privacyPolicy}>
                <LinkItem> {tp('Footer', 'Privacy Policy')} </LinkItem>
              </Link>
              <Link href={paths.regulations}>
                <LinkItem> {tp('Footer', 'Terms and Conditions')} </LinkItem>
              </Link>
              <Link href={paths.home}>
                <LinkItem> {tp('Footer', 'Contact')}</LinkItem>
              </Link>
              <LinkItem onClick={() => window.OneTrust.ToggleInfoDisplay()}>{tp('Footer', 'Cookies')}</LinkItem>
            </BottomLinks>
          ) : (
            clubConfig.showFooterCopyright && (
              <Copyright>
                {tp(
                  'Footer',
                  `Copyright © 2023 Restaurant Club Sp. z o. o., Aleje Ujazdowskie 24 lok. 23a, 00-478 Warszawa,
info@restaurantclub.pl, +48 22 113 43 00`
                )}
              </Copyright>
            )
          )}
        </Wrapper>
      </UniversalContainer>
    </FooterContainer>
  )
}

export default Footer
