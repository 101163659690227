import { validationFields } from '~/utils/validationFields'
import yup from '~/utils/validationMessages'

export const joinTheClubSchema = yup.object().shape({
  email: validationFields.email,
})

export const benefitsData = [
  'Discounts - the ability to use them',
  'Festivals - information when festivals start',
  'Gifts - from Festival Partners',
  'Contests - prizes to be won',
]
