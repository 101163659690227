import { EarlyReservationTranslations } from '~/config/clubConfig/types'
import { gt as GT } from '~/locale'

const bottomSection = (gt: typeof GT) =>
  gt.tp(
    'EarlyReservationPage',
    'Are you a T-Mobile customer? Enter the code you received in the Magenta Moments Program in the box above. Important! It will be automatically included in the payment window at the end of your reservation of any table.'
  )

export const earlyReservation: EarlyReservationTranslations = {
  bottomSection,
}
