// @ts-strict-ignore
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import Image from 'next/image'

import { mediaQueryIsTablet } from '~/theme/utils/grid'
import { Heading5, Heading6 } from '~/theme/utils/typography'
import { runEcommerceDetails } from '~/utils/gtag'
import { imgProxyLoader } from '~/utils/imgproxy'

import RestaurantSlider from '~/components/Restaurant/RestaurantPage/RestaurantSlider'
import { FerModalQuery, ReservableEnum, useFerModalQuery } from '~/generated/graphql'
import ProductPageTitle from '~/shared/molecules/ProductPageTitle'

import { FERModalSkeleton, FERModalSlotSelect, FERModalTabletDetails } from './components'
import { FERModalMenus } from './components/FERModalMenus'
import { Details, Grid, ImageContainer, Profile, Wrapper } from './FERModalShards'
import { renderSliderItem } from './FERModalUtils'

interface Props {
  withCarousel?: boolean
  ferId: string
  isTablet?: boolean
}

const FERModalView = (props: Props) => {
  const { data, loading } = useFerModalQuery({ variables: { ferId: props.ferId }, fetchPolicy: 'cache-first' })

  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })

  if (loading) return <FERModalSkeleton isTablet={isTablet} />

  return <FERModalBody {...props} ferData={data.festivalEditionRestaurant} isTablet={isTablet} />
}

const FERModalBody = ({
  withCarousel,
  ferData,
  isTablet = false,
}: Omit<Props, 'ferId'> & { ferData: FerModalQuery['festivalEditionRestaurant'] }) => {
  const { price: customFerPrice, imageFiles, restaurant, festivalEdition, menus } = ferData || {}
  const price = customFerPrice ?? festivalEdition?.price

  const FestivalName = isTablet ? Heading6 : Heading5

  useEffect(() => {
    runEcommerceDetails({
      reservableData: { restaurant, price, festivalEdition },
      reservableType: ReservableEnum.FestivalEditionRestaurant,
    })
  }, [])

  return (
    <Wrapper>
      <Grid>
        <Profile>
          <ImageContainer>
            {withCarousel ? (
              <RestaurantSlider
                images={imageFiles}
                restaurantName={restaurant.name}
                withFullGallery={false}
                withControls={false}
                autoPlay={true}
                autoHeight={false}
                mouseTracking={false}
                renderItem={renderSliderItem}
              />
            ) : (
              <Image loader={imgProxyLoader} layout='fill' src={imageFiles[0]?.preview} objectFit='cover' objectPosition='center' />
            )}
          </ImageContainer>
          <Details>
            <FestivalName fontWeight='semiBold'>{festivalEdition.festival.name}</FestivalName>
            <ProductPageTitle hideFavourite={true} restaurant={restaurant} />
            {isTablet && <FERModalTabletDetails price={price} fer={ferData} />}
          </Details>
          {!isTablet && <FERModalMenus festivalEdition={festivalEdition} menus={menus} restaurant={restaurant} />}
        </Profile>

        {!isTablet ? <FERModalSlotSelect price={price} fer={ferData} menus={menus} /> : null}
      </Grid>
    </Wrapper>
  )
}

export default FERModalView
