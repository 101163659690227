import { useCallback, useMemo, useState } from 'react'

import { useRouter } from 'next/router'

import { NetworkStatus, QueryHookOptions, QueryResult } from '@apollo/client'

import { filtersFromQuery } from '~/utils/filters'

import { AllQueryVariables, ListingPageType } from '~/components/Listing/ListingPageContent/ListingPageContentTypes'
import { defaultSearchSettings } from '~/constants'
import { paths } from '~/constants/paths'
import { FerListQuery, RestaurantsListQuery, useSelectedRegionQuery } from '~/generated/graphql'

export const orientationBreakpoint = '800px'

export const parseLocationQueryString = (
  locationQueryString: string | undefined
): { __typename: 'CookieRegion'; id: string; name: string; isProposed: boolean } | null => {
  if (!locationQueryString) return null

  const parts = locationQueryString.split('-')
  if (parts.length === 2) {
    return {
      __typename: 'CookieRegion' as const,
      id: parts[0],
      name: parts[1],
      isProposed: false,
    }
  }
  return null
}

export const selectedRegionToQueryString = (selectedRegion: { id?: string; name?: string }): string => {
  return `${selectedRegion.id}-${selectedRegion.name}`
}

export const getPath = (type: ListingPageType, slug: string) => {
  switch (type) {
    case 'FER': {
      return paths.singleFestival(slug)
    }
    case 'daily': {
      return paths.restaurants
    }
    case 'chefsmenu': {
      return paths.chefsMenuOffers
    }
    default: {
      return paths.restaurants
    }
  }
}

// temporary solution, leave the hook useListingQuery and create useFERListingQuery, If useFERListingQuery will work correctly, we will need to standardize
export const useFERListingQuery = <QueryData, QueryVariables>({
  getQueryVariables,
  useQueryHook,
  defaultVariables,
}: {
  getQueryVariables: (filters: AllQueryVariables) => QueryVariables
  useQueryHook: (options: QueryHookOptions<QueryData, QueryVariables>) => QueryResult<QueryData, QueryVariables>
  defaultVariables: QueryVariables
}) => {
  const [filters, setFilters] = useState<AllQueryVariables>()

  const variables = filters && getQueryVariables(filters)

  const { data, fetchMore, networkStatus, loading } = useQueryHook({
    variables: variables || defaultVariables,
    notifyOnNetworkStatusChange: true,
  })

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore

  return {
    data,
    isFetchingMore,
    fetchMore,
    loading,
    setFilters,
    filters,
  }
}

export const useListingQuery = <QueryData, QueryVariables>({
  getQueryVariables,
  useQueryHook,
  pageSize,
}: {
  getQueryVariables: (filters: AllQueryVariables) => QueryVariables
  useQueryHook: (options: QueryHookOptions<QueryData, QueryVariables>) => QueryResult<QueryData, QueryVariables>
  pageSize: number
}) => {
  const router = useRouter()
  const { data: selectedRegionData } = useSelectedRegionQuery()

  const overrideSelectedRegion = parseLocationQueryString(router.query.location as string)
  const selectedRegion = overrideSelectedRegion || selectedRegionData?.selectedRegion

  const defaultFilters: AllQueryVariables = useMemo(
    () => ({
      selectedRegion,
      filters: filtersFromQuery(router.query),
      search: defaultSearchSettings({ untypedQuery: router.query }),
      first: pageSize,
    }),
    [pageSize, router.query, selectedRegion]
  )

  const [filters, setFilters] = useState<AllQueryVariables>(defaultFilters)

  const variables = useMemo(() => getQueryVariables(filters), [filters, getQueryVariables])

  const { data, fetchMore, networkStatus, loading } = useQueryHook({
    variables,
    notifyOnNetworkStatusChange: true,
  })

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore

  const setNewFilters = useCallback(
    (newFilters: AllQueryVariables) => {
      setFilters({ ...defaultFilters, ...newFilters })
    },
    [defaultFilters]
  )

  return {
    data,
    isFetchingMore,
    fetchMore,
    loading,
    setFilters: setNewFilters,
    filters,
  }
}

export const hasRestaurantsAvailableInOtherDates = (
  restaurants?: FerListQuery['festivalEditionRestaurants'] | RestaurantsListQuery['restaurants']
) => restaurants?.nodes && restaurants.nodes.length > 0

export const OTHER_DATES_RESTAURANTS_LIMIT = 5
