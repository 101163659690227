import React from 'react'

import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'

import { processEnv } from '~/utils/env'
import { getStaticFilePath } from '~/utils/helpers'

import config from '~/config'
import { getClubConfig } from '~/config/clubConfig'

import { MetadataType } from './HeadTypes'
import { getDefaultMetadatas } from './HeadUtils'

const generatePath = (asPath: string, locale: string = '') => `${config().clubUrl}${locale}${asPath}`

const HeadSEO: React.FC<{ metadata?: MetadataType }> = ({ metadata }) => {
  const title = metadata?.title || getDefaultMetadatas().title
  const description = metadata?.description || getDefaultMetadatas().description

  const { asPath } = useRouter()

  const clubConfig = getClubConfig()

  return (
    <DefaultSeo
      title={title}
      description={description}
      canonical={metadata?.canonical}
      additionalLinkTags={[
        {
          rel: 'icon',
          href: getStaticFilePath('favicon.png'),
        },
        {
          rel: 'apple-touch-icon',
          href: getStaticFilePath('favicon.png'),
        },
      ]}
      openGraph={{
        url: metadata?.url || getDefaultMetadatas().url,
        title,
        description,
        images: [
          {
            url: metadata?.imageUrl || getDefaultMetadatas().imageUrl,
            alt: metadata?.imageAlt || getDefaultMetadatas().imageAlt,
            type: metadata?.imageUrl ? 'image/jpg' : 'image/png',
          },
        ],
        site_name: getDefaultMetadatas().siteName,
      }}
      languageAlternates={Object.keys(clubConfig.enabledLanguages)
        .map(key => ({ hrefLang: key, href: generatePath(asPath, key && key !== processEnv.DEFAULT_LANG ? `/${key}` : '') }))
        .concat({ hrefLang: 'x-default', href: generatePath(asPath) })}
    />
  )
}

export default HeadSEO
