// @ts-strict-ignore
import { CSSProperties } from 'react'

import dayjs from 'dayjs'

import { Heading6 } from '~/theme/utils/typography'

import { PopoverView } from '~/shared/molecules/Popover/PopoverView'

import Calendar from '../Calendar'
import Icon from '../Icon'

import { ActionWrapper, ClearWrapper, Content, SelectIconWrapper } from './SelectShards'
import { getLabel } from './SelectUtils'

interface Props {
  availableDates?: dayjs.Dayjs[]
  highlightedDates?: dayjs.Dayjs[]
  minDate?: dayjs.Dayjs
  value?: dayjs.Dayjs
  onChange(value: dayjs.Dayjs): void
  error?: boolean
  icon?: React.ReactNode
  style?: CSSProperties
  hideOnChange?: boolean
  className?: string
  onPrevMonthClick?(): void
  onNextMonthClick?(): void
  onClose?(): void
  loading?: boolean
  hideYear?: boolean
  scrollTarget?: HTMLElement
  portalled?: boolean
  isClearable?: boolean
  defaultCalendarDate?: dayjs.Dayjs
}

const SelectCalendar: React.FC<Props> = ({
  availableDates,
  highlightedDates,
  minDate,
  value,
  onChange,
  style,
  error,
  icon,
  hideOnChange,
  className,
  onPrevMonthClick,
  onNextMonthClick,
  loading,
  hideYear,
  portalled = false,
  isClearable = false,
  defaultCalendarDate,
}) => {
  const label = getLabel(value, hideYear)
  const showClear = isClearable && value && !value.isSame(defaultCalendarDate, 'day')
  const handleChange = (value: dayjs.Dayjs) => {
    onChange(value)
  }

  return (
    <PopoverView
      popoverContext={{
        portalled,
        positioning: {
          placement: 'bottom-start',
          flip: false,
        },
      }}
      renderTrigger={api => (
        <Content className={className} style={style} error={error} active={api.open}>
          {icon && <SelectIconWrapper>{icon}</SelectIconWrapper>}
          <Heading6 as='div' fontWeight='medium'>
            {label}
          </Heading6>
          {showClear ? (
            <ClearWrapper
              active={api.open}
              onClick={() => {
                handleChange(defaultCalendarDate)
              }}>
              <Icon icon='x' />
            </ClearWrapper>
          ) : (
            <ActionWrapper active={api.open}>
              <Icon icon='caret-down' />
            </ActionWrapper>
          )}
        </Content>
      )}
      renderContent={api => (
        <Calendar
          value={value}
          onChange={value => {
            if (hideOnChange) {
              api.setOpen(false)
            }
            handleChange(value)
          }}
          availableDates={availableDates}
          highlightedDates={highlightedDates}
          minDate={minDate}
          focused={api.open}
          onPrevMonthClick={onPrevMonthClick}
          onNextMonthClick={onNextMonthClick}
          loading={loading}
        />
      )}
    />
  )
}

export default SelectCalendar
