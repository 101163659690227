// @ts-strict-ignore
import { SentryLink } from 'apollo-link-sentry'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { isEqual, uniqBy, uniqWith } from 'lodash'
import router from 'next/router'

import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { NetworkError } from '@apollo/client/errors'
import { onError } from '@apollo/client/link/error'

import { getGraphQLApiUrl } from '~/utils/helpers'

import { APOLLO_STATE_PROP_NAME, COOKIE, DEFAULT_REGION, HEADERS } from '~/constants'
import { SelectedRegionDocument, Slot } from '~/generated/graphql'

import { getCookieAsParsedJson } from './cookie'

export const passSsrCacheToClientScript = initialState =>
  initialState && (
    <script
      defer
      dangerouslySetInnerHTML={{
        __html: `window.${APOLLO_STATE_PROP_NAME}=${JSON.stringify(initialState).replace(/</g, '\\u003c')};`,
      }}
    />
  )

const mergeNodesUnique = (existing: { nodes: unknown[] }, incoming: { nodes: unknown[] }) => {
  const allNodes = [...(existing?.nodes || []), ...(incoming?.nodes || [])]

  return {
    ...existing,
    ...incoming,
    nodes: uniqBy(allNodes, '__ref'),
  }
}

const mergeNodesMenuUnique = (existing: { nodes: unknown[] }, incoming: { nodes: unknown[] }, { args }) => {
  const allNodes = [...(existing?.nodes || []), ...incoming.nodes]
  return {
    ...existing,
    ...incoming,
    nodes: uniqWith(allNodes, isEqual),
  }
}

export const isAbortError = (error: NetworkError) => error && error.name === 'AbortError'

let apolloClient = null

function create(initialState, ctx: any = {}) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const cache = new InMemoryCache({
    typePolicies: {
      Slot: {
        keyFields: ['date', 'slot', 'reservable', ['__typename', 'id']],
      },
      Reservation: {
        fields: {
          priceValues: {
            merge(existing, incoming) {
              return { ...existing, ...incoming }
            },
          },
        },
      },
      Query: {
        fields: {
          restaurants: {
            keyArgs: ['name', 'reservation_filters', 'region_id', 'tag_ids', 'ids', 'with_reservable_extras', 'sort', 'first'],
            merge: mergeNodesUnique,
          },
          reservableExtras: {
            keyArgs: ['name', 'restaurant_tags', 'regionIds', 'first'],
            merge: mergeNodesUnique,
          },
          festivalEditionRestaurants: {
            keyArgs: ['name', 'reservation_filters', 'region_id', 'tag_ids', 'restaurant_ids', 'first'],
            merge: mergeNodesUnique,
          },
          myReservations: {
            keyArgs: ['type', 'first'],
            merge: mergeNodesUnique,
          },
          menuPositions: {
            keyArgs: ['regionId', 'festivalEditionId', 'index', 'search'],
            merge: mergeNodesMenuUnique,
          },
          slots: {
            read(cached, { variables, cache }) {
              const isOneDay = variables?.dates.startsOn === variables?.dates.endsOn
              const isFER = variables.reservables[0]?.type === 'FestivalEditionRestaurant'
              if (!isFER) return undefined

              if (!cached && isOneDay) {
                const cacheValue = cache.extract()

                // we might have slots for this date fetched from a wider query (i.e. whole month)
                const slots = Object.values(cacheValue).filter(cachedObject => {
                  const isSlot = cachedObject.__typename === 'Slot'
                  if (!isSlot) return false

                  const { date, reservable } = cachedObject as Partial<Slot>
                  const variablesReservable = variables.reservables[0]

                  const isSameDate = date === variables.dates.startsOn
                  const isSameReservable = reservable.__typename === variablesReservable.type && reservable.id === variablesReservable.id
                  return isSameDate && isSameReservable
                })

                // if we don't have this date in the cache (or there are no slots available), let's fetch to double check
                return slots.length ? slots : undefined
              }
              return cached
            },
          },
        },
      },
    },
  }).restore(initialState || {})

  const writeInitialLocalData = () => {
    let selectedRegion = {
      ...DEFAULT_REGION,
      isProposed: true,
      __typename: 'CookieRegion',
    }

    try {
      const cookieRegion = getCookieAsParsedJson(ctx.req, COOKIE.LOCATION)
      const isLegacy = !Object.keys(cookieRegion).includes('isProposed')

      if (cookieRegion) {
        selectedRegion = { ...cookieRegion, __typename: 'CookieRegion' }
      }

      if (isLegacy) {
        selectedRegion.isProposed = false
      }
    } catch {}
    cache.writeQuery({
      query: SelectedRegionDocument,
      data: {
        selectedRegion,
      },
    })
  }

  writeInitialLocalData()

  if (typeof window !== 'undefined') {
    // hydrate client-side cache with SSR data
    cache.restore((window as any).__APOLLO_STATE__)
  }

  const { fetchOptions } = ctx

  const errorLink = onError(({ networkError }) => {
    if (networkError && 'bodyText' in networkError) {
      // Check if error response is JSON
      try {
        JSON.parse(networkError.bodyText)
      } catch (e) {
        // If not replace parsing error message with real one
        // networkError.message = networkError.bodyText
        networkError.message = networkError.bodyText
      }
    }
  })

  return new ApolloClient({
    connectToDevTools: typeof window !== 'undefined',
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      new SentryLink({
        setTransaction: false,
        setFingerprint: true,
        attachBreadcrumbs: {
          includeQuery: true,
          includeVariables: true,
          includeFetchResult: true,
          includeError: true,
          includeContext: ['headers'],
        },
      }),
      errorLink,
      new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            [HEADERS.X_LANGUAGE]: typeof window !== 'undefined' ? router.locale : ctx.req.headers[HEADERS.X_LANGUAGE],
            [HEADERS.ENABLE_DYNAMIC_TRANSLATIONS]: true,
          },
        }))

        return forward(operation)
      }),
      new ApolloLink((operation, forward) => {
        return forward(operation).map((result: any) => {
          const context = operation.getContext()
          const {
            response: { headers },
          } = context
          if (headers) {
            const requestId = headers.get('x-request-id')
            const timestamp = headers.get('x-request-timestamp')

            result.headers = {
              'x-request-id': requestId,
            }
            if (timestamp && result.data?.reservation) {
              result.data.reservation.timestamp = timestamp
            }
          }

          return result
        })
      }),
      new ApolloLinkTimeout(parseInt(process.env.TIMEOUT || '30000', 10)),
      new HttpLink({
        uri: getGraphQLApiUrl(), // Server URL (must be absolute)
        credentials: 'include', // Additional fetch() options like `credentials` or `headers`
        headers: {
          ...(ctx.req && ctx.req.header && { cookie: ctx.req.header('Cookie') }),
        },
        fetchOptions,
      }),
    ]),
    cache,
  })
}

export const createApollo = (initialState, ctx) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    let fetchOptions = {}
    // If you are using a https_proxy, add fetchOptions with 'https-proxy-agent' agent instance
    // 'https-proxy-agent' is required here because it's a sever-side only module
    if (process.env.HTTPS_PROXY && process.env.NEXT_ENV === 'development') {
      const HttpsProxyAgent = require('https-proxy-agent')
      fetchOptions = {
        agent: new HttpsProxyAgent(process.env.HTTPS_PROXY),
      }
    }
    return create(initialState, {
      ...ctx,
      fetchOptions,
    })
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, ctx)
  }

  return apolloClient
}

export default (initialState = null, ctx = {}) => {
  const _apolloClient = apolloClient || createApollo(initialState, ctx)
  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}
