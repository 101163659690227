import Pill from '~/shared/atoms/Pill'
import { ModalProps } from '~/shared/molecules/Modal'

import { PillContainer } from './DailyCardViewShards'

export const loginModalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  closeIconPosition: 'outside' as const,
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed' as const,
  size: 'big' as const,
  iconSize: 'big' as const,
}

export const LoadingPills = () => (
  <PillContainer>{[1, 2, 3, 4, 5]?.map(slot => <Pill key={slot} loading={true} text={slot.toString()} />)}</PillContainer>
)
