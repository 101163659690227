// @ts-strict-ignore
import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsMobile } from '~/theme/utils/grid'

import { TagBar } from './components'
import { Props } from './TagBarTypes'
import { useTags } from './TagBarUtils'

const TagBarView: React.FC<Props> = ({
  restaurant,
  reservableExtra,
  sections = ['hours', 'rating', 'address', 'price'],
  variant = 'plainText',
  maxBaseTagCount = 1,
  ...props
}) => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const tags = useTags({ restaurant, reservableExtra }, maxBaseTagCount)
  const priceTag = tags?.find(tag => typeof tag !== 'string' && tag?.type === 'Price')
  const size = isMobile || variant === 'default' ? 'small' : props?.size

  if (!tags.length) return null

  return <TagBar tags={tags} size={size} variant={variant} priceTag={priceTag} sections={sections} restaurant={restaurant} />
}

export default TagBarView
