import * as yup from 'yup'

import { errors } from '~/constants/errors'
import { gt as GT } from '~/locale'

export const validationMessages = (gt: typeof GT) => ({
  mixed: {
    required: errors.validation.required,
  },
  string: {
    email: errors.validation.email.invalid,
    min: ({ min }: { min: number }): string =>
      gt.tnp(
        'Error',
        'This field must be at least {{ count }} character long',
        'This field must be at least {{ count }} characters long',
        min,
        { count: min }
      ),
    max: ({ max }: { max: number }): string =>
      gt.tnp(
        'Error',
        'This field must be no longer than {{ count }} character',
        'This field must be no longer than {{ count }} characters',
        max,
        { count: max }
      ),
  },
  boolean: {
    isValue: errors.validation.required,
  },
})

yup.setLocale(validationMessages(GT))

export default yup
