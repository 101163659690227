import React from 'react'

import { PlainTextTagBar } from '../TagBarShards'
import { ComponentProps } from '../TagBarTypes'
import { getAddress } from '../TagBarUtils'

import { PriceTagElement, RatingElement } from './TagBar.components.utils'

export function TagBarPlain({ tags, size, variant, priceTag, sections = [], restaurant }: ComponentProps) {
  const tagList = []
  tags.length &&
    tags
      .filter(tag => typeof tag === 'string' || (tag && tag?.type !== 'Price'))
      .forEach((tag, i) => {
        const label = typeof tag === 'string' ? tag : tag?.name
        i === 0 ? tagList.push(label) : tagList.push(` •${'\u00A0' + label}`)
      })

  sections.includes('address') && restaurant && restaurant.address && tagList.push(' • ' + getAddress(restaurant.address, restaurant.zone))
  return (
    <PlainTextTagBar size={size}>
      <RatingElement sections={sections} rating={restaurant?.rating} ratingCount={restaurant?.ratingCount} />
      <PriceTagElement sections={sections} priceTag={priceTag} variant={variant} size={size} />
      {[...tagList]}
    </PlainTextTagBar>
  )
}
