import { Heading6 } from '~/theme/utils/typography'

import { getClubConfig } from '~/config/clubConfig'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'

import { StyledExtraGuestsModal } from './ExtraGuestsModalShards'

interface ExtraGuestsModalProps {
  handleClose: () => void
}

const ExtraGuestsModalView = ({ handleClose }: ExtraGuestsModalProps) => {
  const supportEmail = getClubConfig().supportEmail
  const supportPhone = getClubConfig().supportPhone

  return (
    <StyledExtraGuestsModal>
      <Heading6 as='p' fontWeight='regular'>
        {gt.tp('ExtraGuestsModal', 'To book a table for more people, please contact us')}
        {supportPhone
          ? ':'
          : ` ${gt.tp('ExtraGuestsModal', 'at the address {{ supportEmail }}', {
              supportEmail,
            })}`}
      </Heading6>
      {supportPhone ? (
        <Heading6 as='p' fontWeight='regular'>
          {gt.tp('ExtraGuestsModal', 'You can email {{ supportEmail }} or call the helpline at {{ supportPhone }}.', {
            supportEmail,
            supportPhone,
          })}
        </Heading6>
      ) : null}
      <Button
        label={gt.tp('ExtraGuestsModal', 'Close')}
        color='green'
        width='full'
        height='big'
        fontWeight='regular'
        onClick={handleClose}
      />
    </StyledExtraGuestsModal>
  )
}

export default ExtraGuestsModalView
