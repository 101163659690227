import { gt } from '~/locale'
import { Tab } from '~/shared/molecules/Header/HeaderUtils'
import { Container, Separator } from '~/shared/molecules/Header/TopBarMenu/TopBarMenuShards'
import { TopBarLinkItem } from '~/shared/molecules/Header/TopBarMenu/TopBarMenuView'
import LanguageSwitcher from '~/shared/molecules/LanguageSwitcher'

type FestivalListingHeaderTopBarProps = {
  onClose(): void
  mobileTabs: Tab[]
}

export const FestivalListingMobileMenu = ({ onClose, mobileTabs }: FestivalListingHeaderTopBarProps) => {
  const { tp } = gt

  return (
    <Container isTablet={true}>
      {mobileTabs.map((tab, index) => (
        <TopBarLinkItem
          key={index}
          href={tab.href}
          label={tp(tab.label[0], tab.label[1])}
          onClick={onClose}
          isTablet={true}
          enabledForFeatureFlag={tab.enabledForFeatureFlag?.key}
        />
      ))}
      <Separator />
      <LanguageSwitcher variant='big' onClose={onClose} />
    </Container>
  )
}
