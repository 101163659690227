import { validationFields } from '~/utils/validationFields'
import yup from '~/utils/validationMessages'

import { errors } from '~/constants/errors'

export const newPasswordSchema = yup
  .string()
  .test({
    name: 'no_spaces',
    message: errors.validation.mixed.no_spaces,
    test: value => (value ? !value.match(/\s/g) : true),
  })
  .matches(/^(?=.*[a-z])(?=.*[A-Z]).*$/, errors.validation.mixed.mixed_case)
  .min(8)

export const registerSchema = yup.object().shape({
  firstName: validationFields.firstName,
  lastName: validationFields.lastName,
  phoneNumber: validationFields.phoneNumber,
  email: validationFields.email,
  password: newPasswordSchema.required(),
  regulationConsentAccepted: yup.boolean().oneOf([true]),
})
