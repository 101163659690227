import { useState } from 'react'

import { gt } from '~/locale'

import { ExtendButtonLabel, ExtendLabel, Label } from './CollapsibleLabelShards'

interface CollapsibleLabelProps {
  label: string
  extendedLabel: string
}

const CollapsibleLabelView: React.FC<CollapsibleLabelProps> = ({ label, extendedLabel }) => {
  const [isExtended, setIsExtended] = useState(false)
  const { tp } = gt

  return (
    <div>
      <Label>{label}</Label>
      {isExtended && (
        <ExtendLabel>
          <span dangerouslySetInnerHTML={{ __html: extendedLabel }}></span>
        </ExtendLabel>
      )}
      <ExtendButtonLabel type='button' onClick={() => setIsExtended(!isExtended)}>
        {isExtended ? tp('CollapsibleLabel', 'Hide') : tp('CollapsibleLabel', 'Expand')}
      </ExtendButtonLabel>
    </div>
  )
}

export default CollapsibleLabelView
