import styled from '@emotion/styled'

import { mediaQueryDown, mediaQueryUp } from '~/theme/utils/grid'

export const PageWrapper = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  ${mediaQueryDown('l')} {
    min-height: 100%;
    height: 100%;
  }
`

export type PageContentStyles = { mobileBackground: string }

export const PageContent = styled.main<{ contentStyle?: PageContentStyles }>`
  flex: 1;
  background-color: ${({ theme, contentStyle: { mobileBackground = theme.colors.gray['50'] } = {} }) => mobileBackground};
  overflow-y: auto;
  padding: 32px 16px;

  ${mediaQueryUp('l')} {
    background-color: ${({ theme }) => theme.colors.gray['50']};
    padding: 48px 120px 80px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex: 1;
  overflow-y: hidden;
  max-width: 1500px;
  width: 100%;
  align-self: center;
`

export const ToastContainer = styled.div`
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 20px;

  ${mediaQueryUp('m')} {
    left: 20px;
    top: 100px;
  }
`
