import { Form } from 'formik'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryIsTablet, mediaQueryUp } from '~/theme/utils/grid'
import { Heading3, Heading5 } from '~/theme/utils/typography'

import { StyledCheckboxField } from '~/shared/atoms/Checkbox/CheckboxShards'
import { BasicIcon } from '~/shared/atoms/Icon'

import { StyledFormWrapper, StyledImageWrapper } from '../LoginModal/LoginModalShards'

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.green[500]};
`

export const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  flex-direction: column;
`

export const TopHeading = styled.div<{ fontWeight: string }>``

export const StyledFormLinkContainer = styled.div`
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
`

export const StyledInputs = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 1.5rem;

  & > * {
    width: 100%;
  }

  @media ${mediaQueryIsTablet} and (min-width: 800px), (min-width: 1220px) {
    & > *:first-child,
    & > *:nth-of-type(2) {
      width: calc(50% - 0.5rem);
    }
  }
`

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  ${StyledCheckboxField} {
    padding: 0.5rem 0 0 0.5rem;
  }
`

export const StyledTextWrapper = styled.div`
  padding: 4rem;
  padding-left: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0 0 0 / 40%);
  color: ${({ theme }) => theme.colors.gray[0]};
  width: 100%;

  ${Heading3} {
    margin-bottom: 2rem;
  }

  ${Heading5} {
    display: flex;
    align-items: center;
    margin-bottom: 0.7rem;
  }
`

export const StyledCheckIcon = styled(BasicIcon)`
  margin-right: 0.625rem;
  height: 1rem;
  filter: ${({ theme }) => theme.filters.orange[400]};
`

export const Wrapper = styled.div<{ hasImage?: boolean }>`
  display: flex;
  border: none;
  height: inherit;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  box-shadow: ${({ theme }) => theme.commons.shadow.l};

  ${mediaQueryUp('m')} {
    height: fit-content;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
  }

  ${({ hasImage }) =>
    !hasImage &&
    css`
      max-width: 548px;

      ${StyledFormWrapper} {
        @media (min-width: 950px) {
          width: 100%;
        }
      }

      ${StyledImageWrapper} {
        @media (min-width: 950px) {
          display: none;
        }
      }
    `}
`

export const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.orange[500]};
  &:hover {
    color: ${({ theme }) => theme.colors.orange[600]};
  }
`

export const ConsentNotice = styled.p`
  font-weight: 500;
  font-size: 12px;
  padding: 0 12px;
`
