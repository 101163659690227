// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react'
import AliceCarousel, { EventObject } from 'react-alice-carousel'
import { useMediaQuery } from 'react-responsive'

import 'react-alice-carousel/lib/alice-carousel.css'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Heading2, Heading4 } from '~/theme/utils/typography'
import { mediaQueryIsTablet } from '~/utils/grid'
import { splitToChunks } from '~/utils/splitToChunks'

import { gt } from '~/locale'
import Icon from '~/shared/atoms/Icon'
import Link from '~/shared/atoms/Link'

import {
  Arrow,
  ButtonsContainer,
  Container,
  Heading,
  ItemWrapper,
  LowerBar,
  RedirectLabel,
  SliderContainer,
  TopBar,
} from './UniversalCollectionShards'
import { Props } from './UniversalCollectionTypes'
import { toReservationOffer, toRestaurant } from './UniversalCollectionUtils'

const SliderArrow = ({ left, active, onClick }) => (
  <Arrow onClick={onClick} left={left} active={active}>
    <Icon icon='arrow-right' />
  </Arrow>
)

const responsive = {
  0: { items: 4 },
  270: { items: 4 },
  1280: { items: 4 },
}

const View: React.FC<Props> = ({ restaurants = [], reservationOffers = [], rows = 1, title, showMoreUrl }) => {
  const type = restaurants.length > 0 ? 'restaurant' : 'offer'
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const isDesktop = useMediaQuery({ query: '(min-width: 1280px' })
  const splitToRestaurantItemsRows = (items: JSX.Element[], rowsNumber: number) => splitToChunks(items, rowsNumber, ItemWrapper)
  const [restaurantCardItems, setRestaurantCardItems] = useState(
    splitToRestaurantItemsRows([...toRestaurant(restaurants), ...toReservationOffer(reservationOffers)], rows)
  )
  const carouselRef = useRef(null)
  const [active, setActive] = useState({
    prevButtonEnabled: false,
    nextButtonEnabled: !isDesktop || restaurantCardItems.length > responsive[1280].items,
  })

  const handleOnSlideChange = ({ isPrevSlideDisabled, isNextSlideDisabled, item }: EventObject) => {
    const isLastRowFragment = restaurantCardItems.length - item <= responsive[1280].items

    setActive({
      prevButtonEnabled: !isPrevSlideDisabled,
      nextButtonEnabled: isDesktop ? !isLastRowFragment : !isNextSlideDisabled,
    })
  }

  const Redirect = () => (
    <Link href={showMoreUrl ?? ''}>
      <RedirectLabel>{gt.tp('Main Page', 'See all')}</RedirectLabel>
    </Link>
  )

  useEffect(
    () =>
      setRestaurantCardItems(splitToRestaurantItemsRows([...toRestaurant(restaurants), ...toReservationOffer(reservationOffers)], rows)),
    [restaurants, reservationOffers.length, rows]
  )

  return (
    <Container>
      <TopBar>
        {title && <Heading as={isTablet ? Heading4 : Heading2}>{title}</Heading>}
        <ButtonsContainer>
          {!isMobile && showMoreUrl && <Redirect />}
          <SliderArrow
            left={true}
            onClick={() => {
              active.prevButtonEnabled && carouselRef.current.slidePrev(null)
            }}
            active={active.prevButtonEnabled}
          />
          <SliderArrow
            left={false}
            onClick={() => {
              active.nextButtonEnabled && carouselRef.current.slideNext(null)
            }}
            active={active.nextButtonEnabled}
          />
        </ButtonsContainer>
      </TopBar>
      <SliderContainer type={type}>
        <AliceCarousel
          mouseTracking
          disableButtonsControls
          items={restaurantCardItems}
          responsive={responsive}
          ref={carouselRef}
          disableDotsControls
          onSlideChanged={handleOnSlideChange}
          paddingRight={isMobile ? 0 : -20}
          autoWidth
        />
      </SliderContainer>
      {isMobile && (
        <LowerBar>
          <Redirect />
        </LowerBar>
      )}
    </Container>
  )
}

export default View
