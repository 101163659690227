import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body1 } from '~/theme/utils/typography'

import { CloseIconContainer } from '~/shared/molecules/Modal/ModalShards'

import { Wrapper as BaseWrapper } from '../CardShards'

export const Wrapper = styled(BaseWrapper)`
  padding: 1rem;
  height: fit-content;
  width: 100%;

  ${mediaQueryUp('m')} {
    padding: 2rem;
    border-radius: 1rem;
    max-width: unset;
  }
`

export const ReservationsCount = styled(Body1)`
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: ${({ theme }) => theme.colors.gray[700]};

  ${mediaQueryUp('m')} {
    display: block;
  }
`

export const PillContainer = styled.div`
  grid-area: pills;
  pointer-events: auto;
  display: flex;
  margin-top: 1rem;
  gap: 0.5rem;
  cursor: default;

  ${mediaQueryUp('m')} {
    margin: 2.25rem 0 1.25rem;
  }
`

export const NoSlotsWrapper = styled.div`
  grid-area: pills;
  pointer-events: auto;
  display: flex;
  margin: 0.5rem 0;
  flex-direction: column;
  gap: 0.3rem;

  ${mediaQueryUp('m')} {
    margin: 1rem 0;
    gap: 0.5rem;
  }
`
export const NoSlotsText = styled.span`
  color: ${({ theme }) => theme.colors.gray[900]};
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const NoSlotsBadge = styled.div`
  display: flex;
  height: 2rem;
  padding: 0.375rem 0.75rem;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.orange[200]};
  width: fit-content;
`

export const NoSlotsBadgeText = styled.span`
  color: ${({ theme }) => theme.colors.orange[600]};
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const Arrow = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  margin-bottom: 0.4rem;

  img {
    transform: rotate(180deg);
    filter: ${({ theme }) => theme.filters.orange[600]};
  }
`

export const IconContainer = styled(CloseIconContainer)`
  pointer-events: auto;

  ${mediaQueryUp('l')} {
    display: none;
  }
`
