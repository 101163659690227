// @ts-strict-ignore
import { useEffect, useRef, useState } from 'react'

import ShowOnTrigger from '../ShowOnTrigger'
import { ShowOnTriggerPlacement } from '../ShowOnTrigger/ShowOnTriggerTypes'

import { List } from './DropdownShards'
import { CustomStyles, DropdownItem } from './DropdownTypes'
import { calculateListHeight, elementHeight, menuVerticalOffset, toListElement } from './DropdownUtils'

interface Props {
  items: DropdownItem[]
  placement?: ShowOnTriggerPlacement
  trigger?: 'programmatic' | 'hover'
  show?: boolean
  children: React.ReactNode
  selectedKey: string | number
  onChange(key: string | number): void
  onClickOutside?(): void
  customStyles?: CustomStyles
  scrollTarget?: HTMLElement | null
}

const Dropdown: React.FC<Props> = ({
  items = [],
  trigger,
  children,
  selectedKey,
  show,
  onChange,
  onClickOutside,
  placement = 'down',
  customStyles,
  scrollTarget,
}) => {
  const listRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  useEffect(() => {
    const index = items.findIndex(({ key }) => key === selectedKey)
    setCurrentIndex(index)
  }, [items, selectedKey])

  useEffect(() => {
    if (listRef && show) {
      currentIndex != null && listRef.current && listRef.current.scrollTo({ top: currentIndex * elementHeight, behavior: 'instant' })
    }
  }, [listRef, show])

  const height = calculateListHeight(items.length, elementHeight)
  const styles = { ...customStyles, singleLine: true }

  return (
    <ShowOnTrigger
      placement={placement}
      type={trigger}
      show={true}
      trigger={children}
      verticalOffset={menuVerticalOffset}
      content={
        <List elementHeight={elementHeight} style={{ height }} ref={listRef} show={show} customStyles={styles}>
          {items.map(item => toListElement({ item, isVisible: show, onChange, selectedKey, customStyles: styles }))}
        </List>
      }
      onClickOutside={onClickOutside}
      contentMinWidthAsTrigger
      scrollTarget={scrollTarget}
    />
  )
}

export default Dropdown
