// @ts-strict-ignore
import dayjs from 'dayjs'
import _ from 'lodash'

import { Heading6 } from '~/theme/utils/typography'
import { dayjsToString } from '~/utils/graphqlDataFormatters'

import { NoSlotsWrapper } from '~/components/Restaurant/NoSlotsBoard/NoSlotsBoardShards'
import { AvailableDatesQuery, DateRange, ReservableEnum, ReservableInput, SlotsUsageQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import { Badge } from '~/shared/atoms/Badge'
import { StyledButtonProps } from '~/shared/atoms/Button/ButtonTypes'
import { colors } from '~/theme/colors'

import { SkeletonSlotButton } from './SlotSelectionPanelShards'
import { PossibleSlots, ReservableExtraWithMenus } from './SlotSelectionPanelTypes'

const SlotBadgeTheme = {
  backgroundColor: colors.orange[600],
  textColor: colors.gray[0],
}

export const PrimeTimeBadge = () => <Badge size='small' theme={SlotBadgeTheme} label={gt.tp('Badge', 'Prime Time 🔥')} />
export const LastSpotsBadge = () => <Badge size='small' theme={SlotBadgeTheme} label={gt.tp('Badge', 'Last Spot ⚡')} />
export const YourSpotBadge = () => <Badge size='small' theme={SlotBadgeTheme} label={gt.tp('Badge', 'Your Spot')} />

export const DiscountBadge = ({ discountValue }: { discountValue: number }) => (
  <Badge size='small' color='red' label={`-${discountValue}%`} />
)

interface SkeletonSlotListProps {
  listSize: number
}

export const SkeletonSlotlist = ({ listSize }: SkeletonSlotListProps) => {
  const list = new Array(listSize)
    .fill(0)
    .map((_, i) => <SkeletonSlotButton key={i} label='' href='' loading disabled height='small' width='full' />)
  return <>{list}</>
}

export const NoSlotsTodayBoard = () => (
  <NoSlotsWrapper>
    <Heading6 fontWeight='medium'>{gt.tp('NoSlots', 'No tables available - try other dates!')}</Heading6>
  </NoSlotsWrapper>
)

export const actionButtonProps: StyledButtonProps = {
  width: 'full',
  height: 'small',
  color: 'secondary',
}

export const DEFAULT_LIST_SIZE = 5

export const getListSize = (showAllSlots: boolean, possibleSlots: number[]) => {
  return showAllSlots ? possibleSlots.length : DEFAULT_LIST_SIZE
}

export const areAnySlotsAvailable = slots => {
  return slots?.some(({ sizes }) => sizes.length > 0)
}

export const getOneMonthDateRange = (date: dayjs.Dayjs): DateRange => {
  const now = dayjs()
  const startOfSelectedMonth = date.clone().startOf('month')
  const isTodayBeforeSelectedDateMonthStart = now.clone().isBefore(startOfSelectedMonth)

  return {
    startsOn: dayjsToString(isTodayBeforeSelectedDateMonthStart ? startOfSelectedMonth : now),
    endsOn: dayjsToString(date.clone().endOf('month')),
  }
}

export const getDayUsageVariables = (date: string, reservableInput?: ReservableInput, withReservableExtra?: boolean) => ({
  dates: { startsOn: date, endsOn: date },
  reservables: [reservableInput],
  withReservableExtra,
})

export const getMonthUsageVariables = (dates: DateRange, reservableInput: ReservableInput, withReservableExtra?: boolean) => ({
  dates,
  reservables: [reservableInput],
  withReservableExtra,
})

export const getNewMonth = (date, clickType) => {
  switch (clickType) {
    case 'prev': {
      return date.clone().subtract(1, 'month')
    }
    case 'next': {
      return date.clone().add(1, 'month')
    }
    default: {
      return date.clone()
    }
  }
}

export const getAvailableDates = (slots: AvailableDatesQuery['slots']): dayjs.Dayjs[] => {
  if (slots && Array.isArray(slots)) {
    const allDates = Array.from(new Set(slots.filter(({ sizes }) => sizes.length > 0).map(({ date }) => date)))

    if (_.isEmpty(allDates)) {
      return []
    }
    return allDates.map((date: string) => dayjs.parseZone(date, 'YYYY-MM-DD'))
  }
  return []
}

export const getCurrentDaySlots = (slots: SlotsUsageQuery['slots'], currentDay: string) => {
  const dayStart = slots.findIndex(({ date }) => date === currentDay)
  const nextDayStart = slots.findIndex((slot, i) => i >= dayStart && slot.date !== currentDay)
  if (nextDayStart < 0) {
    return slots.slice(dayStart)
  }
  return slots.slice(dayStart, nextDayStart)
}

export const createMockSlot = (slot: number, date: string) => ({
  slot,
  sizes: [] as number[],
  runningOut: false,
  discount: 0,
  date,
  primeTimeFee: 0,
})

export const addFringeSlots = (possibleSlots: PossibleSlots, interval: number, count: number = 9): PossibleSlots => {
  let slotInterval = interval
  if (!interval || possibleSlots.length > 1) {
    slotInterval = possibleSlots[1] - possibleSlots[0]
  }
  const following = Array.from(Array(Math.floor(count / 2)))
    .map((_, i) => possibleSlots[possibleSlots.length - 1] + slotInterval * (i + 1))
    .filter(slot => slot < 24 * 60)

  return [...possibleSlots, ...following]
}

export const mapSlotsUsage = (slotsUsage: SlotsUsageQuery['slots'], searchedDate: string, slotlist: PossibleSlots) => {
  if (slotsUsage) {
    const currentDaySlots = getCurrentDaySlots(slotsUsage, searchedDate)

    return slotlist.map(
      possibleSlot => currentDaySlots.find(({ slot }) => slot === possibleSlot) ?? createMockSlot(possibleSlot, searchedDate)
    )
  }
}

export const getCurrentListFragment = <T extends { slot: number }>(firstSlot: number, slotlistWithUsage: T[], listSize: number): T[] => {
  const firstSlotIndex = slotlistWithUsage.findIndex(({ slot }) => slot === firstSlot)
  return slotlistWithUsage.slice(firstSlotIndex, firstSlotIndex + listSize)
}

export const getDefaultFestivalMenuCounts = (menus: unknown[]) => {
  return menus ? Array.from({ length: menus.length }, () => 0) : []
}

export const getDefaultReservableExtraMenuCounts = (peopleCount: number, reservableExtra: ReservableExtraWithMenus) => {
  return [
    {
      menuId: reservableExtra.menus[0].id,
      quantity: peopleCount,
      reservableExtraId: reservableExtra.id,
    },
  ]
}

export const responseKey = (reservableType: ReservableEnum) => {
  switch (reservableType) {
    case ReservableEnum.Daily:
      return 'createDailyReservation'
    case ReservableEnum.Event:
      return 'createEventReservation'
    default:
      return 'createFestivalReservation'
  }
}
