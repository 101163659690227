import { css } from '@emotion/react'

import { colors } from '~/theme/colors'

export const bottomSheetStyles = css`
  // overwrite with !important, lib applies these variables as inline styles :<
  [data-rsbs-root] {
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.5);
    --rsbs-bg: #fff;
    --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-overlay-rounded: 0;
  }

  [data-rsbs-overlay] {
    border-top-left-radius: 16px;
    border-top-left-radius: var(--rsbs-overlay-rounded, 16px);
    border-top-right-radius: 16px;
    border-top-right-radius: var(--rsbs-overlay-rounded, 16px);
    display: flex;
    background: #fff;
    background: var(--rsbs-bg, #fff);
    flex-direction: column;
    height: 0px;
    height: var(--rsbs-overlay-h, 0px);
    transform: translate3d(0, 0px, 0);
    transform: translate3d(0, var(--rsbs-overlay-translate-y, 0px), 0);
    will-change: height;
  }

  [data-rsbs-overlay]:focus {
    outline: none;
  }

  [data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
    box-shadow:
      0 -5px 60px 0 rgba(38, 89, 115, 0.11),
      0 -1px 0 rgba(38, 89, 115, 0.05);
  }

  [data-rsbs-overlay],
  [data-rsbs-root]:after {
    max-width: auto;
    max-width: var(--rsbs-max-w, auto);
    margin-left: env(safe-area-inset-left);
    margin-left: var(--rsbs-ml, env(safe-area-inset-left));
    margin-right: env(safe-area-inset-right);
    margin-right: var(--rsbs-mr, env(safe-area-inset-right));
  }

  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: 3;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    touch-action: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  [data-rsbs-backdrop] {
    top: -60px;
    bottom: -60px;
    background-color: rgba(0, 0, 0, 0.6);
    background-color: var(--rsbs-backdrop-bg, rgba(0, 0, 0, 0.6));
    will-change: opacity;
    cursor: pointer;
    opacity: 1;
  }

  [data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
    cursor: ns-resize;
  }

  [data-rsbs-root]:after {
    content: '';
    pointer-events: none;
    background: #fff;
    background: var(--rsbs-bg, #fff);
    height: 1px;
    transform-origin: bottom;
    transform: scale3d(1, 0, 1);
    transform: scale3d(1, var(--rsbs-antigap-scale-y, 0), 1);
    will-change: transform;
  }

  [data-rsbs-footer],
  [data-rsbs-header] {
    flex-shrink: 0;
    cursor: ns-resize;
    padding: 16px;
  }

  [data-rsbs-header] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    padding-top: calc(20px + env(safe-area-inset-top));
    padding-bottom: 0;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: ${colors.gray[300]};
      margin-top: 16px;
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    [data-rsbs-header]:before {
      transform: translateX(-50%) scaleY(0.75);
    }
  }

  [data-rsbs-has-header='false'] [data-rsbs-header] {
    box-shadow: none;
    padding-top: calc(12px + env(safe-area-inset-top));
  }

  [data-rsbs-scroll] {
    flex-shrink: 1;
    flex-grow: 1;
    -webkit-tap-highlight-color: revert;
    -webkit-touch-callout: revert;
    -webkit-user-select: auto;
    -ms-user-select: auto;
    -moz-user-select: auto;
    user-select: auto;
    overflow: auto;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
  }

  [data-rsbs-scroll]:focus {
    outline: none;
  }

  [data-rsbs-has-footer='false'] [data-rsbs-content] {
    padding: 0 16px calc(48px + env(safe-area-inset-bottom));
  }

  [data-rsbs-content] {
    /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
    overflow: hidden;
  }

  [data-rsbs-footer] {
    box-shadow:
      0 -1px 0 rgba(46, 59, 66, calc(1 * 0.125)),
      0 2px 0 #fff;
    box-shadow:
      0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity, 1) * 0.125)),
      0 2px 0 var(--rsbs-bg, #fff);
    overflow: hidden;
    z-index: 1;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
  }

  [data-rsbs-is-dismissable='true'] [data-rsbs-header] > *,
  [data-rsbs-is-dismissable='true'] [data-rsbs-scroll] > *,
  [data-rsbs-is-dismissable='true'] [data-rsbs-footer] > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-header] > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-header] > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-scroll] > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-scroll] > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-footer] > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-footer] > * {
    opacity: 1;
    opacity: var(--rsbs-content-opacity, 1);
  }

  [data-rsbs-is-dismissable='true'] [data-rsbs-backdrop],
  [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-backdrop],
  [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-backdrop] {
    opacity: 1;
    opacity: var(--rsbs-backdrop-opacity, 1);
  }

  [data-rsbs-state='closed'],
  [data-rsbs-state='closing'] {
    /* Allows interactions on the rest of the page before the close transition is finished */
    pointer-events: none;
  }
`
