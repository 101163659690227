import { createContext, useContext } from 'react'

import { GetServerSideProps } from 'next'

import { getUserSSR } from '~/utils/getUserSSR'
import { globalBoostrapPropsWrapper, globalBootstrap } from '~/utils/globalBootstrap'
import { isDaily, isFER } from '~/utils/isReservableType'

import { MinimumReservableExtraAsignments } from '~/components/Checkout/CheckoutPage/CheckoutMenuSelect/ChefsMenuSelect/CheckoutChefsMenuSelectForm.utils'
import {
  CheckoutFlow,
  CheckoutReservation,
  CheckoutVoucher,
  CommonCheckoutPagesProps,
  MinimumReservable,
} from '~/components/Checkout/CheckoutPage/CheckoutPageTypes'
import { getCheckoutSecondStep } from '~/components/Checkout/CheckoutPage/CheckoutPageUtils'
import { paths } from '~/constants'
import {
  Daily,
  Event,
  FestivalEditionRestaurant,
  ReservationSummaryDocument,
  ReservationSummaryQuery,
  SuccessPageQuery,
  SuccessPageVoucherQuery,
  UpdateDailyReservationMutationFn,
  UpdateDailyReservationMutationResult,
  UpdateDailyReservationMutationVariables,
  UpdateEventReservationMutationFn,
  UpdateEventReservationMutationResult,
  UpdateFestivalReservationMutationFn,
  UpdateFestivalReservationMutationResult,
  useReservationSummaryQuery,
  useVoucherDataQuery,
  Voucher,
  VoucherDataQuery,
} from '~/generated/graphql'
import { gt } from '~/locale'

export const getCheckoutServerSideProps: GetServerSideProps<CommonCheckoutPagesProps> = async ctx => {
  const { apolloClient } = await globalBootstrap({ ctx })

  const { code } = ctx.query
  const previousRoute = ctx.req.headers?.referer || null
  const { user } = await getUserSSR({ apolloClient })

  const {
    data: { reservation },
    errors,
  } = await apolloClient.query<ReservationSummaryQuery>({
    query: ReservationSummaryDocument,
    variables: { code },
    errorPolicy: 'all',
  })

  if (!user && errors?.find(err => err.message === 'Not authorized to access Query.reservation')) {
    return {
      redirect: {
        destination: `/user?redirectTo=${ctx.resolvedUrl}`,
        permanent: false,
      },
    }
  }
  if (!reservation || reservation?.user?.id !== user?.id) {
    return {
      notFound: true,
    }
  }

  return globalBoostrapPropsWrapper(
    { apolloClient },
    {
      props: {
        reservation,
        previousRoute,
      },
      // Should be this when 401 will be changed
      // ...(!reservation && { redirect: { destination: paths.error401(ctx.req?.url), permanent: true } }),
    }
  )
}

export type SpecifiedReservation<ReservableType extends { __typename?: string }> = CheckoutReservation & {
  reservable: Pick<ReservableType, '__typename'>
}

export type DailyReservation = SpecifiedReservation<Daily>
export type FestivalReservation = SpecifiedReservation<FestivalEditionRestaurant>
export type DailyOrFestivalReservation = DailyReservation | FestivalReservation
export type EventReservation = SpecifiedReservation<Event>
export type VoucherReservation = SpecifiedReservation<Voucher>
export type SuccessPageReservation = NonNullable<SuccessPageQuery['reservation']>
export type SuccessVoucherPageReservation = NonNullable<SuccessPageVoucherQuery['voucher']>
export const isVoucher = <ReservationPartial extends Pick<CheckoutReservation | CheckoutVoucher, '__typename'>>(
  reservation: ReservationPartial | CheckoutVoucher
): reservation is CheckoutVoucher => {
  return reservation.__typename === 'Voucher'
}

export type CheckoutCrossSell = FestivalReservation['reservable']['crossSells'][number]

export const getInitialCheckoutPath = ({
  reservation,
  isCHMReservation,
  editMode,
}: {
  reservation: Pick<CheckoutReservation, 'code' | 'reservableExtrasAvailable'> & {
    reservable?: MinimumReservable | null
  }
  isCHMReservation?: boolean
  editMode?: boolean
}) => {
  const reservable = reservation.reservable
  if (reservable && isFER(reservable)) return paths.checkoutFestivalMenu(reservation.code, editMode)

  if (reservation.reservableExtrasAvailable) {
    return paths.checkoutChefsMenu(reservation.code, isCHMReservation ? CheckoutFlow.CHM : CheckoutFlow.D2D, editMode)
  }

  if (!reservable) {
    return paths.checkoutFestivalMenu(reservation.code, editMode)
  }

  return getCheckoutSecondStep({
    reservable,
    code: reservation.code,
    editMode,
    flow: isCHMReservation ? CheckoutFlow.CHM : CheckoutFlow.D2D,
    isCHM: isDaily(reservable),
  })
}

export const getCHMCount = (reservableExtras: UpdateDailyReservationMutationVariables['reservableExtraAttrs']) => {
  if (!reservableExtras) return 0
  if (Array.isArray(reservableExtras)) {
    return reservableExtras.reduce((acc, reservableExtra) => {
      return acc + reservableExtra.quantity
    }, 0)
  }

  return 0
}

export const compareReservableExtraAssignmentsQuantities = ({
  previousReservation,
  reservableExtraAssignments,
}: {
  previousReservation: DailyReservation['previousReservation']
  reservableExtraAssignments?: MinimumReservableExtraAsignments | null
}) => {
  const sumQuantities = (
    assignments: Array<Pick<NonNullable<DailyReservation['reservableExtraAssignments']>[number], 'quantity' | 'reservableExtraId'>>
  ) =>
    assignments?.reduce((map: Record<string, number>, { reservableExtraId, quantity }) => {
      map[reservableExtraId] = (map[reservableExtraId] || 0) + quantity
      return map
    }, {})

  const previousReservableExtraAssignments = previousReservation?.reservableExtraAssignments

  // If there were no assingments adding some is permitted
  if (!previousReservableExtraAssignments) return true

  // All assingments deleted, but there were some in the previous reservation
  if (!reservableExtraAssignments) return false

  const currentSums = sumQuantities(reservableExtraAssignments)
  const previousSums = sumQuantities(previousReservableExtraAssignments)

  // Compare quantities of each OLD reservableExtra assignment with the new once
  // This is to check if they are the same (or if there are more) as in the previous reservation
  const ids = Object.keys(previousSums)
  return ids.every(id => currentSums[id] >= previousSums[id])
}

export const getSubmitButtonLabel = ({
  reservationPeopleCount,
  menusCount,
  isCHMSelectionRequired,
  isCheckoutButtonAlwaysActiveEnabled,
}: {
  menusCount: number
  reservationPeopleCount: number
  isCHMSelectionRequired: boolean
  isCheckoutButtonAlwaysActiveEnabled: boolean
}) => {
  if (isCheckoutButtonAlwaysActiveEnabled) return gt.tp('CheckoutPage', 'Next')
  if (!isCHMSelectionRequired) return gt.tp('CheckoutPage', 'Add to reservation')

  if (menusCount === reservationPeopleCount) return gt.tp('CheckoutPage', 'Next')

  return `${gt.tp('CheckoutPage', 'Select menu to continue')} (${menusCount}/${reservationPeopleCount})`
}
export { CheckoutFlow }

type CheckoutContextType = {
  updateReservation?: UpdateDailyReservationMutationFn | UpdateFestivalReservationMutationFn | UpdateEventReservationMutationFn
  isUpdateCalled?: boolean
  code: string
  flow?: CheckoutFlow
  isVoucherReservation?: boolean
  isEdit?: boolean
} & (
  | Partial<Omit<UpdateDailyReservationMutationResult, 'called'>>
  | Partial<Omit<UpdateFestivalReservationMutationResult, 'called'>>
  | Partial<Omit<UpdateEventReservationMutationResult, 'called'>>
)

type UpdateReservationFunction<T> = T extends DailyReservation
  ? UpdateDailyReservationMutationFn
  : T extends FestivalReservation
  ? UpdateFestivalReservationMutationFn
  : T extends EventReservation
  ? UpdateEventReservationMutationFn
  : UpdateDailyReservationMutationFn

export const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined)

export const useCheckoutContext = <
  ReservationType extends DailyOrFestivalReservation | EventReservation | NonNullable<VoucherDataQuery['voucher']> =
    | DailyOrFestivalReservation
    | EventReservation
    | NonNullable<VoucherDataQuery['voucher']>,
>() => {
  const context = useContext(CheckoutContext)
  if (!context) {
    throw new Error('useCheckoutContext must be used within a CheckoutContext.Provider')
  }

  const { data: reservationData } = useReservationSummaryQuery({
    variables: { code: context.code || '' },
    skip: context.isVoucherReservation,
    nextFetchPolicy: 'cache-first',
  })
  const { data: voucherData } = useVoucherDataQuery({ variables: { code: context.code }, skip: !context.isVoucherReservation })
  const reservation = context.isVoucherReservation ? voucherData?.voucher : reservationData?.reservation

  return {
    ...context,
    reservation: reservation as ReservationType,
    updateReservation: context.updateReservation as UpdateReservationFunction<ReservationType>,
  }
}

export const CHECKOUT_DEBOUNCE_TIME = 600
