import { Form, Formik } from 'formik'

import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { getClubConfig } from '~/config/clubConfig'
import { useNewsletterSignUpMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import CollapsibleLabel from '~/shared/atoms/CollapsibleLabel/CollapsibleLabelView'
import Input from '~/shared/atoms/Input'
import { useToast } from '~/shared/atoms/Toast'
import {
  Address,
  AddressInfo,
  Benefit,
  BenefitIcon,
  Benefits,
  BenefitsTitle,
  Consent,
  JoinButton,
  JoinHeading,
  JoinInputContainer,
  JoinMobileHeading,
  JoinTheClubWrapper,
  ToastContainer,
} from '~/shared/molecules/Footer/JoinTheClub/JoinTheClubShards'
import { benefitsData, joinTheClubSchema } from '~/shared/molecules/Footer/JoinTheClub/JoinTheClubUtils'

type JoinTheClubProps = {
  stretchContainer?: boolean
}

const JoinTheClub = ({ stretchContainer = false }: JoinTheClubProps) => {
  const clubConfig = getClubConfig()
  const toast = useToast()
  const [signUp] = useNewsletterSignUpMutation()

  const { tp } = gt
  const { addressLines } = clubConfig.copy.footerInfo

  const handleSignUp = (email: string, resetForm: () => void, setSubmitting: (isSubmitting: boolean) => void) => {
    signUp({
      variables: { email: email },
      onCompleted: () => {
        toast.add({
          type: 'success',
          message: gt.tp('ConfirmationMessage', 'Email sent!'),
          containerSelector: '#FooterToastsContainer',
          size: 'regular',
        })
        resetForm()
        setSubmitting(false)
      },
      onError: err =>
        formatErrorMessages(err)?.map(error => {
          toast.add({
            message: error,
            type: 'error',
            containerSelector: '#FooterToastsContainer',
            size: 'regular',
          })
          return setSubmitting(false)
        }),
    })
  }

  return (
    <JoinTheClubWrapper stretchContainer={stretchContainer}>
      <JoinHeading>{clubConfig.hideRCInfo ? tp('Footer', 'SIGN UP FOR OUR NEWSLETTER') : tp('Footer', 'Join the Club!')}</JoinHeading>
      <JoinMobileHeading fontWeight='medium'>{tp('Footer', 'Get exclusive discounts and access to exceptional gifts')}</JoinMobileHeading>
      <ToastContainer id='FooterToastsContainer' />
      <Formik
        initialValues={{ email: '' }}
        validationSchema={joinTheClubSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          handleSignUp(values.email, resetForm, setSubmitting)
        }}>
        {({ values, errors, handleSubmit, isSubmitting, handleChange }) => (
          <Form>
            <JoinInputContainer>
              <Input
                error={!!errors.email}
                errorMessage={errors.email}
                onChange={handleChange}
                name='email'
                width='full'
                height='normal'
                value={values.email}
                placeholder={tp('Footer', 'Your email address...')}
              />
              <JoinButton
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                width={'full'}
                color='orange'
                label={tp('Footer', 'Join')}
              />
            </JoinInputContainer>
            <Consent stretchContainer={stretchContainer}>
              <CollapsibleLabel
                label={clubConfig.copy.marketingConsent(gt).footer.label}
                extendedLabel={clubConfig.copy.marketingConsent(gt).footer.expandedText}
              />
              {clubConfig.hideRCInfo && addressLines ? (
                <Address>
                  {addressLines.map(addressLine => (
                    <AddressInfo key={addressLine}>{addressLine}</AddressInfo>
                  ))}
                </Address>
              ) : (
                <Benefits>
                  <BenefitsTitle>{tp('Footer', 'You will gain:')}</BenefitsTitle>
                  {benefitsData.map(text => (
                    <Benefit key={text}>
                      <BenefitIcon icon='check' />
                      {tp('Footer', text)}
                    </Benefit>
                  ))}
                </Benefits>
              )}
            </Consent>
          </Form>
        )}
      </Formik>
    </JoinTheClubWrapper>
  )
}

export default JoinTheClub
