import { FestivalCodesEnum } from '~/generated/graphql'
import { gt as GT } from '~/locale'

import { ClubConfigCopy } from '../../types'

import { earlyReservation, voucherTranslation } from './pageTranslate'

const rwReservationStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your reservation...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Booking confirmed!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Reservation cancelled'),
})

const giftStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your purchase...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Congrats! Your gift purchase is complete!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Gift purchase cancelled'),
})

const beforeYouVisitTitle = (gt: typeof GT) => gt.tp('SuccessPage', 'Read before you visit')

const beforeYouVisitDefaultDescription = (gt: typeof GT) =>
  gt.tp(
    'SuccessPage',
    // eslint-disable-next-line max-len
    'Be eco! Don\'t print the confirmation - at the restaurant, the confirmation on your phone will be enough. Please be on time :). Punctuality gives everyone an equal opportunity to enjoy their dream restaurant experience. After the visit, log in to your account, select "Your reservations" and "Review". The restaurant counts on your honest opinion!'
  )

export const marketingConsent = (gt: typeof GT) => ({
  footer: {
    label: gt.tp(
      'Footer',
      'By pressing „Join” you accept collecting and benefiting from DISCOUNT CODES, be notified of Festivals, obtain eBook “RESTAURANTS’ RECIPES” (price PLN 78), therefore I consent to the sending of the commercial information'
    ),
    expandedText: gt.tp(
      'Footer',
      'to me by Restaurant Club sp. z o. o. to the contact details I provided and the use of these data for direct marketing purposes via electronic terminal devices (e-mail, online social networking sites, phone number provided by me - SMS, telephone contact).'
    ),
  },
  form: {
    label: gt.tp(
      'RegisterForm',
      'I want to: collect and benefit from DISCOUNT CODES, be notified of Festivals, obtain eBook “RESTAURANTS’ RECIPES” (price PLN 78), therefore I consent to the sending of the commercial information'
    ),
    expandedText: gt.tp(
      'RegisterForm',
      'to me by Restaurant Club sp. z o. o. to the contact details I provided and the use of these data for direct marketing purposes via electronic terminal devices (e-mail, online social networking sites, phone number provided by me - SMS, telephone contact).'
    ),
  },
})

export const getMetadata = (gt: typeof GT) => ({
  default: {
    title: gt.tp('RestaurantWeekCZ', 'RestaurantWeek Germany | The biggest restaurant festival in the world'),
    description: gt.tp(
      'Head',
      "Book top restaurants at a special price. Discover the show-stopping menus of Chefs and the world's most popular Restaurant Festival - RestaurantWeek®."
    ),
    imageUrl: 'https://restaurant-week.de/assets/rc_share.png',
    imageAlt: 'rc_share',
    siteName: gt.tp('Head', 'restaurant-week.de'),
    url: 'https://restaurant-week.de',
  },
  festivals: {
    [FestivalCodesEnum.Rw]: {
      title: gt.tp('RestaurantWeekDE', 'RestaurantWeek Germany | The biggest restaurant festival in the world'),
      description: 'Restaurant Week',
      imageUrl: 'https://restaurant-week.de/assets/rc_share.png',
      imageAlt: 'rc_share',
    },
  },
})

export const copy: ClubConfigCopy = {
  voucher: voucherTranslation,
  rwReservationStatusMessages,
  giftStatusMessages,
  beforeYouVisitTitle,
  beforeYouVisitDefaultDescription,
  marketingConsent,
  metadata: getMetadata,
  earlyReservation,
  footerInfo: {},
}
