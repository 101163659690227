import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Body2, Heading5 } from '~/theme/utils/typography'

import { gt } from '~/locale'
import Dropdown from '~/shared/atoms/Dropdown'
import Icon from '~/shared/atoms/Icon'
import Tag from '~/shared/atoms/Tag'

import { DropdownSelect, Item, StyledTagBar } from '../TagBarShards'
import { ComponentProps, Props } from '../TagBarTypes'
import { getAddress, useOpeningTime } from '../TagBarUtils'

import { PriceTagElement, RatingElement } from './TagBar.components.utils'

const OpeningTimeDropdown: React.FC<Pick<Props, 'restaurant'>> = ({ restaurant }) => {
  const [show, setShow] = useState(false)
  const { currentTitle, items } = useOpeningTime(restaurant, gt)
  const currentDayIndex = parseInt(restaurant?.openingHoursToday?.day || '', 10) - 1
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const DropdownTitle = isMobile ? Body2 : Heading5

  return (
    <Dropdown
      trigger='programmatic'
      show={show}
      selectedKey={currentDayIndex}
      onChange={() => null}
      onClickOutside={() => setShow(false)}
      items={items}>
      <DropdownSelect active={show} onClick={() => setShow(v => !v)}>
        <DropdownTitle>{currentTitle}</DropdownTitle>
        <Icon icon='arrow' />
      </DropdownSelect>
    </Dropdown>
  )
}
export function TagBarDefault({ tags, size, variant, priceTag, sections = [], restaurant }: ComponentProps) {
  return (
    <StyledTagBar size={size} variant={variant}>
      <RatingElement sections={sections} rating={restaurant?.rating} ratingCount={restaurant?.ratingCount} />
      <PriceTagElement sections={sections} priceTag={priceTag} variant={variant} size={size} />
      {tags.length &&
        tags
          .filter(tag => typeof tag === 'string' || tag?.type !== 'Price')
          .map(tag => {
            const label = typeof tag === 'string' ? tag : tag?.name
            const key = typeof tag === 'string' ? tag : tag?.id
            return (
              <Item key={key}>
                <Tag size={size} label={label} variant={variant} />
              </Item>
            )
          })}
      {sections.includes('address') && restaurant && restaurant.address && (
        <Item>
          <Tag size={size} label={getAddress(restaurant.address, restaurant.zone)} variant={variant} />
        </Item>
      )}
      {sections.includes('hours') && restaurant && (
        <Item>
          <OpeningTimeDropdown restaurant={restaurant} />
        </Item>
      )}
    </StyledTagBar>
  )
}
