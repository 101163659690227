import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { Banner as BannerAtom, Container as ContainerAtom } from '~/components/Festival/Banners/BannerShards'
import { imageUrl } from '~/components/Festival/Banners/BannerTypes'

export const Banner = styled(BannerAtom)<{ color?: string; image?: imageUrl }>`
  width: 100%;
  height: 9rem;
  border-radius: 0.5625rem;

  ${({ image }) =>
    image &&
    css`
      background: url('${image.mobile}') no-repeat 0 0 / contain;
    `}
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}


  ${mediaQueryUp('m')} {
    height: 7.5rem;
    ${({ image }) =>
      image &&
      css`
        background-image: url('${image.desktop}');
      `}
  }
`

export const Container = styled(ContainerAtom)`
  margin-left: auto;
  margin-right: 0.5rem;

  width: 100%;
  max-width: 150px;

  ${mediaQueryUp('s')} {
    max-width: 200px;
  }

  ${mediaQueryUp('m')} {
    width: 100%;
    max-width: 100%;
    margin-left: 15rem;
    margin-right: 1rem;
    flex-direction: row;
  }
`
