import { Styles } from '~/components/Festival/Banners/BannerTypes'
import theme from '~/theme'

export const defaultStyle: Styles = {
  bgColor: theme.colors.orange[50],
  titleFontColor: theme.colors.gray[1000],
  buttonColor: 'orange',
  fontColor: theme.colors.gray[800],
  dateColor: theme.colors.gray[800],
  imageUrl: {
    desktop: '/assets/festival-menu-banner.png',
    mobile: '/assets/festival-menu-banner-mobile.png',
  },
}
