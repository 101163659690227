import { Styles } from '~/components/Festival/Banners/BannerTypes'

export const buildMenusRoute = (festivalEditionId: number, location: string) => {
  return `/menus?festivalFilter=${festivalEditionId}&location=${location}`
}

export const fdwStyle: Styles = {
  bgColor: '#241E49',
  buttonColor: 'fdw',
  titleFontColor: '#FFFFFF',
  fontColor: '#FFFFFF',
  dateColor: '#FFFFFF',
  imageUrl: {
    desktop: '/assets/festival-menu-banner-fdw.png',
    mobile: '/assets/festival-menu-banner-fdw-mobile.png',
  },
}
