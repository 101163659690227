import { EventsListingRoutes, GiftEventsListingBanners } from '~/config/clubConfig/types'
import { FestivalSalesOffer } from '~/types/festival'

import { VoucherImagesFunction } from '.'

const SideEventsListing = {
  mainBanner: {
    desktop: 'side-events-listing/banner.png',
    mobile: 'side-events-listing/banner-mobile.png',
  },
}

export const getVoucherEventsListingBanners: VoucherImagesFunction<GiftEventsListingBanners> = festivalSalesOffer => {
  switch (festivalSalesOffer) {
    case FestivalSalesOffer.BLACK_FRIDAY:
      return {
        [EventsListingRoutes.GiftEventsListing]: {
          mainBanner: {
            desktop: 'gift-events-listing/BW24-banner-desktop.png',
            mobile: 'gift-events-listing/BW24-banner-mobile.png',
          },
          bottomBanner: {
            desktop: 'gift-events-listing/BF24-banner-comparison-desktop.png',
            mobile: 'gift-events-listing/BF24-banner-comparison-mobile.png',
          },
        },
        [EventsListingRoutes.SideEventsListing]: SideEventsListing,
      }
    case FestivalSalesOffer.XMAS:
      return {
        [EventsListingRoutes.GiftEventsListing]: {
          mainBanner: {
            desktop: 'gift-events-listing/XMAS24-banner-desktop.png',
            mobile: 'gift-events-listing/XMAS24-banner-mobile.png',
          },
          bottomBanner: {
            desktop: 'gift-events-listing/XMAS24-banner-comparison-desktop.png',
            mobile: 'gift-events-listing/XMAS24-banner-comparison-mobile.png',
          },
        },
        [EventsListingRoutes.SideEventsListing]: SideEventsListing,
      }
    case FestivalSalesOffer.GIFT:
    default:
      return {
        [EventsListingRoutes.GiftEventsListing]: {
          mainBanner: {
            desktop: 'gift-events-listing/GIFT25-banner-desktop.png',
            mobile: 'gift-events-listing/GIFT25-banner-mobile.png',
          },
          bottomBanner: {
            desktop: 'gift-events-listing/GIFT25-banner-comparison-desktop.png',
            mobile: 'gift-events-listing/GIFT25-banner-comparison-mobile.png',
          },
        },
        [EventsListingRoutes.SideEventsListing]: SideEventsListing,
      }
  }
}
