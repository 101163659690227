import { CSSObjectWithLabel } from 'react-select'

import { selectStyles as defaultStyles } from '../SelectionDropdown/SelectionDropdownShards'

export const selectStyles = {
  ...defaultStyles,
  container: (base: CSSObjectWithLabel) => ({
    ...base,
    width: 'fit-content',
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    width: '300px',
    zIndex: 30,
    boxShadow: 'none',
    border: 0,
    marginTop: 0,
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
  }),
}
