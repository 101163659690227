import React from 'react'

import SlotSelectionSkeleton from '~/components/Reservation/SlotSelectionPanel/SlotSelectionSkeleton'

import { Details, Grid } from '../../FERModalShards'

import { SkeletonContent, SkeletonImage, SkeletonImageContainer, SkeletonProfile, SkeletonWrapper } from './SkeletonShards'

export function FERModalSkeleton({ isTablet }: { isTablet: boolean }) {
  return (
    <SkeletonWrapper>
      <Grid>
        <SkeletonProfile>
          <SkeletonImageContainer>
            <SkeletonImage />
          </SkeletonImageContainer>
          <Details>
            <SkeletonContent margin='1rem 0 1rem' height='25px' />
            <SkeletonContent height='30px' />
            <SkeletonContent margin='1rem 0 1.5rem' height='24px' />
            {isTablet && <SkeletonContent height='44px' />}
          </Details>
        </SkeletonProfile>

        {!isTablet ? <SlotSelectionSkeleton /> : null}
      </Grid>
    </SkeletonWrapper>
  )
}
