import styled from '@emotion/styled'

import { Heading6, lineClamp } from '~/theme/utils/typography'

export const DescriptionText = styled(Heading6)<{ expandText: boolean; lineClampNumber?: number }>`
  color: ${({ theme }) => theme.colors.gray[700]};
  ${({ expandText, lineClampNumber = 3 }) => !expandText && lineClamp(lineClampNumber)}
  white-space: pre-wrap;
  word-break: break-word;
`

export const ExpandTextButton = styled.button`
  color: ${({ theme }) => theme.colors.gray[700]};
  cursor: pointer;
  text-decoration: underline;
  display: block;
  font-size: ${({ theme }) => theme.text.fontSize.m};
  text-align: start;
`
