import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import Tile from '~/shared/atoms/Tile'

export const Wrapper = styled(Tile)`
  position: relative;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.2s;

  ${mediaQueryUp('m')} {
    max-width: 305px;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.commons.shadow.l};
  }
`
