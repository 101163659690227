// @ts-strict-ignore
/* eslint-disable id-length */

import localeModules from 'locale/dist'
import Gettext from 'node-gettext'

import { processEnv } from '~/utils/env'

import { t, tn, tnp, tp } from './translators'

const inDebugMode = processEnv.NODE_ENV !== 'production'

const logDebug = getLogger('locale:gt', 'debug')

function getSupportedLocales() {
  return Object.keys(localeModules)
}

class GT {
  private gettext: Gettext

  constructor(debug = inDebugMode) {
    this.gettext = new Gettext({ debug, sourceLocale: 'pl' })
  }

  get locale() {
    return this.gettext?.locale
  }

  t = (message: string, scope?: any) => t(this.gettext.gettext.bind(this.gettext))(message, scope)

  tn = (one: string, other: string, count: number, scope?: any) => tn(this.gettext.ngettext.bind(this.gettext))(one, other, count, scope)

  tp = (context: string, message: string, scope?: any) => tp(this.gettext.pgettext.bind(this.gettext))(context, message, scope)

  tnp = (context: string, one: string, other: string, count: number, scope?: any) =>
    tnp(this.gettext.npgettext.bind(this.gettext))(context, one, other, count, scope)

  getSupportedLocales() {
    return getSupportedLocales()
  }

  update(locale: string, messages: object) {
    logDebug(`Update locale ${locale}`)
    const { gettext } = this
    if (!gettext) {
      return
    }

    if (gettext.locale !== locale) {
      logDebug(`Detected locale change from ${gettext.locale || 'unknown'} to ${locale}`)
      Object.keys(messages).forEach(lang => {
        gettext.addTranslations(lang, 'messages', messages[lang])
      })
      gettext.setLocale(locale)
    }
  }
}

export const gt = new GT()
