import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Heading6 } from '~/theme/utils/typography'

import { StyledTagBar } from '~/shared/molecules/TagBar/TagBarShards'

export const Container = styled.div`
  max-width: 100%;
  overflow: hidden;
  height: fit-content;

  ${StyledTagBar} {
    height: unset;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  align-items: center;
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  & > *:not(:last-child) {
    ${mediaQueryUp('m')} {
      margin-bottom: 1rem;
    }
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.gray[1000]};
  max-width: 45rem;

  ${({ theme }) => theme.text.h3({ theme, fontWeight: 'semiBold' })};

  ${mediaQueryUp('m')} {
    ${({ theme }) => theme.text.h2({ theme, fontWeight: 'semiBold' })};
  }
`

export const Tagline = styled.h5`
  color: ${({ theme }) => theme.colors.gray[600]};

  ${({ theme }) => theme.text.h6({ theme, fontWeight: 'semiBold' })};

  ${mediaQueryUp('m')} {
    ${({ theme }) => theme.text.h5({ theme, fontWeight: 'semiBold' })};
    color: ${({ theme }) => theme.colors.gray[1000]};
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 1.5rem;
  }
`

export const ActionButton = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > *:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  img {
    height: 1.5rem;
    width: auto;
    filter: ${({ theme, active }) => (active ? theme.filters.green[400] : theme.filters.gray[700])};

    ${mediaQueryUp('l')} {
      height: 1.25rem;
    }
  }
`

export const ActionButtonTitle = styled(Heading6)<{ active?: boolean }>`
  display: none;

  ${mediaQueryUp('l')} {
    color: ${({ theme, active }) => (active ? theme.colors.green[400] : theme.colors.gray[700])};
    transition: color 0.2s;
    margin-left: 0.5rem;
    display: block;
    font-family: ${({ theme }) => theme.text.fontFamily.fontSans};
  }
`
