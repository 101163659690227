import styled from '@emotion/styled'

import { breakpoints, mediaQueryUp } from '~/theme/utils/grid'

type VisibleFromProps = {
  breakpoint: keyof typeof breakpoints
}

export const VisibleFrom = styled.div<VisibleFromProps>`
  display: none;

  ${props => mediaQueryUp(props.breakpoint)} {
    display: block;
  }
`

export const HiddenFrom = styled.div<VisibleFromProps>`
  display: block;

  ${props => mediaQueryUp(props.breakpoint)} {
    display: none;
  }
`
