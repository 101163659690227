import Link from '~/shared/atoms/Link'

import { Wrapper } from './ImageBannerShards'
import { BannerDetails } from './ImageBannerTypes'

interface Props {
  link: string
  desktop: BannerDetails
  mobile: BannerDetails
}
const ImageBannerView = ({ link, mobile, desktop }: Props) => {
  return (
    <Link href={link}>
      <Wrapper mobile={mobile} desktop={desktop} />
    </Link>
  )
}

export default ImageBannerView
