import { gt } from '~/locale'
import { Tab } from '~/shared/molecules/Header/HeaderUtils'
import { Container } from '~/shared/molecules/Header/TopBarMenu/TopBarMenuShards'
import { TopBarLinkItem } from '~/shared/molecules/Header/TopBarMenu/TopBarMenuView'
import LanguageSwitcher from '~/shared/molecules/LanguageSwitcher'

type FestivalListingHeaderTopBarProps = {
  onClose(): void
  topTabs: Tab[]
}

export const FestivalListingHeaderTopBar = ({ onClose, topTabs }: FestivalListingHeaderTopBarProps) => {
  const { tp } = gt

  return (
    <Container isTablet={false}>
      {topTabs.map((tab, index) => (
        <>
          {index === topTabs.length - 1 && <div className='separator' style={{ flexGrow: 1 }} />}
          <TopBarLinkItem
            key={index}
            href={tab.href}
            label={tp(tab.label[0], tab.label[1])}
            onClick={onClose}
            isTablet={false}
            enabledForFeatureFlag={tab.enabledForFeatureFlag?.key}
          />
        </>
      ))}
      <LanguageSwitcher variant='small' onClose={onClose} />
    </Container>
  )
}
