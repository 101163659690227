import styled from '@emotion/styled'

import { BasicIcon } from '~/shared/atoms/Icon'
import { StyledPriceTag } from '~/shared/atoms/PriceTag/PriceTagShards'

export const Item = styled.span`
  display: flex;
  height: fit-content;
  width: fit-content;
  align-items: center;
`

export const StyledTagBar = styled.div<{
  size: string
  variant?: 'plainText' | 'default'
}>`
  display: flex;
  align-items: center;
  line-height: ${({ size }) => (size === 'small' ? '1rem' : '1.5rem')};
  width: 100%;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.gray[800]};
  max-width: 45rem;
  line-height: 1.5;
  font-size: ${({ theme, size }) => (size === 'small' ? theme.text.fontSize.s : theme.text.fontSize.m)};
  height: 1.625rem;
  gap: ${({ variant }) => (variant === 'default' ? '0.5rem' : 0)};
`

export const PlainTextTagBar = styled.span<{
  size: string
}>`
  line-height: ${({ size }) => (size === 'small' ? '1rem' : '1.5rem')};
  width: 100%;
  color: ${({ theme }) => theme.colors.gray[800]};
  max-width: 45rem;
  font-size: ${({ theme, size }) => (size === 'small' ? theme.text.fontSize.s : theme.text.fontSize.m)};

  & ${Item} {
    display: inline-flex;
    line-height: 1;
    align-items: end;
  }

  & ${StyledPriceTag} {
    display: inline-block;
  }
`

export const TagBarIconContainer = styled.div`
  height: 0.9375rem;
  width: 0.9375rem;
  margin: 0 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledStarIcon = styled(BasicIcon)`
  width: 100%;
  filter: ${({ theme }) => theme.filters.gray[800]};
`

export const TagWrapper = styled.span`
  display: flex;
  height: fit-content;
  width: fit-content;
  align-items: center;
`

export const DropdownSelect = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 0.25rem;
  }

  img {
    height: 0.6rem;
    filter: ${({ theme }) => theme.filters.gray[1000]};
    transform: rotate(${({ active }) => (active ? 90 : -90)}deg);
  }
`
