// @ts-strict-ignore
import { useState } from 'react'

import { gt } from '~/locale'

import {
  CheckboxBox,
  HiddenInput,
  Label,
  LabelAddition,
  LabelExpanded,
  ShowMore,
  StyledCheckboxField,
  StyledCheckboxIcon,
} from './CheckboxShards'

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode
  labelAddition?: string
  indeterminate?: boolean
  disabled?: boolean
  checked?: boolean
  showMore?: React.ReactNode
  error?: boolean
}

const CheckboxView = ({
  label,
  labelAddition,
  checked,
  disabled,
  onChange,
  showMore,
  indeterminate: defaultIndeterminate,
  error = false,
  ...props
}: CheckboxProps) => {
  const [isIndeterminate, setIsIndeterminate] = useState(defaultIndeterminate)
  const icon = isIndeterminate ? 'indeterminate' : 'check-bold'
  const [expand, setExpand] = useState(false)
  const { tp } = gt

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isIndeterminate) {
      setIsIndeterminate(false)
    }

    onChange(event)
  }

  return (
    <StyledCheckboxField disabled={disabled} error={error}>
      <CheckboxBox checked={checked} disabled={disabled}>
        {(checked || isIndeterminate) && <StyledCheckboxIcon icon={icon} />}
      </CheckboxBox>
      <Label>
        {label}
        {labelAddition && <LabelAddition>&nbsp;{labelAddition}</LabelAddition>}
        {showMore && (
          <>
            {' '}
            {expand && <LabelExpanded>{showMore}</LabelExpanded>}
            <ShowMore
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                setExpand(!expand)
              }}>
              {expand ? tp('Checkbox', 'Hide') : tp('Checkbox', 'Expand')}
            </ShowMore>
          </>
        )}
      </Label>
      <HiddenInput {...props} checked={checked} type={'checkbox'} onChange={handleChange} />
    </StyledCheckboxField>
  )
}

export default CheckboxView
