import React, { ReactNode } from 'react'

import url from 'url'

import Link from '~/shared/atoms/Link'

import { Anchor } from './ProductCardActionsShards'

interface Props {
  onClick?: () => void
  children: ReactNode
  href?: string | url.UrlObject
}

const ProductCardActions = ({ onClick, href, children }: Props) => {
  if (!href) return <>{children}</>

  const handleClick = (e: React.MouseEvent) => {
    if (!onClick || e.metaKey) return

    e.preventDefault()
    e.stopPropagation()
    onClick()
  }
  const resolvedHref = typeof href === 'string' ? href : url.format(href)

  return (
    <Link href={resolvedHref}>
      <Anchor target='_blank' onClick={handleClick}>
        {children}
      </Anchor>
    </Link>
  )
}

export default ProductCardActions
