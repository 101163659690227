import { useRouter } from 'next/router'

import { ApolloError } from '@apollo/client'

import { getTimeLeft } from '~/utils/datetime'
import { formatErrorMessages, getErrorCodes } from '~/utils/formatErrorMessages'
import { isAbortError } from '~/utils/init-apollo'
import { isFER } from '~/utils/isReservableType'

import { AdditionalConsent } from '~/components/Checkout/CheckoutPage/CheckoutGuestLogin/CheckoutGuestLoginTypes'
import { modalProps } from '~/components/Checkout/CheckoutPage/MarketingConsentModal/MarketingConsentModalUtils'
import MarketingConsentModal from '~/components/Checkout/CheckoutPage/MarketingConsentModal/MarketingConsentModalView'
import { getMetadataValue } from '~/components/GiftEvents/pages/GiftEventsListingPage/GiftEventsListingPageUtils'
import { ConsentLabels } from '~/components/User/UserPageLayout/UserSettings/UserSettingsUtils'
import { getClubConfig } from '~/config/clubConfig'
import { paths } from '~/constants'
import { shouldTriggerMarketingModal } from '~/constants/errors'
import { FestivalCodesEnum, ProviderSpecificPaymentMethodEnum, ReservableEnum, UserConsentSubtypeEnum } from '~/generated/graphql'
import { useCheckoutError } from '~/layout/CheckoutLayoutNew/CheckoutLayoutNewView'
import { NewToastParams } from '~/shared/atoms/Toast/ToastTypes'
import { useModal } from '~/shared/molecules/Modal'
import { Timestamp } from '~/types/common'

import { hasComplimentaryCrossSells, hasCrossSells } from './CheckoutCrossSells/CheckoutCrossSellsUtils'
import { CheckoutFlow, CheckoutReservable, CheckoutReservation, MinimumReservable, PaymentMethod } from './CheckoutPageTypes'

export const handleError = (err: ApolloError, add: (p: NewToastParams) => void) => {
  formatErrorMessages(err)?.map(error =>
    add({
      type: 'error',
      containerSelector: '#ReservationSectionToastsContainer',
      size: 'large',
      message: error,
    })
  )
}

export const updateResponseKey = (reservableType: ReservableEnum) => {
  switch (reservableType) {
    case ReservableEnum.Daily:
      return 'updateDailyReservation'
    case ReservableEnum.Event:
      return 'updateEventReservation'
    default:
      return 'updateFestivalReservation'
  }
}

export const getReservationExpirationTimeLeft = ({ timestamp, expiresAt }: { timestamp: Timestamp; expiresAt: Timestamp }) => {
  return getTimeLeft(timestamp, expiresAt)
}

export const hasReservableExtraAssigments = (reservation: Pick<CheckoutReservation, 'reservableExtraAssignments'>) =>
  Array.isArray(reservation.reservableExtraAssignments) && reservation.reservableExtraAssignments.length > 0

export const additionalPartnerConsentsForFestival: Partial<Record<FestivalCodesEnum, AdditionalConsent[]>> = {
  gw: [
    {
      subType: UserConsentSubtypeEnum.Pernod,
      required: false,
      label: <ConsentLabels path='partners.gw.label' />,
      showMore: <ConsentLabels path='partners.gw.showMore' />,
    },
  ],
}

export const convertPermittedPaymentMethods = (permittedPaymentMethods: ProviderSpecificPaymentMethodEnum[]): PaymentMethod[] => {
  const clubConfig = getClubConfig()

  const permittedPaymentMethodsConfig = {
    [PaymentMethod.BLIK]:
      clubConfig.blikEnabled && permittedPaymentMethods.includes(ProviderSpecificPaymentMethodEnum.PayuBlikAuthorizationCode),
    [PaymentMethod.Card]:
      clubConfig.directCardPaymentEnabled && permittedPaymentMethods.includes(ProviderSpecificPaymentMethodEnum.PayuCard),
    [PaymentMethod.Klarna]:
      clubConfig.klarnaEnabled && permittedPaymentMethods.includes(ProviderSpecificPaymentMethodEnum.KlarnaPaymentMethod),
    [PaymentMethod.PayPo]:
      clubConfig.paypoEnabled && permittedPaymentMethods.includes(ProviderSpecificPaymentMethodEnum.PaypoPaymentMethod),
    [PaymentMethod.VisaMobile]:
      clubConfig.visaMobileEnabled && permittedPaymentMethods.includes(ProviderSpecificPaymentMethodEnum.PayuVisaMobile),
    [PaymentMethod.ApplePay]: permittedPaymentMethods.includes(ProviderSpecificPaymentMethodEnum.PayuApplePay),
    [PaymentMethod.GooglePay]: permittedPaymentMethods.includes(ProviderSpecificPaymentMethodEnum.PayuGooglePay),
    [PaymentMethod.PBL]: permittedPaymentMethods.includes(ProviderSpecificPaymentMethodEnum.PayuPbl),
  }

  return (Object.keys(permittedPaymentMethodsConfig) as PaymentMethod[]).filter(method => !!permittedPaymentMethodsConfig[method])
}

export const shouldShowVisaVoucherBanner = (reservable: CheckoutReservable) => {
  const isFERReservation = isFER(reservable)

  if (isFERReservation) {
    return getMetadataValue(reservable.festivalEdition.festival.metadatas, 'enableVisa') === 'true'
  }

  return false
}

export const getCheckoutSecondStep = ({
  reservable,
  code,
  flow,
  editMode,
  isCHM,
}: {
  reservable: MinimumReservable
  code: CheckoutReservation['code']
  flow?: CheckoutFlow
  editMode?: boolean
  hasAnyCrossSells?: boolean
  isCHM?: boolean
}) => {
  const hasCrossSellsType = 'crossSells' in reservable

  if (hasCrossSellsType && hasComplimentaryCrossSells(reservable.crossSells) && !isCHM) {
    return paths.checkoutComplimentaryCrossSells(code, flow, editMode)
  }

  if (hasCrossSellsType && hasCrossSells(reservable.crossSells)) {
    return paths.checkoutCrossSells(code, flow, editMode)
  }

  return paths.checkoutGuest(code, flow, editMode)
}

export const useCheckoutFlow = () => {
  const router = useRouter()
  const flow = router.query.flow as Partial<CheckoutFlow>
  const editMode = !!router.query.editMode

  return { flow, editMode }
}

export const useHandleVoucherError = () => {
  const { showError } = useCheckoutError()
  const modal = useModal()

  return ({ error, onMarketingConsentAccepted }: { error: ApolloError; onMarketingConsentAccepted?: () => Promise<unknown> | void }) => {
    if (isAbortError(error.networkError)) return

    const errorCodes = getErrorCodes(error)
    const messages = formatErrorMessages(error)
    if (shouldTriggerMarketingModal(errorCodes) && onMarketingConsentAccepted) {
      modal.open(
        modalProps,
        <MarketingConsentModal
          handleAccepted={async () => {
            await onMarketingConsentAccepted()
            modal.close()
          }}
        />
      )
    } else if (messages.length > 0) {
      showError(error)
    }
  }
}
