import { ButtonContainer, MainText, SecondaryText, TextContainer } from '~/components/Festival/Banners/BannerShards'
import { defaultStyle } from '~/components/Festival/Banners/BannerUtils'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Link from '~/shared/atoms/Link'

import { Banner, Container } from './FestivalMenuBannerShards'
import { FestivalMenuBannerProps } from './FestivalMenuBannerTypes'
import { buildMenusRoute } from './FestivalMenuBannerUtils'

const FestivalMenuBannerView = ({ festivalEditionId, location, styles = defaultStyle }: FestivalMenuBannerProps) => {
  const { tp } = gt
  return (
    <Banner color={styles.bgColor} image={styles.imageUrl}>
      <Container>
        <TextContainer>
          <MainText color={styles.titleFontColor}>{tp('FestivalMenuBanner', 'Festival Menu')}</MainText>
          {/* eslint-disable-next-line max-len */}
          <SecondaryText color={styles.fontColor}>
            {tp('FestivalMenuBanner', 'Click and see an alphabetical list of all the dishes at the Festival!')}
          </SecondaryText>
        </TextContainer>
        <ButtonContainer>
          <Link href={buildMenusRoute(festivalEditionId, location)}>
            <Button label={tp('FestivalMenuBanner', 'Open Menu')} color={styles.buttonColor} width={'fixed'} as='a' />
          </Link>
        </ButtonContainer>
      </Container>
    </Banner>
  )
}

export default FestivalMenuBannerView
