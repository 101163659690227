import styled from '@emotion/styled'

import { Body2 } from '~/theme/utils/typography'

import { Container } from '../Input/InputShards'

export const PhoneInputContainer = styled.div`
  ${Body2} {
    color: ${({ theme }) => theme.colors.gray[600]};
    margin-bottom: 4px;
  }

  ${Body2}:last-child {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.red[400]};
  }
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  & .PhoneInput {
    display: flex;
    gap: 12px;
    position: relative;
  }

  ${Container} {
    margin-top: 0;
  }

  & .PhoneInputCountrySelect {
  }
`

export const ValueContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`

export const ValueContainerImageWrapper = styled.div`
  display: flex;
  height: 100%;
  margin-left: 16px;
  gap: 6px;
  align-items: center;

  & img {
    width: 32px;
    height: 100%;
    border: 0.5px solid ${({ theme }) => theme.colors.gray[300]};
    border-radius: 0.1rem;
  }
`

export const HiddenInput = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`
