import * as React from 'react'

import { getStaticFilePath } from '~/utils/helpers'

import { MetadataType } from './HeadTypes'
import { getDefaultMetadatas } from './HeadUtils'

const HeadManifest: React.FC<{ metadata?: MetadataType }> = ({ metadata }) => (
  <>
    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
    <meta name='description' content={metadata?.description || getDefaultMetadatas().description} />
    <meta property='og:image' content={metadata?.imageUrl || getDefaultMetadatas().imageUrl} key='image' />
    <meta property='og:title' content={metadata?.title || getDefaultMetadatas().title} key='title' />
    <meta property='og:description' content={metadata?.description || getDefaultMetadatas().description} key='description' />
    <meta property='og:site_name' content={getDefaultMetadatas().siteName} />
    <meta property='og:type' content={metadata?.type || 'website'} />

    <link rel='shortcut icon' href={getStaticFilePath('favicon.png')} />
    <meta name='apple-itunes-app' content='app-id=1490565530, affiliate-data=restaurantclub' />
    <meta name='facebook-domain-verification' content='h3qy4e8vlegdngh8aa9l4f60dewdj2' />
    {/* <meta name='sentry-trace' content={sentryTraceId()} /> */}
  </>
)

export default HeadManifest
