import { Styles } from '~/components/Festival/Banners/BannerTypes'

export const rwpStyle: Styles = {
  bgColor: '#24236A',
  buttonColor: 'rwp',
  titleFontColor: '#FFFFFF',
  fontColor: '#FFFFFF',
  dateColor: '#FFEE00',
  imageUrl: {
    desktop: '/assets/rwp-main-hand.png',
    mobile: '/assets/rwp-main-hand-mobile.png',
  },
}
export const bwStyle: Styles = {
  bgColor: '#F4E7D7',
  buttonColor: 'bw',
  titleFontColor: '#1C2473',
  fontColor: '#1C2473',
  dateColor: '#1C2473',
  imageUrl: {
    desktop: '/assets/bw-main.png',
    mobile: '/assets/bw-main.png',
  },
}
