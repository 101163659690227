import { ReactNode } from 'react'

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'

import { growthbookConfig, initGrowthbookInstance, useGrowthbookDataRefresh } from '~/utils/growthbook'

type Props = {
  children: ReactNode
}

const growthbook = new GrowthBook(growthbookConfig)

initGrowthbookInstance(growthbook)

export const GrowthbookProvider = ({ children }: Props) => {
  useGrowthbookDataRefresh(growthbook)

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
}
