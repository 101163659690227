import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const Banner = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-top: 0.625rem;

  a {
    padding: 0;
    min-height: 1.875rem;
    font-size: ${({ theme }) => theme.text.fontSize.xs};
  }

  ${mediaQueryUp('m')} {
    align-items: center;
    margin: 0 0 0 auto;

    a {
      padding: 0.75rem 1.5rem;
      min-height: 2.875rem;
      font-size: ${({ theme }) => theme.text.fontSize.base};
    }
  }
`

export const MainText = styled.span<{ color?: string }>`
  font-size: 1.25rem;
  font-weight: 500;

  ${mediaQueryUp('m')} {
    font-size: 1.625rem;
  }

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`

export const SecondaryText = styled.span`
  font-size: ${({ theme }) => theme.text.fontSize.xs};
  font-weight: 400;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${mediaQueryUp('m')} {
    font-size: ${({ theme }) => theme.text.fontSize.s};
  }

  ${mediaQueryUp('l')} {
    font-size: ${({ theme }) => theme.text.fontSize.m};
  }
`
