import { useState } from 'react'

import { Heading3, Heading6 } from '~/theme/utils/typography'

import { UserDataDocument, useUpdateUserMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Checkbox from '~/shared/atoms/Checkbox'

import { StyledMarketingConsentModal } from './MarketingConsentModalShards'

interface MarketingConsentModalViewProps {
  handleAccepted: () => void
}

const MarketingConsentModalView = ({ handleAccepted }: MarketingConsentModalViewProps) => {
  const [checked, setChecked] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [updateUser] = useUpdateUserMutation({
    variables: { hasMarketingConsent: true },
    refetchQueries: [{ query: UserDataDocument }],
    onCompleted: () => handleAccepted(),
  })

  const handleSubmit = () => {
    setSubmitting(true)
    return updateUser()
  }

  return (
    <StyledMarketingConsentModal>
      <Heading3 fontWeight='semiBold'>{gt.tp('MarketingConsentModal', 'Follow our newsletter to use the discount code')}</Heading3>
      <Heading6 fontWeight='regular'>
        {gt.tp(
          'MarketingConsentModal',
          "You'll gain access to regular discounts\nand won't miss reservation openings with availability of the best tables.\nThere are up to 7 restaurant and bar festivals a year!"
        )}
      </Heading6>
      <Checkbox
        label={gt.tp('MarketingConsentModal', '* Marketing Consent Accept')}
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      <Button
        label={gt.tp('ForgotPasswordModal', 'To add the code, select consent above')}
        color='green'
        width='full'
        height='big'
        fontWeight='regular'
        disabled={!checked || isSubmitting}
        loading={isSubmitting}
        onClick={handleSubmit}
      />
    </StyledMarketingConsentModal>
  )
}

export default MarketingConsentModalView
