import dynamic from 'next/dynamic'

import Loader from '~/shared/atoms/Loader'

import { LoaderContainer, MapWrapper } from './MapShards'

export interface MapItem {
  latitude: number
  longitude: number
  name?: string
  description?: string
  detailsHref?: string
}

export interface MapViewProps {
  items: Array<MapItem>
  loading?: boolean
}

const Loading = () => (
  <LoaderContainer>
    <Loader color='gray' />
  </LoaderContainer>
)

const MapView = ({ items, loading }: MapViewProps) => {
  const MapContent = dynamic(() => import('./MapUtils'), {
    loading: () => <Loading />,
    ssr: false,
  })

  if (loading) {
    return <Loading />
  }

  return (
    <MapWrapper id='map'>
      <MapContent items={items} />
    </MapWrapper>
  )
}

export default MapView
