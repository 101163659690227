import { createContext, ReactNode, useContext, useId } from 'react'

import { normalizeProps, Portal, PropTypes, useActor, useMachine } from '@zag-js/react'
import * as toast from '@zag-js/toast'

import { IconWrapper, Label, ToastIcon, XIcon } from '../Toast/ToastShards'
import { ToastType } from '../Toast/ToastTypes'
import { ICONS } from '../Toast/ToastUtils'

import { CloseTrigger, NewStyledToast, NewToastsContainer } from './NewToastShards'

function Toast({ actor, onClose }: { actor: toast.Service; onClose: () => void }) {
  const [state, send] = useActor(actor)
  const api = toast.connect(state, send, normalizeProps)

  return (
    <NewStyledToast type={api.type as ToastType} {...api.getRootProps()}>
      <span {...api.getGhostBeforeProps()} />
      <IconWrapper type={api.type as ToastType}>
        {/* @ts-ignore toast has weird types */}
        <ToastIcon icon={ICONS[api.type]} />
      </IconWrapper>
      <div {...api.getTitleProps()}>
        <Label>{api.title}</Label>
      </div>
      <CloseTrigger onClick={onClose}>
        <IconWrapper>
          <XIcon icon='x' />
        </IconWrapper>
      </CloseTrigger>
      <span {...api.getGhostAfterProps()} />
    </NewStyledToast>
  )
}

const ToastContext = createContext({})
export const useNewToast = (): toast.GroupApi<PropTypes, any> => useContext<any>(ToastContext)

export function NewToastProvider({ children }: { children: ReactNode }) {
  const [state, send] = useMachine(
    toast.group.machine({
      id: useId(),
      overlap: true,
      offsets: '10px',
      placement: 'top-end',
      duration: 999999,
      removeDelay: 50000,
      max: 20,
    }),
    {
      context: {
        id: useId(),
        duration: 999999,
        placement: 'top-end',
        offsets: '10px',
        overlap: true,
      },
    }
  )

  const api = toast.group.connect(state, send, normalizeProps)

  return (
    <ToastContext.Provider value={api}>
      <Portal>
        {api.getPlacements().map(placement => (
          <NewToastsContainer key={placement} {...api.getGroupProps({ placement })}>
            {api.getToastsByPlacement(placement).map(toast => {
              return <Toast key={toast.id} actor={toast} onClose={() => api.remove(toast.id)} />
            })}
          </NewToastsContainer>
        ))}
      </Portal>
      {children}
    </ToastContext.Provider>
  )
}
