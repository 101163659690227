import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { BannerDetails } from './ImageBannerTypes'

export const Wrapper = styled.div<{ mobile: BannerDetails; desktop: BannerDetails }>`
  width: 100%;
  aspect-ratio: ${({ mobile }) => `${mobile.width} / ${mobile.height}`};
  height: auto;
  margin-bottom: 1.5rem;
  border-radius: 0.5625rem;
  background-image: ${({ mobile }) => `url('${mobile.localPath}')`};
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;

  ${mediaQueryUp('m')} {
    aspect-ratio: ${({ desktop }) => `${desktop.width} / ${desktop.height}`};
    background-image: ${({ desktop }) => `url('${desktop.localPath}')`};
  }
`
