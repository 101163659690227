import { ClubConfigPaths } from '../../types'

export const paths: ClubConfigPaths = {
  socialMedia: [
    { social: 'facebook', href: 'https://www.facebook.com/RestaurantWeekCesko' },
    { social: 'instagram', href: 'https://www.instagram.com/restaurantweek_cz/' },
  ],
  resetPasswordUrl: {
    redirectUrl: '/user',
    onExitUrl: '/restaurants/festival/restaurant-week',
    shallow: false,
  },
}
