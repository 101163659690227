import parsePhoneNumberFromString, { isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js/max'

import yup from '~/utils/validationMessages'

import { errors } from '~/constants/errors'

export const validationFields = {
  email: yup.string().email().required(),
  firstName: yup.string().max(100).required(),
  lastName: yup.string().max(100).required(),
  password: yup.string().required(),
  phoneNumber: yup.string().test({
    name: 'length',
    message: ({ value }) => {
      if (!value) return true

      const numberForLibphone = `+${value}`
      const parsedNumber = parsePhoneNumberFromString(numberForLibphone)
      const phoneNumber = parsedNumber?.number || value

      const validationError = validatePhoneNumberLength(phoneNumber)

      if (validationError === 'TOO_LONG') return errors.validation.phone_number.too_long
      if (validationError === 'TOO_SHORT') return errors.validation.phone_number.too_short

      const isValid = isValidPhoneNumber(phoneNumber)

      if (!isValid) {
        return errors.validation.phone_number.invalid
      }
    },
    test: value => {
      if (!value) return true
      const numberForLibphone = `+${value}`

      const parsedNumber = parsePhoneNumberFromString(numberForLibphone)
      const phoneNumber = parsedNumber?.number || value

      const validationError = validatePhoneNumberLength(phoneNumber)

      const isValid = isValidPhoneNumber(phoneNumber)

      return !validationError && isValid
    },
  }),
  city: yup
    .string()
    .matches(/^[A-Za-zĄĆĘŁŃÓŚŹŻąćęłńóśźż]+$/, errors.validation.mixed.letters_only)
    .required(),
}
