// @ts-strict-ignore

import ChefsMenuModal from '~/components/ChefsMenu/ChefsMenuModal'
import FERModal, { festivalModalProps } from '~/components/Festival/FERModal'
import { RESERVABLE_TYPES } from '~/constants'
import { FerModalQuery, SingleReservableExtraQuery } from '~/generated/graphql'
import Modal from '~/shared/molecules/Modal'

import { useProductModalNavigation } from './ProductModalUtils'

interface Props {
  type: typeof RESERVABLE_TYPES.FER | typeof RESERVABLE_TYPES.EXTRA
  FER?: FerModalQuery['festivalEditionRestaurant']
  reservableExtra?: SingleReservableExtraQuery['reservableExtra']
  handleClose: () => void
}

const ProductModal = ({ reservableExtra, FER, type, handleClose }: Props) => {
  const { closeModal } = useProductModalNavigation({ reservableExtra, FER, type, handleClose })

  return (
    <Modal {...festivalModalProps} handleClose={closeModal}>
      {type === RESERVABLE_TYPES.FER ? (
        <FERModal ferId={FER.id} withCarousel />
      ) : (
        <ChefsMenuModal handleClose={closeModal} reservableExtra={reservableExtra} />
      )}
    </Modal>
  )
}

export default ProductModal
