import { useCallback, useState } from 'react'

import Link from 'next/link'

import { FestivalBannerContainer, FestivalBannerLink } from './FestivalBannerShards'

interface SourceParams {
  media: string
  sizes: string
}

interface Props {
  files: any
  sourceParams?: {
    mobile: SourceParams
    desktop: SourceParams
  }
  middle?: boolean
  href?: string
}

export const FestivalBannerView = ({ files, sourceParams, middle, href }: Props) => {
  const bannerMobile = files?.mobile?.original?.url
  const banner = files?.desktop?.original?.url
  const [showBanner, setShowBanner] = useState(true)

  const handleError = useCallback(() => {
    setShowBanner(false)
  }, [setShowBanner])

  const bannerContent = (
    <FestivalBannerContainer middle={middle} href={!!href}>
      <picture onError={handleError}>
        <source
          media={sourceParams?.mobile?.media ?? '(max-width: 599px)'}
          sizes={sourceParams?.mobile?.sizes ?? '100vw'}
          srcSet={bannerMobile}
        />
        <source
          media={sourceParams?.desktop?.media ?? '(min-width: 600px)'}
          srcSet={banner}
          sizes={sourceParams?.desktop?.sizes ?? '(max-width: 1440px) 100%, 1280px'}
        />
        <img src={bannerMobile} alt='festival banner' draggable={false} />
      </picture>
    </FestivalBannerContainer>
  )

  if (!showBanner) {
    return null
  }

  return href ? (
    <Link href={href} legacyBehavior passHref>
      <FestivalBannerLink data-ga={href ? 'festival-banner' : null} target='_blank'>
        {bannerContent}
      </FestivalBannerLink>
    </Link>
  ) : (
    <>{bannerContent}</>
  )
}

export default FestivalBannerView
