// @ts-strict-ignore
import type { LinkHTMLAttributes } from 'react'

import { CSSInterpolation } from '@emotion/css'
import { css, Theme } from '@emotion/react'

import { fontSize } from '~/theme/text'

import { BasicButtonIcon } from './ButtonShards'
import { ButtonColor, ButtonHeight, ButtonState, ButtonVariant, ButtonWidth, Props } from './ButtonTypes'

export const buttonWidth: Record<ButtonWidth & { fixed: Record<ButtonHeight, string> }, string> = {
  fit: 'fit-content',
  full: '100%',
  fixed: {
    small: '7rem',
    normal: '7.75rem',
    big: '8.5rem',
  },
}

export const buttonHeight: Record<ButtonHeight, string> = {
  small: '2.125rem',
  normal: '2.875rem',
  big: '3.125rem',
}

export const buttonPadding: Record<ButtonHeight, string> = {
  small: '0.625rem 0.875rem',
  normal: '0.75rem 1.5rem',
  big: '0.875rem 1rem',
}

export const buttonFontSize: Record<ButtonHeight, string> = {
  small: fontSize.base,
  normal: fontSize.base,
  big: fontSize.l,
}

export const buttonColors: (
  theme: Theme
) => Record<ButtonColor, Record<ButtonState | 'text' | 'filter' | 'filterDefault', string>> = theme => ({
  primary: {
    default: theme.colors.gray[900],
    hover: theme.colors.gray[800],
    active: theme.colors.gray[700],
    loading: theme.colors.gray[800],
    text: theme.colors.gray[0],
    filter: theme.filters.gray[0],
    filterDefault: theme.filters.gray[900],
  },
  secondary: {
    default: theme.colors.gray[0],
    hover: theme.colors.gray[0],
    active: theme.colors.gray[50],
    loading: theme.colors.gray[0],
    text: theme.colors.gray[1000],
    filter: theme.filters.gray[1000],
    filterDefault: theme.filters.gray[0],
  },
  orange: {
    default: theme.colors.orange[500],
    hover: theme.colors.orange[600],
    active: theme.colors.orange[600],
    loading: theme.colors.orange[300],
    text: theme.colors.gray[0],
    filter: theme.filters.gray[0],
    filterDefault: theme.filters.orange[500],
  },
  green: {
    default: theme.colors.green[400],
    hover: theme.colors.green[500],
    active: theme.colors.green[600],
    loading: theme.colors.green[300],
    text: theme.colors.gray[0],
    filter: theme.filters.gray[0],
    filterDefault: theme.filters.green[400],
  },
  red: {
    default: theme.colors.red[400],
    hover: theme.colors.red[500],
    active: theme.colors.red[600],
    loading: theme.colors.red[300],
    text: theme.colors.gray[0],
    filter: theme.filters.gray[0],
    filterDefault: theme.filters.red[400],
  },
  blue: {
    default: theme.colors.blue[400],
    hover: theme.colors.blue[500],
    active: theme.colors.blue[600],
    loading: theme.colors.blue[300],
    text: theme.colors.gray[0],
    filter: theme.filters.gray[0],
    filterDefault: theme.filters.gray[400],
  },
  fdw: {
    default: '#A88768',
    hover: '#BA9773',
    active: '#C19E7B',
    loading: '#C19E7B',
    text: '#FFFFFF',
    filter: '#FFFFFF',
    filterDefault: '#A88768',
  },
  bw: {
    default: '#FFCF67',
    hover: '#FFCF67',
    active: '#FFCF67',
    loading: '#FFCF67',
    text: '#24236A',
    filter: '#24236A',
    filterDefault: '#FFCF67',
  },
  rwp: {
    default: '#FFEE00',
    hover: '#FFEE00',
    active: '#FFEE00',
    loading: '#FFEE00',
    text: '#24236A',
    filter: '#24236A',
    filterDefault: '#FFEE00',
  },
})

export const buttonLoaderColor: (theme: Theme, props: Props) => Record<ButtonVariant, string> = (theme, { color }) => ({
  solid: buttonColors(theme)[color].text,
  outlined: buttonColors(theme)[color].default,
  ghost: theme.colors.gray[1000],
})

export const backgroundAndBorder = (value: string) => {
  return `background: ${value}; border: 1px solid ${value};`
}

export const buttonBehavior: (theme: Theme, props: Props) => Record<ButtonVariant, CSSInterpolation> = (theme, props) => {
  const colors = buttonColors(theme)[props.color]

  return {
    solid: css`
      color: ${colors.text};
      ${BasicButtonIcon} {
        filter: ${colors.filter};
      }
      ${backgroundAndBorder(colors.default)};

      &:hover,
      &:focus {
        color: ${colors.text};
        ${backgroundAndBorder(colors.hover)};
      }

      &:active {
        ${backgroundAndBorder(colors.active)};
      }

      ${props.disabled &&
      css`
        ${backgroundAndBorder(theme.colors.gray[400])};
      `}

      ${props.loading &&
      css`
        ${backgroundAndBorder(colors.loading)};
      `}

      ${props.color === 'secondary' &&
      css`
        box-shadow: ${theme.commons.shadow.xs};
        border: 1px solid ${theme.colors.gray[50]};

        &:hover {
          box-shadow: ${theme.commons.shadow.s};
        }

        ${props.disabled &&
        css`
          box-shadow: none;
        `}
      `}
    `,
    outlined: css`
      color: ${colors.default};
      ${BasicButtonIcon} {
        filter: ${colors.filterDefault};
      }
      border: 1px solid ${colors.default};
      background: transparent;

      &:hover {
        background: ${colors.hover};
        color: ${colors.text};
        ${BasicButtonIcon} {
          filter: ${colors.filter};
        }
        border: 1px solid ${props.height === 'small' ? colors.hover : theme.colors.gray[0]};
      }

      &:active {
        ${backgroundAndBorder(colors.active)};
        color: ${colors.text};
        ${BasicButtonIcon} {
          filter: ${colors.filter};
        }
      }

      /* edge case */
      ${props.color === 'secondary' &&
      css`
        ${buttonBehavior(theme, {
          ...props,
          color: 'orange',
        }).outlined}

        ${!props.loading &&
        !props.disabled &&
        css`
          color: ${colors.text};
          border: 1px solid ${theme.colors.gray[50]};
          background: transparent;
          ${BasicButtonIcon} {
            filter: ${colors.filter};
          }
        `}
      `}
    `,
    ghost: css`
      color: ${theme.colors.gray[1000]};
      ${BasicButtonIcon} {
        filter: ${theme.filters.gray[1000]};
      }
      ${backgroundAndBorder('transparent')};

      &:hover {
        ${backgroundAndBorder(theme.colors.gray[100])};
      }

      &:active {
        ${backgroundAndBorder(theme.colors.gray[200])};
      }
    `,
  }
}

export const getLinkAttributes = (props: Props): Pick<LinkHTMLAttributes<HTMLAnchorElement>, 'tabIndex'> => {
  return {
    tabIndex: props.disabled ? -1 : 0,
  }
}
