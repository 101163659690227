import styled from '@emotion/styled'

import { Heading5 } from '~/theme/utils/typography'
import { mediaQueryUp } from '~/utils/grid'

export const MapWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.gray[0]};

  .leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    z-index: 0;
  }
`

export const MapButtonWrapper = styled.div`
  grid-area: filter;
  padding: 0;
  width: 100%;

  ${mediaQueryUp('m')} {
    width: 33%;
  }
`

export const MapModalWrapper = styled.div`
  display: flex;
  border: none;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  box-shadow: ${({ theme }) => theme.commons.shadow.l};

  ${mediaQueryUp('m')} {
    height: 50vh;
    width: 50vw;
    border-radius: 1rem;
    overflow: hidden;
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  padding: 4rem;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  a {
    color: ${({ theme }) => theme.colors.gray[0]} !important;
  }
`

export const PopupTitle = styled(Heading5)`
  font-weight: 600;
`
