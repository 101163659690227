import React, { useMemo } from 'react'

import Image from 'next/image'

import { imgProxyLoader } from '~/utils/imgproxy'

import RestaurantSlider from '~/components/Restaurant/RestaurantPage/RestaurantSlider'
import { SliderImg } from '~/components/Restaurant/RestaurantPage/RestaurantSlider/RestaurantSliderView'
import { Like } from '~/shared/atoms/Like'

import { CardType } from '..'
import { LikeContainerWide, Photo, PhotoWrapper, SliderImageWrapper } from '../CardShards' // TODO

interface ImageSectionProps {
  type: CardType
  restaurantId: string
  favorited: boolean
  withCarousel: boolean
  isHovered: boolean
  restaurantName: string
  sliderImages: {
    id: string
    preview?: string | null
    original?: string | null
  }[]
}

const renderSliderItem = (type: CardType) => (image: SliderImg, restaurantName: string) => (
  <SliderImageWrapper type={type}>
    <Image
      loader={imgProxyLoader}
      src={image?.original || image?.preview || ''}
      quality={80}
      alt={restaurantName}
      layout='fill'
      objectFit='cover'
      priority={true}
    />
  </SliderImageWrapper>
)

export function ImageSection({ type, restaurantId, favorited, withCarousel, isHovered, restaurantName, sliderImages }: ImageSectionProps) {
  const renderSliderPhoto = useMemo(() => renderSliderItem(type), [type])

  return (
    <PhotoWrapper>
      <LikeContainerWide>
        <Like restaurantId={restaurantId} favorited={favorited} />
      </LikeContainerWide>
      {withCarousel ? (
        <RestaurantSlider
          images={sliderImages}
          restaurantName={restaurantName}
          withFullGallery={false}
          withControls={false}
          autoPlayInterval={1000}
          autoPlay={isHovered}
          renderItem={renderSliderPhoto}
          autoHeight={false}
        />
      ) : (
        <Photo src={sliderImages[0]?.preview || ''} alt={restaurantName} loader={imgProxyLoader} layout='fill' />
      )}
    </PhotoWrapper>
  )
}
