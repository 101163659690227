import { paths } from '~/constants/paths'
import { FestivalCodesEnum, OngoingFestivalsListQuery } from '~/generated/graphql'
import { NavBarRoute } from '~/shared/molecules/NavBarMobile/NavBarMobileTypes'

export const DEFAULT_NAV_BAR_ROUTES: NavBarRoute[] = [
  {
    label: 'Discover',
    icon: 'search',
    href: paths.home,
  },
  {
    label: 'Find a table',
    icon: 'restaurant',
    href: paths.restaurants,
  },
  {
    label: '#ChefsMenu',
    icon: 'chefsmenu',
    href: paths.chefsMenuOffers,
  },
  {
    label: 'Festivals',
    icon: 'festivals',
    href: paths.festivals,
  },
]

export const getNavBarRoutes = (ongoingFestivalsData?: OngoingFestivalsListQuery['ongoingFestivalEditions']): NavBarRoute[] => {
  if (!ongoingFestivalsData || ongoingFestivalsData.length === 0) {
    return DEFAULT_NAV_BAR_ROUTES
  }

  const festivalRoutes = ongoingFestivalsData
    ?.filter(ongoingFestival => ongoingFestival.state === 'SALE')
    ?.map(ongoingFestival => {
      if (ongoingFestival.festival.code === FestivalCodesEnum.Rw) {
        return {
          label: 'RestaurantWeek®',
          href: paths.festivalListing('restaurant-week'),
          icon: 'serving-tray',
        }
      } else if (ongoingFestival.festival.code === FestivalCodesEnum.Bw) {
        return {
          label: 'BreakfastWeek',
          href: paths.festivalListing('breakfast-week'),
          icon: 'croissant',
        }
      }
      return null
    })
    .filter((x): x is NonNullable<typeof x> => Boolean(x))

  return festivalRoutes.length > 0 && festivalRoutes.length < 3
    ? [
        {
          label: 'Discover',
          icon: 'search',
          href: paths.home,
        },
        ...festivalRoutes,
        {
          label: 'Find a table',
          icon: 'restaurant',
          href: paths.restaurants,
        },
      ]
    : DEFAULT_NAV_BAR_ROUTES
}
