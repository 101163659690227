import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import Icon from '~/shared/atoms/Icon'

const MAX_MAP_HEIGHT = '356px'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media (min-width: 768px) {
    gap: 2.5rem;
    flex-wrap: nowrap;
    max-height: ${MAX_MAP_HEIGHT};
  }
`

export const MapContainer = styled.div`
  aspect-ratio: 3 / 2;
  width: 100%;

  @media (min-width: 768px) {
    aspect-ratio: 1 / 1;
    min-width: 280px;
    max-width: ${MAX_MAP_HEIGHT};
  }
`

export const ContactDetails = styled.div`
  width: 100%;
  height: 100%;

  h5,
  h6 {
    margin-bottom: 0;
  }
`

export const Section = styled.div`
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
  ${mediaQueryUp('m')} {
    padding: 1.5rem 0;
  }
  &:first-child {
    padding-top: 0;
  }
`

export const WebsiteSection = styled(Section)<{ hasAddress: boolean }>`
  ${({ theme, hasAddress }) =>
    hasAddress &&
    `
  color: ${theme.colors.green[500]};
  cursor: pointer;
`}
`

export const StyledIcon = styled(Icon)`
  height: 1.5rem;
  width: 1.5rem;
  filter: ${({ theme }) => theme.filters.gray[700]};
  margin-right: 0.5rem;
`

export const IconButton = styled(Icon)`
  margin-top: 1.375rem;
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  filter: ${({ theme }) => theme.filters.gray[700]};
  cursor: pointer;
`
