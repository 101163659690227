import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { useApolloClient } from '@apollo/client'

import { Country } from '~/utils/countries'

import { isLocaleEnabled } from '~/config/clubConfig'
import { HomepageQueryDocument } from '~/generated/graphql'
import { gt } from '~/locale'
import { loadLocales } from '~/locale/loader'
import { DropdownItem } from '~/shared/atoms/Dropdown'
import { Flag } from '~/shared/atoms/Flag/Flag'
import { LocaleItemContainer } from '~/shared/molecules/LanguageSwitcher/LanguageSwitcherShards'

interface UseLanguageSwitcherOutput {
  languages: string[]
  options: DropdownItem[]
  onLanguageChange(lang: string): void
}

export const localeToCountry: Record<string, Country> = {
  en: Country.UnitedKingdom,
  pl: Country.Poland,
  uk: Country.Ukraine,
  cs: Country.CzechRepublic,
  de: Country.Germany,
}

export const LocaleItem = ({ locale }: { locale: string }) => {
  return (
    <LocaleItemContainer>
      <Flag country={localeToCountry[locale]}></Flag>
      {gt.tp('Languages', locale.toUpperCase())}
    </LocaleItemContainer>
  )
}

const useLanguageSwitcher = (): UseLanguageSwitcherOutput => {
  const router = useRouter()
  const apolloClient = useApolloClient()

  const onLanguageChange = useCallback(
    async (value: string | false) => {
      const { pathname, asPath, query } = router

      await loadLocales(value)

      await router.push({ pathname, query }, asPath, { locale: value, shallow: true })

      apolloClient.refetchQueries({
        include: [HomepageQueryDocument],
      })
    },
    [router]
  )

  const languages = router.locales?.filter(isLocaleEnabled) || []
  const options = languages.map(language => ({
    key: language,
    label: gt.tp('Languages', language.toUpperCase()),
    icon: <Flag country={localeToCountry[language]} />,
  }))

  return {
    languages,
    options,
    onLanguageChange,
  }
}

export default useLanguageSwitcher
