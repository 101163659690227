// @ts-strict-ignore
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import Image from 'next/image'
import { useRouter } from 'next/router'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Heading4, Heading5, Heading6 } from '~/theme/utils/typography'
import { formatPrice } from '~/utils/graphqlDataFormatters'
import { imgProxyLoader } from '~/utils/imgproxy'

import { festivalModalProps } from '~/components/Festival/FERModal'
import CardActions from '~/components/Product/ProductCardActions'
import ProductModal from '~/components/Product/ProductModal'
import { paths, RESERVABLE_TYPES } from '~/constants'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import FormattedPrice from '~/shared/atoms/FormattedPrice/FormattedPriceView'
import { useModal } from '~/shared/molecules/Modal'

import {
  DetailsContainer,
  Eyebrow,
  InfoBox,
  InfoRow,
  InfoSubtitle,
  InfoTitle,
  Photo,
  PhotoWrapper,
  StyledTile,
  StyledTileWide,
  WideContainer,
  WidePhoto,
  WidePhotoWrapper,
} from './ReservationOfferCardShards'
import { ReservationOfferCardProps } from './ReservationOfferCardTypes'
import { getFERProperties, getReservableExtraProperties } from './ReservationOfferCardUtils'

export const ReservationOfferCard = ({ FER, reservableExtra, type = 'regular' }: ReservationOfferCardProps) => {
  const { tp } = gt
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const baseMargin = isMobile ? '.5' : '.75'
  const Title = isMobile ? Heading5 : Heading4
  const router = useRouter()
  const modal = useModal()

  const properties = reservableExtra ? { ...getReservableExtraProperties(reservableExtra) } : { ...getFERProperties(FER) }

  const { eyebrowText, titleText, photo, slug, price, buttonLabel } = properties

  const handleOpenModal = () => {
    const modalType = reservableExtra ? RESERVABLE_TYPES.EXTRA : RESERVABLE_TYPES.FER
    modal.open(festivalModalProps, <ProductModal FER={FER} reservableExtra={reservableExtra} type={modalType} handleClose={modal.close} />)
  }

  useEffect(() => {
    // this sucks, but next router is seemingly unaware of this param sometimes, god knows why...
    // should be refactored when we remake the restaurant page soon anyway
    const params = new URLSearchParams(window.location.search)
    const festivalFilter = params.get('festivalFilter')
    const restaurant = params.get('restaurant')

    if ((festivalFilter && FER?.festivalEdition?.id === festivalFilter) || reservableExtra?.slug === restaurant) {
      handleOpenModal()
    }
  }, [router.query])

  if (type === 'wide') {
    return (
      <StyledTileWide withBorder>
        <WideContainer>
          {photo && (
            <WidePhotoWrapper>
              <WidePhoto>
                <Image loader={imgProxyLoader} src={photo} layout='fill' objectFit='cover' height={164} width={164} />
              </WidePhoto>
            </WidePhotoWrapper>
          )}
          <InfoBox style={{ marginTop: 0 }}>
            <InfoRow marginBottomRem={baseMargin}>
              <Eyebrow>{eyebrowText}</Eyebrow>
            </InfoRow>
            <InfoRow marginBottomRem={baseMargin}>
              <Title fontWeight='medium'>{titleText}</Title>
            </InfoRow>
            <InfoRow marginBottomRem='1'>
              <DetailsContainer>
                <FormattedPrice price={price} withGuest={true} />
              </DetailsContainer>
            </InfoRow>
            {FER?.externalSaleUrl ? (
              <Button as='a' href={FER.externalSaleUrl} height='small' fontWeight='semiBold' label={tp('Reservation', 'Reserve table')} />
            ) : (
              <Button height='small' fontWeight='semiBold' onClick={handleOpenModal} label={buttonLabel} />
            )}
          </InfoBox>
        </WideContainer>
      </StyledTileWide>
    )
  }

  return (
    <CardActions
      onClick={handleOpenModal}
      href={{
        pathname: reservableExtra ? paths.singleChefsMenu({ slug }) : paths.singleRestaurant({ slug }),
        query: router.query,
      }}>
      <StyledTile className='ReservationOfferCard'>
        <PhotoWrapper horizontal={false} ratio={isMobile ? '9 / 16' : '1 / 1'} imageMargin={'0'}>
          {photo && (
            <Photo>
              <Image
                loader={imgProxyLoader}
                src={photo}
                alt={titleText}
                layout='fill'
                objectFit='cover'
                height={304}
                width={304}
                sizes='(max-width: 500px) 100vw,
                  (max-width: 1200px) 50vw,
                  33vw'
              />
            </Photo>
          )}
        </PhotoWrapper>

        {/* cannot be fetched from api */}
        {/* {badges && (
          <BadgeContainer>
            {badges.slice(0, 3).map(badge => {
              return (
                <Badge
                  size={'regular'}
                  key={badge.key}
                  color={badge.color}
                  label={badge.label}
                />
              )
            })}
          </BadgeContainer>
        )} */}

        <InfoBox>
          <InfoRow>
            <InfoTitle as='h3' fontWeight='semiBold'>
              {titleText}
            </InfoTitle>
            <InfoSubtitle fontWeight='regular'>{eyebrowText}</InfoSubtitle>
          </InfoRow>
          <DetailsContainer>
            <Heading6 fontWeight='semiBold'>{formatPrice(price)}&nbsp;/&nbsp;</Heading6>
            <Heading6 fontWeight='regular'>{tp('Reservation', 'Guest')}</Heading6>
          </DetailsContainer>
        </InfoBox>
      </StyledTile>
    </CardActions>
  )
}

export default ReservationOfferCard
