import { useMemo, useRef, useState } from 'react'

import { useFormikContext } from 'formik'
import { AsYouType, CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'

import { Body2, Heading6 } from '~/theme/utils/typography'

import config from '~/config'

import Input from '../Input'
import { Props as InputProps } from '../Input/InputTypes'

import { CountrySelect } from './CountrySelect'
import { PhoneInputContainer, PhoneInputWrapper } from './PhoneInputSheets'

export default ({
  defaultValue,
  title,
  name,
  onChange,
  description,
  error,
  errorMessage,
  disabled,
  ...rest
}: Omit<InputProps, 'height' | 'width'> & { name: string }) => {
  const { setFieldTouched, setFieldValue } = useFormikContext()

  const parsedPhoneNumber = parsePhoneNumberFromString(`+${defaultValue}` || '')
  const defaultCountryCode = parsedPhoneNumber?.country || (config().marketCode.toUpperCase() as CountryCode)
  const defaultAsYouType = useMemo(() => new AsYouType(defaultCountryCode), [defaultCountryCode])
  const defaultFormattedPhoneNumber = defaultAsYouType.input(parsedPhoneNumber?.nationalNumber || '')

  const [country, setCountry] = useState(defaultCountryCode)
  const [value, setValue] = useState(defaultFormattedPhoneNumber)
  const phoneNumberInputRef = useRef<HTMLInputElement>(null)

  const asYouType = new AsYouType(country)

  return (
    <PhoneInputContainer>
      {title && <Heading6 as={'div'}>{title}</Heading6>}
      {description && <Body2 fontWeight={'medium'}>{description}</Body2>}
      <PhoneInputWrapper>
        <CountrySelect
          id={name}
          value={country}
          onChange={val => {
            phoneNumberInputRef.current?.focus()

            const parsedNumber = parsePhoneNumberFromString(value, val as CountryCode)

            setFieldTouched(name, true, true)
            setFieldValue(name, parsedNumber?.number?.replace('+', ''))
            setCountry(val as CountryCode)
          }}
        />
        <Input
          {...rest}
          error={error}
          height='normal'
          width='full'
          inputRef={phoneNumberInputRef}
          name={name}
          onChange={e => {
            const parsedNumber = parsePhoneNumberFromString(e.target.value || '', country)

            setValue(e.target.value)
            onChange && onChange(e)

            setFieldTouched(name, true, true)
            setFieldValue(name, parsedNumber?.number?.replace('+', '') || `${e.target.value}`)
          }}
          value={asYouType.input(value)}
        />
      </PhoneInputWrapper>
      {error && errorMessage && !disabled && <Body2 fontWeight={'medium'}>{errorMessage}</Body2>}
    </PhoneInputContainer>
  )
}
