import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const FestivalBannerContainer = styled.div<{ middle?: boolean; href?: boolean }>`
  width: 100%;
  max-width: none !important;

  img {
    max-width: 100%;
  }

  ${({ href }) => href && 'cursor: pointer;'}

  ${mediaQueryUp('m')} {
    margin-top: 0;
    ${({ middle }) => middle && 'grid-column: 1/-1;'}
  }

  ${({ middle }) => {
    return middle
      ? css`
          max-width: none;
          picture {
            img {
              max-width: 100%;
            }
          }
        `
      : css`
          height: fit-content;
          position: relative;
          grid-area: banner;
          max-width: 1280px;
          margin: 0 auto;
        `
  }}
`

export const FestivalBannerLink = styled.a<{ middle?: boolean }>`
  display: contents;
`
