// @ts-strict-ignore
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { ApolloError } from '@apollo/client'
import { PropTypes } from '@zag-js/react'
import { GroupApi } from '@zag-js/toast'

import { formatErrorMessages } from '~/utils/formatErrorMessages'

import { errors } from '~/constants/errors'

import { Toasts } from './ToastShards'
import { NewToastParams } from './ToastTypes'
import ToastView from './ToastView'

export const TOAST_TIMEOUT = 5000

export const ICONS = {
  success: 'circle-check',
  error: 'attention-triangle',
  info: 'info',
  loading: 'info',
}

const ToastContext = createContext<any>(null)
const generateID = (toastsLength: number) => Date.now() + toastsLength + 1

export const ToastProvider = (props: { children: ReactNode | ReactNode[] }) => {
  const [toasts, setToasts] = useState([])
  const [containerSelector, setContainerSelector] = useState('')
  const [mounted, setMounted] = useState(false)
  const toastRef = useRef(null)

  const close = (id: string) => setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))

  const contextValue = useMemo(() => {
    const add = (params: NewToastParams) => {
      if (containerSelector !== params.containerSelector) {
        setContainerSelector(params.containerSelector)
        setToasts([
          {
            id: generateID(0),
            message: params.message,
            type: params.type,
            size: params.size,
          },
        ])
      } else {
        setToasts(currentToasts => [
          ...currentToasts,
          {
            id: generateID(currentToasts.length),
            message: params.message,
            type: params.type,
            size: params.size,
          },
        ])
      }

      if (toastRef.current && toastRef.current.getBoundingClientRect().top < 0) {
        toastRef.current.scrollIntoView(false)
      }
    }
    return { add }
  }, [containerSelector])

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  return (
    <ToastContext.Provider value={contextValue}>
      {mounted && containerSelector && document.querySelector(containerSelector)
        ? createPortal(
            <Toasts count={toasts.length} ref={toastRef} size={toasts[0]?.size}>
              {toasts.map(toast => (
                <ToastView key={toast.id} close={() => close(toast.id)} message={toast.message} type={toast.type} />
              ))}
            </Toasts>,
            document.querySelector(containerSelector)
          )
        : null}
      {props.children}
    </ToastContext.Provider>
  )
}

export type ToastReturnType = { add: (p: NewToastParams) => void }
export const useToast = (): ToastReturnType => useContext(ToastContext)

export const handleNetworkError = ({ err, create }: { err?: ApolloError; create: GroupApi<PropTypes, any>['create'] }) => {
  let errorMessage = err ? formatErrorMessages(err) : [errors.unknown_error]
  if (!errorMessage.length) {
    errorMessage = [errors.unknown_error]
  }

  errorMessage.map(error =>
    create({
      title: error,
      type: 'error',
    })
  )
}
