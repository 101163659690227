export type AutoAttributeSettings = {
  uuidCookieName?: string
  uuidKey?: string
  uuid?: string
  uuidAutoPersist?: boolean
  market?: string
  loggedIn?: boolean
  email?: string
}

export function getGbUserId() {
  const COOKIE_NAME = 'gbuuid'
  let uuid = ''
  function persistUUID() {
    setCookie(COOKIE_NAME, uuid)
  }

  // If cookie is already set, return
  uuid = getCookie(COOKIE_NAME)
  if (uuid) {
    persistUUID()
    return uuid
  }

  // Generate a new UUID
  uuid = genUUID(window.crypto)
  persistUUID()
  return uuid
}

function setCookie(name: string, value: string) {
  const d = new Date()
  const COOKIE_DAYS = 400 // 400 days is the max cookie duration for chrome
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * COOKIE_DAYS)
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString()
}

function getCookie(name: string): string {
  const value = '; ' + document.cookie
  const parts = value.split(`; ${name}=`)
  return parts.length === 2 ? parts[1].split(';')[0] : ''
}

// Use the browsers crypto.randomUUID if set to generate a UUID
function genUUID(crypto?: Crypto) {
  if (crypto && crypto.randomUUID) return crypto.randomUUID()
  return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => {
    const n = crypto && crypto.getRandomValues ? crypto.getRandomValues(new Uint8Array(1))[0] : Math.floor(Math.random() * 256)
    return ((c as unknown as number) ^ (n & (15 >> ((c as unknown as number) / 4)))).toString(16)
  })
}
