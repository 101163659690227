import styled from '@emotion/styled'

import { BasicIcon } from '../Icon'

export const StyledCheckboxIcon = styled(BasicIcon)<{ icon: string }>`
  cursor: inherit;
  width: ${({ icon }) => (icon === 'indeterminate' ? '.625rem' : '.875rem')};
  filter: ${({ theme }) => theme.filters.gray[0]};
`

export const StyledCheckboxField = styled.label<{ disabled: boolean; error?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 1.25rem;
  width: fit-content;
  cursor: pointer;
  ${({ theme, disabled }) =>
    disabled &&
    `
    &, & > * { pointer-events: none; };
    color: ${theme.colors.gray[300]};
    ${StyledCheckboxIcon} {filter: ${theme.filters.gray[500]}}
  `};

  ${({ theme, error, disabled }) =>
    error &&
    !disabled &&
    `
    color: ${theme.colors.red[400]};
    ${CheckboxBox} { border-color: ${theme.colors.red[400]}};
    a { color: ${theme.colors.red[400]}; };
  `};
`

export const CheckboxBox = styled.div<{ checked: boolean; disabled: boolean }>`
  transition: 0.3s;
  flex-shrink: 0;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  background: ${({ theme, checked }) => (checked ? theme.colors.orange[400] : theme.colors.gray[0])};
  border: 1px solid ${({ theme, checked }) => (checked ? theme.colors.orange[400] : theme.colors.gray[300])};
  &:hover {
    border-color: ${({ theme }) => theme.colors.orange[500]};
    background: ${({ theme, checked }) => (checked ? theme.colors.orange[500] : theme.colors.gray[0])};
  }
  ${({ theme, disabled, checked }) =>
    disabled &&
    `
    border-color: ${checked ? theme.colors.gray[200] : theme.colors.gray[300]};
    background: ${theme.colors.gray[200]};
  }`}
`

export const Label = styled.div`
  transition: 0.3s;
  cursor: inherit;
  margin: 0;
  margin-left: 0.25rem;
  font-weight: ${({ theme }) => theme.text.fontWeight.regular};
  font-size: ${({ theme }) => theme.text.fontSize.base};
  line-height: ${({ theme }) => theme.text.lineHeight.restful};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.tight};
`

export const LabelAddition = styled.span`
  color: ${({ theme }) => theme.colors.gray[600]};
`

export const HiddenInput = styled.input`
  display: none;
`

export const ShowMore = styled.span`
  color: ${({ theme }) => theme.colors.orange[500]};
`

export const LabelExpanded = styled.span`
  font-size: ${({ theme }) => theme.text.fontSize.xxs};
  margin-right: 0.2rem;

  a {
    color: ${({ theme }) => theme.colors.orange[500]};
    &:hover {
      color: ${({ theme }) => theme.colors.orange[600]};
    }
  }
`
