import { CheckoutCrossSell } from '~/utils/checkoutFlow'

import { PreviousCrossSell } from '../CheckoutPageTypes'

export const getPreviousQuantity = ({ previousCrossSells, id }: { previousCrossSells: PreviousCrossSell[]; id: string }) => {
  const prevCrossSell = previousCrossSells?.find(prev => prev.crossSell.id === id)
  return prevCrossSell?.quantity
}

export const hasComplimentaryCrossSells = (crossSells: Pick<CheckoutCrossSell, 'festivalDrink'>[]) => {
  return !!crossSells.filter(crossSell => crossSell.festivalDrink).length
}

export const hasCrossSells = (crossSells: Pick<CheckoutCrossSell, 'festivalDrink'>[]) => {
  return !!crossSells.filter(crossSell => !crossSell.festivalDrink).length
}

export const getMaxCrossSellCount = ({ peopleCount, crossSell }: { peopleCount: number; crossSell: CheckoutCrossSell }) => {
  if (crossSell.quantityLimitedToOne) {
    return 1
  }

  if (crossSell.peopleCountLimited) {
    return peopleCount
  }

  if (crossSell.price === 0) {
    return peopleCount
  }

  return 99
}
